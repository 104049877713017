import { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import Jaktområder from "../Jaktomrader/Jaktomrader";
import MittJaktomrade from "../Jaktomrader/MittJaktomrade";
import SkuttDyrDetaljer from "../Oversikt/SkuttDyrDetaljer";
import Aldersbestemmelse from "./Aldersbestemmelse";
import JegeropplysningerForm from "./Components/JegeropplysningerForm";
import Minside from "./Components/MinSide";
import Kontakt from "./Kontakt";
import Om from "./Om";

export default function MinSide(): JSX.Element {
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const location = useLocation();

  useEffect(() => {
    if (appData.jegeropplysninger.isSuccess && !appData.jegeropplysninger.data?.HarGyldigProfil) {
      navigate("/velkommen");
    }
  }, [
    location.pathname,
    navigate,
    appData.jegeropplysninger.isSuccess,
    appData.jegeropplysninger.data?.HarGyldigProfil,
  ]);

  return (
    <Routes>
      <Route index element={<Minside />} />
      <Route path="fellingsinfo/:individId" element={<SkuttDyrDetaljer />} />
      <Route path="jegeropplysninger/*" element={<JegeropplysningerForm mode="rediger" />} />
      <Route path="kontakt/*" element={<Kontakt />} />
      <Route path="om/*" element={<Om />} />
      <Route path="aldersbestemmelse/*" element={<Aldersbestemmelse />} />
      <Route path="jaktomrader/*" element={<Jaktområder />} />
      <Route path="jaktomrade/*" element={<MittJaktomrade />} />
    </Routes>
  );
}
