import { t } from "i18next";
import React, { ReactElement } from "react";
import { onEnterPressed } from "~/src/helpers/accessibilityHelpers";
import { Favorittområde, Område } from "../../api/types";
import { ArtIcons, artIcon } from "../../helpers/icons";
import { ArtType, OmradeType } from "../../helpers/types";
import { useStateful } from "../../hooks/useStateful";
import { ArtNames } from "../Jaktomrade/ArtNames";
import Text from "../Text/Text";

export type JaktområdeDefaultProps = {
  områdeEntry: Område | Favorittområde;
  omradeType?: OmradeType;
  selectable?: boolean;
  onClick: () => void;
};

const JaktområdeDefault: React.ForwardRefRenderFunction<HTMLDivElement, JaktområdeDefaultProps> = (
  { områdeEntry, omradeType, selectable = true, onClick },
  ref,
) => {
  const selected = useStateful(false);
  const isFavorittomrade = "Områdenavn" in områdeEntry;
  const områdeNavn = isFavorittomrade
    ? områdeEntry.Områdenavn
    : "Navn" in områdeEntry
      ? områdeEntry.Navn
      : "Ukjent";
  const områdeKode = isFavorittomrade
    ? områdeEntry.Områdekode
    : "Id" in områdeEntry
      ? områdeEntry.Id
      : "Ukjent";

  const handleClicked = () => {
    selectable && selected.set(!selected.value);
    onClick();
  };

  const bgColor =
    "isPending" in områdeEntry && områdeEntry.isPending ? "bg-md-warning-light" : "bg-white";
  const jaktområdeClassName = `flex flex-row ${bgColor} justify-between hover:bg-md-lysegronn rounded-sm p-1`;

  const getHeader = (): ReactElement => {
    return (
      <Text key={`header-${id}`} variant="h6">
        {områdeNavn}
      </Text>
    );
  };
  const getInfo = (): ReactElement => {
    return (
      <div key={`info-${id}`} className="flex flex-row gap-2">
        <Text variant="paragraph">{områdeKode}</Text>
        {(omradeType === OmradeType.Vald && (
          <Text className="flex flex-row text-sm items-center ml-5">
            {t("fauna.art.rådyr.artsnavn").toUpperCase()}
          </Text>
        )) || (
          <Text className="flex flex-row text-sm items-center ml-5">
            {ArtNames({ ...områdeEntry }).toUpperCase()}
          </Text>
        )}
      </div>
    );
  };

  const id = `Jaktomrade-${områdeNavn}`;
  return (
    <div
      id={id}
      key={id}
      className={`${jaktområdeClassName} ${
        selected.value && "bg-md-lysegronn border-md-primary border-l-2 pl-2"
      }`}
      ref={ref}
      onClick={async () => handleClicked()}
      onKeyDown={(e) => onEnterPressed(e, handleClicked)}
    >
      <div className="flex flex-col w-full">
        {getHeader()}
        {getInfo()}
      </div>
      {(omradeType === OmradeType.Vald && (
        <div className="flex flex-row items-center">{artIcon(ArtType.Rådyr)}</div>
      )) ||
        ArtIcons({ ...områdeEntry })}
    </div>
  );
};

export default React.forwardRef(JaktområdeDefault);
