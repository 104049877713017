import { ApiError } from "~/src/api/types";

/**
 * For forms using useForm.
 * Sets API errors in the form using the provided response and setError function.
 *
 * @param response - The response object containing the API errors.
 * @param setError - The function to set the error(s) in the form.
 * @returns void
 * @template T - The type of the form values.
 */
// biome-ignore lint/suspicious/noExplicitAny: Can't set a type for response in this case since we're passing a variable that is already type "any"
export function setApiErrorsUseForm<T>(response: any, setError: any) {
  const errors = response.axiosError.response.data.errors;
  for (const error in errors) {
    setError(error as keyof T, { type: "server", message: errors[error][0] });
  }
}

/**
 * For forms not using any form library.
 * Returns form errors from an ApiError which can be used to highlight errors in the form.
 *
 * @param error - ApiError
 * @returns void
 * @template T - The type of the form values.
 */
export function toErrorRecords<T>(error: ApiError): Partial<Record<keyof T, string>> {
  // biome-ignore lint/suspicious/noExplicitAny: Can't set a type for response in this case since we're passing a variable that is already type "any"
  const errorResponse = error.axiosError?.response?.data as any;
  const errors = errorResponse && "errors" in errorResponse ? errorResponse.errors : {};
  return extractErrors<T>(errors);
}

/**
 * Returns the first error for each record from a set of error records.
 *
 * @param errors - Any set of records of the form { Identifier: ["Error", "Messages"] }
 * @returns void
 * @template T - The type of the form values.
 */
// biome-ignore lint/suspicious/noExplicitAny: Can't set a type for response in this case since we're passing a variable that is already type "any"
function extractErrors<T>(
  errors: Partial<Record<keyof T, string[]>>,
): Partial<Record<keyof T, string>> {
  const fieldErrors: Partial<Record<keyof T, string>> = {};
  for (const error in errors) {
    fieldErrors[error as keyof T] = errors[error]![0];
  }
  return fieldErrors;
}
