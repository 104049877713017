import { DetectedBarcode } from "barcode-detector";

const removeReadonly = <T>(arr: T[] | readonly T[]): T[] => arr as T[];

async function polyfill() {
  if (!("BarcodeDetector" in window)) {
    try {
      await import("barcode-detector");
    } catch {
      throw new Error("BarcodeDetector API is not supported by your browser.");
    }
  }
}

export async function getBarcodeReader(formats?: BarcodeFormat[]): Promise<BarcodeReader> {
  return polyfill().then(() =>
    window.BarcodeDetector.getSupportedFormats().then(
      (supportedFormats) => new BarcodeReader(formats || supportedFormats),
    ),
  );
}

/**
 * BarcodeReader class to detect barcodes from images or videos.
 *
 * @see https://developer.mozilla.org/docs/Web/API/BarcodeDetector
 * @see https://github.com/georapbox/barcode-scanner/blob/main/src/js/helpers/BarcodeReader.js
 */
export default class BarcodeReader {
  private barcodeDetector: BarcodeDetector | undefined = undefined;

  async detect(source: ImageData | HTMLVideoElement): Promise<DetectedBarcode> {
    if (!this.barcodeDetector) {
      throw new Error("BarcodeReader is not initialized.");
    }

    const results = await this.barcodeDetector.detect(source);

    if (Array.isArray(results) && results.length > 0) {
      return results[0];
    } else {
      throw new Error("Could not detect barcode from provided source.");
    }
  }

  constructor(formats: readonly BarcodeFormat[]) {
    const _formats = removeReadonly(formats);
    this.barcodeDetector = new window.BarcodeDetector({ formats: _formats });
  }
}
