import { Card, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArtButtons } from "../../components/Button/ArtButtons";
import { ArtType } from "../../helpers/types";

export default function VelgArt(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onArtButtonClick = (art: ArtType) => {
    let path = "";
    switch (art) {
      case ArtType.Elg:
        path = "/registrering/elg";
        break;
      case ArtType.Hjort:
        path = "/registrering/hjort";
        break;
      case ArtType.Rådyr:
        path = "/registrering/radyr";
        break;
      case ArtType.Villrein:
        path = "/registrering/villrein";
        break;
      case ArtType.Villsvin:
        path = "/registrering/villsvin";
        break;
      default:
        throw new Error(`registrering for ${art} not implemented`);
    }
    navigate(path, { state: { valgtArt: art } });
  };

  return (
    <Card className="h-full">
      <Typography variant="h5" className="ml-5 mt-8 mb-3">
        {t("pages.registrering.subtitle")}
      </Typography>
      <ArtButtons onClick={onArtButtonClick} />
    </Card>
  );
}
