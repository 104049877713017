import { InputProps } from "@material-tailwind/react/components/Input";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { IconButton } from "@mui/material";
import React, { ReactElement } from "react";
import { Stateful } from "../../hooks/useStateful";
import Text from "../Text/Text";

export type CounterProps = {
  id: string;
  label: string;
  showButtons?: boolean;
  error?: string;
  showErrorHelperText?: boolean;
  onCountChanged?: (count: number) => void;
  minCount?: number;
  maxCount?: number;
  value?: number;
  state?: Stateful<number>;
  disabled?: boolean;
  helpText?: string;
} & Omit<InputProps, "ref" | "crossOrigin" | "error">;

const Counter: React.ForwardRefRenderFunction<HTMLDivElement, CounterProps> = (
  {
    id,
    label,
    showButtons = true,
    onCountChanged,
    minCount = 0,
    maxCount = 1000,
    value,
    error,
    disabled,
    showErrorHelperText = true,
    ...rest
  },
  ref,
) => {
  const handleCountChanged = (count: number) => {
    onCountChanged && onCountChanged(count);
  };

  const input: ReactElement = (
    <div
      className={`flex justify-center items-center rounded-sm text-center h-11 min-w-11 max-w-11 ${
        error
          ? "bg-md-error-bg-red-light border border-md-error-red rounded-sm"
          : " border-none bg-md-greengray"
      }`}
      id={id + "CounterInput"}
      data-testid={id + "CounterInput"}
    >
      <Text variant="h6">{value}</Text>
    </div>
  );

  let iconButtonClassnames = "text-xl text-md-primary disabled:text-gray-400";
  if (!showButtons) {
    iconButtonClassnames = " " + "opacity-0 pointer-events-none ";
  }
  const minusDisabled = value === undefined || value <= 0 || disabled;
  const plusDisabled = value === undefined || value >= maxCount || disabled;

  return (
    <div>
      <div
        className="flex flex-row justify-between items-center border-b border-md-greengray pb-2"
        ref={ref}
      >
        <div className="col-span-4">
          <Text variant="paragraph">{label}</Text>
          {error && showErrorHelperText && (
            <Text variant="small" className="text-md-error-red">
              {error}
            </Text>
          )}
        </div>
        <div className="flex flex-row space-x-6 items-center">
          <IconButton
            disabled={minusDisabled}
            onClick={() => handleCountChanged(Math.max((value || 0) - 1, minCount))}
            id={id + "CounterMinusButton"}
            data-testid={id + "CounterMinusButton"}
            className={iconButtonClassnames}
          >
            <RemoveCircleRoundedIcon className="text-3xl" />
          </IconButton>
          {input}
          <IconButton
            disabled={plusDisabled}
            onClick={() => handleCountChanged(Math.min((value || 0) + 1, maxCount))}
            id={id + "CounterPlutButton"}
            data-testid={id + "CounterPlusButton"}
            className={iconButtonClassnames}
          >
            <AddCircleRoundedIcon className="text-3xl" />
          </IconButton>
        </div>
      </div>
      {rest.helpText && <Text variant="small">{rest.helpText}</Text>}
    </div>
  );
};

export default React.forwardRef(Counter);
