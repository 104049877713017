import { JaktdagResponse } from "../api/types";
import { Terreng } from "../pages/Registrering/types";
import { AlderType, ArtType, KjønnType, SumSettOgSkuttJaktdag } from "./types";

export function sumSettOgSkuttJaktdag(data: JaktdagResponse): SumSettOgSkuttJaktdag {
  const settOgSkutt = {
    // elg: summer både voksen og ungdyr
    // hjort: summer bare voksen
    skuttOkser:
      (data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hann &&
          x.Alder === AlderType.Voksen &&
          x.Terreng !== Terreng.Innmark,
      ).length || 0) +
      (data.Individer?.filter(
        (x) =>
          x.Art === ArtType.Elg &&
          x.Kjønn === KjønnType.Hann &&
          x.Alder === AlderType.Ungdyr &&
          x.Terreng !== Terreng.Innmark,
      ).length || 0),

    skuttSpissbukker:
      data.Individer?.filter(
        (x) =>
          x.Art === ArtType.Hjort &&
          x.Kjønn === KjønnType.Hann &&
          x.Alder === AlderType.Ungdyr &&
          x.Terreng !== Terreng.Innmark,
      ).length || 0,

    skuttBukkerInnmark:
      data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hann &&
          x.Alder === AlderType.Voksen &&
          x.Terreng === Terreng.Innmark,
      ).length || 0,

    skuttSpissbukkerInnmark:
      data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hann &&
          x.Alder === AlderType.Ungdyr &&
          x.Terreng === Terreng.Innmark,
      ).length || 0,

    skuttKyr:
      (data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hunn &&
          x.Alder === AlderType.Voksen &&
          x.Terreng !== Terreng.Innmark,
      ).length || 0) +
      (data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hunn &&
          x.Alder === AlderType.Ungdyr &&
          x.Terreng !== Terreng.Innmark,
      ).length || 0),

    skuttKyrInnmark:
      (data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hunn &&
          x.Alder === AlderType.Voksen &&
          x.Terreng === Terreng.Innmark,
      ).length || 0) +
      (data.Individer?.filter(
        (x) =>
          x.Kjønn === KjønnType.Hunn &&
          x.Alder === AlderType.Ungdyr &&
          x.Terreng === Terreng.Innmark,
      ).length || 0),

    skuttKalver:
      data.Individer?.filter((x) => x.Alder === AlderType.Kalv && x.Terreng !== Terreng.Innmark)
        .length || 0,

    skuttKalverInnmark:
      data.Individer?.filter((x) => x.Alder === AlderType.Kalv && x.Terreng === Terreng.Innmark)
        .length || 0,

    settOkser: data.SettOkseEllerBukk || 0,
    settSpissbukk: data.SettSpissbukk || 0,

    settBukkInnmark: data.SettBukkInnmark || 0,
    settSpissbukkInnmark: data.SettSpissbukkInnmark || 0,

    settKalver:
      (data.SettKyrMed1Kalv || 0) +
      2 * (data.SettKyrMed2EllerFlereKalver || 0) +
      (data.SettKalverAlene || 0),
    settKalverInnmark: data.SettKalvInnmark || 0,

    settKyr:
      (data.SettKyrUtenKalvEllerKolle || 0) +
      (data.SettKyrMed1Kalv || 0) +
      (data.SettKyrMed2EllerFlereKalver || 0),
    settKyrInnmark: data.SettKolleInnmark || 0,

    settUkjente: data.SettUkjente || 0,
    settUkjenteInnmark: data.SettUkjenteInnmark || 0,
  };

  const errors = {
    okser:
      settOgSkutt.settSpissbukk + settOgSkutt.settOkser <
      settOgSkutt.skuttOkser + settOgSkutt.skuttSpissbukker,
    spissbukk:
      settOgSkutt.settSpissbukk + settOgSkutt.settOkser <
      settOgSkutt.skuttOkser + settOgSkutt.skuttSpissbukker,
    kyr: settOgSkutt.settKyr < settOgSkutt.skuttKyr,
    kalver: settOgSkutt.settKalver < settOgSkutt.skuttKalver,
    bukkInnmark:
      settOgSkutt.settSpissbukkInnmark + settOgSkutt.settBukkInnmark <
      settOgSkutt.skuttBukkerInnmark + settOgSkutt.skuttSpissbukkerInnmark,
    spissbukkInnmark: settOgSkutt.settSpissbukkInnmark < settOgSkutt.skuttSpissbukkerInnmark,
    kyrInnmark: settOgSkutt.settKyrInnmark < settOgSkutt.skuttKyrInnmark,
    kalverInnmark: settOgSkutt.settKalverInnmark < settOgSkutt.skuttKalverInnmark,
    utmark: false,
    innmark: false,
  };
  errors.utmark = errors.okser || errors.spissbukk || errors.kyr || errors.kalver;
  errors.innmark =
    errors.bukkInnmark || errors.spissbukkInnmark || errors.kyrInnmark || errors.kalverInnmark;

  const isInconsistent =
    errors.okser ||
    errors.spissbukk ||
    errors.kyr ||
    errors.kalver ||
    errors.bukkInnmark ||
    errors.kyrInnmark ||
    errors.kalverInnmark;

  return {
    ...settOgSkutt,
    sumSkutt:
      settOgSkutt.skuttOkser +
      settOgSkutt.skuttSpissbukker +
      settOgSkutt.skuttKyr +
      settOgSkutt.skuttKalver,
    sumSett:
      settOgSkutt.settOkser +
      settOgSkutt.settSpissbukk +
      settOgSkutt.settKalver +
      settOgSkutt.settKyr +
      settOgSkutt.settUkjente,
    sumSkuttInnmark:
      settOgSkutt.skuttBukkerInnmark +
      settOgSkutt.skuttSpissbukkerInnmark +
      settOgSkutt.skuttKyrInnmark +
      settOgSkutt.skuttKalverInnmark,
    sumSettInnmark:
      settOgSkutt.settBukkInnmark +
      settOgSkutt.settSpissbukkInnmark +
      settOgSkutt.settKalverInnmark +
      settOgSkutt.settKyrInnmark +
      settOgSkutt.settUkjenteInnmark,
    isInconsistent: isInconsistent,
    errors: errors,
  };
}

export const sumSettOgSkuttJaktdagDefault = (): SumSettOgSkuttJaktdag => {
  return {
    settBukkInnmark: 0,
    settKalverInnmark: 0,
    settKyrInnmark: 0,
    settOkser: 0,
    settKalver: 0,
    settKyr: 0,
    settUkjente: 0,
    settUkjenteInnmark: 0,

    skuttOkser: 0,
    skuttSpissbukker: 0,
    skuttKalverInnmark: 0,
    skuttKyrInnmark: 0,
    skuttKyr: 0,
    skuttKalver: 0,
    skuttBukkerInnmark: 0,
    sumSett: 0,
    sumSettInnmark: 0,
    sumSkutt: 0,
    sumSkuttInnmark: 0,
    isInconsistent: false,
    errors: {
      utmark: false,
      innmark: false,
      okser: false,
      spissbukk: false,
      kyr: false,
      kalver: false,
      bukkInnmark: false,
      spissbukkInnmark: false,
      kyrInnmark: false,
      kalverInnmark: false,
    },
  };
};
