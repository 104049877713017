import { useTranslation } from "react-i18next";
import Counter from "~/src/components/Counter/Counter";
import { useFormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { settElgFields } from "./ElgTypes";
import { JaktdagElgFormDataContext } from "./RegistrerElg";

const SettCountersElg = () => {
  const { t } = useTranslation();
  const { formData, setFormData, formErrors } = useFormDataContext(JaktdagElgFormDataContext);

  return (
    <div className="pb-2 space-y-2">
      {settElgFields.map((dyrType) => {
        return (
          <Counter
            key={dyrType}
            showErrorHelperText={false}
            id={dyrType}
            label={t(`pages.registrerArt.elg.sett.${dyrType}`)}
            error={formErrors[dyrType]}
            onCountChanged={(count) => {
              const _formData = { ...formData };
              _formData[dyrType] = count;
              setFormData({ ..._formData });
            }}
            value={formData[dyrType] as number}
          />
        );
      })}
    </div>
  );
};

export default SettCountersElg;
