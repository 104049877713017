import React, {
  FocusEventHandler,
  ForwardedRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ErrorText from "../../Text/ErrorText";
import InputLabel from "./InputLabel";

type HTMLInputType = HTMLInputElement | HTMLTextAreaElement;

type InputProps = React.InputHTMLAttributes<HTMLInputType> & {
  label?: string;
  errorMessage?: string;
  helpText?: string;
  textArea?: boolean;
};

const Input: React.ForwardRefRenderFunction<HTMLInputType, InputProps> = (props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const refPointer = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  useImperativeHandle(ref, () => refPointer.current!, []);

  const handleFocus: FocusEventHandler<HTMLInputType> = (e) => {
    setIsFocused(true);
    props.onFocus && props.onFocus(e);
  };

  const handleBlur: FocusEventHandler<HTMLInputType> = (e) => {
    setIsFocused(false);
    props.onBlur && props.onBlur(e);
  };
  const handleChange: React.ChangeEventHandler<HTMLInputType> = (e) => {
    // setHasValue(e.target.value !== "");
    if (props.inputMode === "numeric") {
      e.target.value = e.target.value.replace(/[^\d]/g, "");
    }
    if (props.inputMode === "decimal" || props.type === "number") {
      e.target.value = e.target.value.replace(/[^\d,.]/g, "");
    }
    if (props.step === 1) {
      e.target.value = e.target.value.replace(/[.,]/g, "");
    }
    props.onChange && props.onChange(e);
  };

  const hasValue =
    !!props.value || !!props.defaultValue || (!!refPointer.current && !!refPointer.current.value);

  const isError = !!props.errorMessage;
  const commonClassnames = `flex flex-col text-md-gray bg-md-greengray border-b-2
           focus:outline-none border-md-secondary focus:border-md-primary 
           w-full px-2 transition duration-150 font-normal pl-4 pt-1.5 `;

  return (
    <div className="relative w-full">
      <InputLabel
        for={props.id || ""}
        label={props.label || ""}
        isError={isError}
        isFocused={isFocused}
        hasValue={hasValue || !!props.placeholder}
        onClick={() => setIsFocused(true)}
      />
      <>
        {props.textArea ? (
          <textarea
            id={props.id}
            ref={refPointer as ForwardedRef<HTMLTextAreaElement>}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={!isFocused ? props.placeholder : ""}
            maxLength={props.maxLength}
            onChange={handleChange}
            value={props.value}
            defaultValue={props.defaultValue}
            inputMode={props.inputMode || props.type === "number" ? "numeric" : props.inputMode}
            className={commonClassnames + "pt-5 h-28 rounded-t-md"}
          />
        ) : (
          <input
            id={props.id}
            ref={refPointer as ForwardedRef<HTMLInputElement>}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={!isFocused ? props.placeholder : ""}
            maxLength={props.maxLength}
            onChange={handleChange}
            value={props.value}
            defaultValue={props.defaultValue}
            inputMode={props.inputMode || props.type === "number" ? "numeric" : props.inputMode}
            className={commonClassnames + "h-12 rounded-t-md"}
            type={props.type}
            hidden={props.hidden}
            aria-hidden={props.hidden}
          />
        )}
      </>
      <div className="mt-1.5 break-words whitespace-normal overflow-hidden">
        {props.errorMessage ? (
          <ErrorText>{props.errorMessage}</ErrorText>
        ) : (
          <div className="text-sm">{props.helpText}</div>
        )}
      </div>
    </div>
  );
};
export default React.forwardRef(Input);
