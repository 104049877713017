import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { retrieve } from "~/src/contexts/StorageContext/helpers";
import { useConfirmationDialog } from "~/src/hooks/useConfirmationDialog";
import { useDraftConfirmationDialog } from "~/src/hooks/useDraftConfirmationDialog";
import { FormDataWrapper } from "~/src/hooks/useFormData";
import { getFormDataStorageKey } from "~/src/pages/Registrering/Jaktdag/common/helpers";

const empty = (obj: object | undefined) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

type PathPropWrapper = {
  path: string;
  props: RouteProps;
  children?: PathPropWrapper[];
};

export type RouteProps = {
  pageTitle?: string;
  leadingText?: string;
  leftAction?: "actions.close" | "actions.cancel" | "actions.backArrow" | "actions.x" | string;
  rightAction?: "actions.save" | "actions.sync" | string;
  onLeftActionClick?: () => void;
  onRightActionClick?: () => void;
  dialogs?: React.ReactNode[];
  hideNavbar?: boolean;
};

const parsePathProps = (path: string, pathProps: PathPropWrapper[]) => {
  const decodedPathname = decodeURIComponent(path);
  const pathParts = decodedPathname.split("/").filter((path) => path);

  let searchingProps = pathProps;
  for (const part of pathParts) {
    const match = searchingProps.find((pp) => pp.path === part || pp.path.startsWith(":"));
    const wildCard = searchingProps.find((pp) => pp.path === "*");

    if (empty(match) && empty(wildCard)) {
      return null;
    }

    if (match) {
      if (!match.children?.length || pathParts.indexOf(part) === pathParts.length - 1) {
        return match.props;
      }
      searchingProps = match.children; // dig deeper
    }

    if (wildCard) return wildCard.props; // * means that it is a match no matter what comes after.
  }
  return null;
};

/**
 * `useRouteProps` is a custom hook that returns page properties based on the current route.
 * The properties include the page title, left and right actions, and click handlers for these actions.
 *
 * @returns {Object} An object containing the following properties:
 * - `pageTitle`: The title of the page, translated using i18next.
 * - `leftAction`: The left action of the page, translated using i18next.
 * - `rightAction`: The right action of the page, translated using i18next.
 * - `onLeftActionClick`: A function to be called when the left action is clicked. If not defined, a void function is returned.
 * - `onRightActionClick`: A function to be called when the right action is clicked. If not defined, a void function is returned.
 *
 * @example
 * const { pageTitle, leftAction, rightAction, onLeftActionClick, onRightActionClick } = useRouteProps();
 *
 * TODO:
 * 1. Extract the routeToPageProps object to a separate file
 * 2. Support IconButtons for left and right actions in addition to i18n keys
 * 3. Consider the need to support translations variables in the i18n keys
 *
 */
const useRouteProps = (): RouteProps => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToOversiktJaktdager = () => {
    navigate("/oversikt", {
      state: { activeTab: 0, valgtOmradekode: location.state?.routeProps?.valgtOmradekode },
    });
  };
  const navigateToOversiktSkutteDyr = () => {
    navigate("/oversikt", {
      state: { activeTab: 1, valgtOmradekode: location.state?.routeProps?.valgtOmradekode },
    });
  };

  const formDialogProps = {
    title: t("pages.registrering.dialogs.avbryt.title"),
    confirmButtonText: t("actions.yes"),
    cancelButtonText: t("pages.registrering.dialogs.avbryt.cancelButtonText"),
    onConfirm: () => {
      const pathsToSkutteDyr = ["fellingsinfo", "radyr", "villrein", "skuttdyrdetaljer"];
      if (pathsToSkutteDyr.some((value) => location.pathname.includes(value))) {
        navigateToOversiktSkutteDyr();
      } else {
        navigateToOversiktJaktdager();
      }
    },
  };

  const draftDialog = useDraftConfirmationDialog({
    ...formDialogProps,
    message: t("pages.registrering.dialogs.avbryt.message"),
    confirmButtonText: t("pages.registrering.dialogs.avbryt.confirmButtonText"),
    onSave: () => {
      location.pathname.includes("fellingsinfo")
        ? navigateToOversiktSkutteDyr()
        : navigateToOversiktJaktdager();
    },
  });

  const cancelFormInputDialog = useConfirmationDialog(formDialogProps);

  const map = {
    path: "kart",
    props: {
      pageTitle: t("pages.kart.title"),
      leadingText: t("pages.kart.beskrivelse"),
      leftAction: t("actions.cancel"),
      onLeftActionClick: () => {
        navigate(-1);
      },
      hideNavbar: true,
    },
  };

  const showCancelDialog = async () => {
    const hasFormData = retrieve<FormDataWrapper<unknown>>(getFormDataStorageKey()) !== undefined;
    if (hasFormData) {
      draftDialog.show();
    } else {
      cancelFormInputDialog.show();
    }
  };
  const cancelDialogs = [draftDialog.element, cancelFormInputDialog.element];

  const pathProps = [
    {
      path: "minside",
      props: { pageTitle: t("pages.minSide.title") },
      children: [
        {
          path: "jegeropplysninger",
          props: {
            pageTitle: t("pages.minSide.jegerOpplysninger.title"),
            leftAction: t("actions.cancel"),
            onLeftActionClick: async () => navigate(-1),
          },
        },
        {
          path: "jaktomrade",
          props: {
            pageTitle: t("pages.minSide.mineJaktområder.title"),
            leftAction: t("actions.cancel"),
            // rightAction: t("actions.sync"),
            onLeftActionClick: async () => navigate(-1),
          },
        },
        {
          path: "jaktomrader",
          props: {
            pageTitle: t("pages.jaktområder.title"),
            leftAction: t("actions.cancel"),
            // rightAction: t("actions.sync"),
            onLeftActionClick: async () => navigate(-1),
          },
        },
        {
          path: "kontakt",
          props: {
            pageTitle: t("pages.kontakt.title"),
            leftAction: t("actions.backArrow"),
            onLeftActionClick: async () => navigate(-1),
          },
        },
        {
          path: "om",
          props: {
            pageTitle: t("pages.omSos.title"),
            leftAction: t("actions.backArrow"),
            onLeftActionClick: async () => navigate(-1),
          },
        },
        {
          path: "aldersbestemmelse",
          props: {
            pageTitle: t("pages.aldersbestemmelse.title"),
            leftAction: t("actions.backArrow"),
            onLeftActionClick: async () => {
              navigate(-1);
            },
          },
        },
      ],
    },
    {
      path: "registrering",
      props: {
        pageTitle: t("pages.registrering.title"),
      },
      children: [
        map,
        {
          path: "radyr",
          props: {
            pageTitle: t("pages.registrerArt.rådyr.title"),
            leftAction: t("actions.back"),
            onLeftActionClick: showCancelDialog,
            dialogs: cancelDialogs,
            hideNavbar: true,
          },
          children: [map],
        },
        {
          path: "elg",
          props: {
            pageTitle: t("pages.registrerArt.elg.title"),
            leftAction: t("actions.back"),
            onLeftActionClick: showCancelDialog,
            dialogs: cancelDialogs,
            hideNavbar: true,
          },
          children: [map],
        },
        {
          path: "villrein",
          props: {
            pageTitle: t("pages.registrerArt.villrein.title"),
            leftAction: t("actions.back"),
            onLeftActionClick: showCancelDialog,
            dialogs: cancelDialogs,
            hideNavbar: true,
          },
          children: [map],
        },
        {
          path: "hjort",
          props: {
            pageTitle: t("pages.registrerArt.hjort.title"),
            leftAction: t("actions.back"),
            onLeftActionClick: showCancelDialog,
            dialogs: cancelDialogs,
            hideNavbar: true,
          },
          children: [map],
        },
        {
          path: "villsvin",
          props: {
            pageTitle: t("pages.registrerArt.villsvin.title"),
            leftAction: t("actions.back"),
            onLeftActionClick: showCancelDialog,
            dialogs: cancelDialogs,
            hideNavbar: true,
          },
          children: [map],
        },
        {
          path: "fellingsinfo",
          props: {},
          children: [
            {
              path: ":id",
              props: {
                pageTitle: t("pages.registrerArt.fellingsinfo.title"),
                leftAction: t("actions.back"),
                onLeftActionClick: showCancelDialog,
                dialogs: cancelDialogs,
                hideNavbar: true,
              },
            },
          ],
        },
        {
          path: "jaktdag",
          props: {},
          children: [
            {
              path: ":id",
              props: {
                pageTitle: t("pages.redigerJaktdag.title"),
                leftAction: t("actions.cancel"),
                onLeftActionClick: showCancelDialog,
                dialogs: cancelDialogs,
                hideNavbar: true,
              },
              children: [map],
            },
          ],
        },
      ],
    },
    {
      path: "oversikt",
      props: {
        pageTitle: t("pages.oversikt.title"),
      },
      children: [
        {
          path: "skuttdyrdetaljer",
          props: {
            pageTitle: "",
            leftAction: t("actions.backArrow"),
            onLeftActionClick: async () => navigateToOversiktSkutteDyr(),
          },
        },
        {
          path: "jaktdagdetaljer",
          props: {
            pageTitle: "",
            leftAction: t("actions.backArrow"),
            onLeftActionClick: async () => navigateToOversiktJaktdager(),
          },
        },
      ],
    },
  ];

  const defaultPageProps = {
    pageTitle: t("pages.default.title"),
    onLeftActionClick: () => {},
    onRightActionClick: () => {},
  };

  const props = parsePathProps(location.pathname, pathProps);
  if (props) return props;
  return defaultPageProps;
};
export default useRouteProps;
