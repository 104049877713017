import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { IndividFormDataContext } from "~/src/pages/Registrering/components/IndividForm";
import { AccordionDefault } from "../../../components/Accordion/AccordionDefault";
import Checkbox from "../../../components/Form/Checkbox";
import InputDefault from "../../../components/Form/Input/InputDefault";
import Text from "../../../components/Text/Text";
import AppDataContext from "../../../contexts/AppDataContext/DataProvider";
import { ArtType } from "../../../helpers/types";

export default function KvitteringsKopi(): JSX.Element {
  const { t } = useTranslation();
  const { formData, formErrors, setFormData } = useFormDataContext(IndividFormDataContext);
  const { KvitteringEgen, KvitteringJaktleder, KvitteringValdansvarlig, KvitteringAnnen } =
    formData;
  const { jegeropplysninger } = useContext(AppDataContext);

  const setKvitteringValdansvarlig = (sendKvittering: boolean) =>
    setFormData({ ...formData, KvitteringValdansvarlig: sendKvittering });
  const setKvitteringAnnen = (epost: string) =>
    setFormData({ ...formData, KvitteringAnnen: epost ? epost : undefined });
  const setKvitteringEgen = (sendKvittering: boolean) =>
    setFormData({ ...formData, KvitteringEgen: sendKvittering });
  const setKvitteringJaktleder = (sendKvittering: boolean) =>
    setFormData({ ...formData, KvitteringJaktleder: sendKvittering });

  return (
    <AccordionDefault headerContent={t("pages.registrerArt.epostBekreftelse.title")}>
      <Text variant="h6">{t("pages.registrerArt.epostBekreftelse.subHeader")}</Text>

      <div className="gap-y-1">
        <div className="gap-x-4">
          <div>
            <Checkbox
              label={t("pages.registrerArt.epostBekreftelse.valdansvarlig")}
              checked={KvitteringValdansvarlig ?? false}
              onChange={() => setKvitteringValdansvarlig(!KvitteringValdansvarlig)}
            />
          </div>
          <div>
            {[ArtType.Hjort, ArtType.Elg].includes(formData.Art) && (
              <Checkbox
                label={t("pages.registrerArt.epostBekreftelse.jaktleder")}
                checked={KvitteringJaktleder ?? false}
                onChange={() => setKvitteringJaktleder(!KvitteringJaktleder)}
              />
            )}
          </div>
          <div>
            <Checkbox
              label={
                t("pages.registrerArt.epostBekreftelse.meg") +
                (!jegeropplysninger.data?.Epost ? "*" : "")
              }
              checked={KvitteringEgen ?? false}
              onChange={() => setKvitteringEgen(!KvitteringEgen)}
              disabled={!jegeropplysninger.data?.Epost}
            />
            {!jegeropplysninger.data?.Epost && (
              <Text variant="small" className="ps-2">
                {t("pages.registrerArt.epostBekreftelse.helperText")}
              </Text>
            )}
          </div>
        </div>
      </div>

      <div className="w-full pt-4">
        <InputDefault
          id={"input-kvitteringskopi-annenEpost"}
          label={t("pages.registrerArt.epostBekreftelse.annenEpostLabel")}
          placeholder={t("pages.registrerArt.epostBekreftelse.annenEpostPlaceholder")}
          onValueChanged={(value) => setKvitteringAnnen(value)}
          defaultValue={KvitteringAnnen ?? ""}
          validationErrorMessage={formErrors?.KvitteringAnnen}
        />
      </div>
    </AccordionDefault>
  );
}
