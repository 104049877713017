import { QueryFunctionContext } from "@tanstack/react-query";
import {
  CreateIndividRequest,
  CreateJaktdagRequest,
  Favorittområde,
  IndividMedJaktdagResponse,
  IndividResponse,
  JaktdagResponse,
  JegerOpplysningerResponse,
  OmrådeData,
  UpdateIndividRequest,
  UpdateJaktdagRequest,
} from "../api/types";
import { jaktdataApi } from "../appGlobals";
import { formatISOWithoutTimestamp } from "../helpers/time";
import { ArtType } from "../helpers/types";
import { getParams } from "./params";

export const getJegeropplysningerQuery = async (): Promise<JegerOpplysningerResponse> => {
  return jaktdataApi.getJegeropplysninger();
};

export const getJaktfeltQuery = async (): Promise<OmrådeData> => {
  return jaktdataApi.getJaktfelt();
};

export const getValdQuery = async (): Promise<OmrådeData> => {
  return jaktdataApi.getVald();
};

export const getVillreinValdQuery = async (): Promise<OmrådeData> => {
  return jaktdataApi.getVillreinVald();
};

type GetIndividParams = {
  individId: string;
};
export const getIndividQuery = async (
  context: QueryFunctionContext,
): Promise<IndividMedJaktdagResponse> => {
  const params = getParams<GetIndividParams>(context);

  return jaktdataApi.getIndivid(params.individId).then(async (individ) => {
    let individResponse: IndividMedJaktdagResponse = individ;
    if (individ.JaktdagId) {
      individResponse = {
        Jaktdag: await jaktdataApi.getJaktdag(individ.JaktdagId),
        ...individ,
      };
    }
    return { ...individResponse };
  });
};

export const postIndividQuery = async (individ: CreateIndividRequest): Promise<string> => {
  return jaktdataApi.postIndivid(individ);
};

export const putIndividQuery = async (individ: UpdateIndividRequest): Promise<void> => {
  return jaktdataApi.putIndivid(individ);
};

export type GetJaktdagParams = {
  jaktdagId: string;
};
export const getJaktdagQuery = async (context: QueryFunctionContext): Promise<JaktdagResponse> => {
  const params = getParams<GetJaktdagParams>(context);
  return jaktdataApi.getJaktdag(params.jaktdagId);
};

export const getJaktområderQuery = async (): Promise<Favorittområde[]> => {
  return jaktdataApi.getJaktområder().then((jaktomrader: Favorittområde[]) => {
    return jaktomrader.length <= 0 ? [] : jaktomrader;
  });
};
export type GetJaktdagerParams = {
  jaktomradeId: string;
  sesong?: number;
  artFilter?: ArtType[];
  dateFilter?: Date;
  jegernummerFilter?: number;
};

export function getJaktdagerQuery(context: QueryFunctionContext): Promise<JaktdagResponse[]> {
  const params = getParams<GetJaktdagerParams>(context);
  const artFilter = params.artFilter?.map((art) => art.toString());
  const dateFilter = params.dateFilter && formatISOWithoutTimestamp(params.dateFilter);
  const jegernummerFilter = params.jegernummerFilter;

  return jaktdataApi
    .getJaktdager(params.sesong || new Date().getFullYear(), params.jaktomradeId)
    .then((jaktdager) => {
      return jaktdager.filter((jaktdag) => {
        return (
          (artFilter !== undefined ? artFilter?.includes(jaktdag.Art || "") : true) &&
          (dateFilter !== undefined
            ? formatISOWithoutTimestamp(jaktdag.Dato) === dateFilter
            : true) &&
          (jegernummerFilter !== undefined ? jaktdag.Jegernummer === jegernummerFilter : true)
        );
      });
    });
}

export const postJaktdagQuery = async (jaktdag: CreateJaktdagRequest): Promise<string> => {
  return jaktdataApi.postJaktdag(jaktdag);
};

export const putJaktdagQuery = async (jaktdag: UpdateJaktdagRequest): Promise<void> => {
  return jaktdataApi.putJaktdag(jaktdag);
};

export type GetSkutteDyrParams = {
  jaktomradeId: string;
  sesong?: number;
  artFilter?: ArtType[];
  dateFilter?: Date;
};

export function getSkutteDyrQuery(context: QueryFunctionContext): Promise<IndividResponse[]> {
  const params = getParams<GetSkutteDyrParams>(context);
  const artFilter = params.artFilter?.map((art) => art.toString());
  const dateFilter = !!params.dateFilter && formatISOWithoutTimestamp(params.dateFilter);
  return jaktdataApi
    .getSkutteDyr(params.sesong || new Date().getFullYear(), params.jaktomradeId)
    .then((skutteDyr) => {
      let _skutteDyr = skutteDyr;
      _skutteDyr =
        (artFilter && skutteDyr.filter((skuttDyr) => artFilter.includes(skuttDyr.Art || ""))) ||
        _skutteDyr;
      _skutteDyr =
        (dateFilter &&
          skutteDyr.filter(
            (skuttDyr) => formatISOWithoutTimestamp(skuttDyr.Dato) === dateFilter,
          )) ||
        _skutteDyr;
      return _skutteDyr;
    });
}

export type GetSkuttDyrParams = {
  individId: string;
};

export function getSkuttDyrQuery(context: QueryFunctionContext): Promise<IndividResponse> {
  const params = getParams<GetSkuttDyrParams>(context);

  return jaktdataApi.getIndivid(params.individId).then((skuttDyr) => {
    return skuttDyr;
  });
}
