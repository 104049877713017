import { SumSettOgSkuttElg } from "../pages/Registrering/Jaktdag/RegistrerElg/ElgTypes";

export enum ArtType {
  Elg = "Elg",
  Hjort = "Hjort",
  Rådyr = "Rådyr",
  Villrein = "Villrein",
  Villsvin = "Villsvin",
}

export enum AlderType {
  Kalv = "Kalv",
  Ungdyr = "Ungdyr",
  Voksen = "Voksen",
}

export enum KjønnType {
  Hann = "Hann",
  Hunn = "Hunn",
}

export enum OmradeType {
  Kommune = "Kommune",
  Jaktfelt = "Jaktfelt",
  Vald = "Vald",
  VillreinVald = "Villreinvald",
  Ukjent = "Ukjent",
}

export const OmradeTypeForArt = {
  [ArtType.Elg]: "Jaktfelt",
  [ArtType.Rådyr]: "Vald",
  [ArtType.Villrein]: "Villreinvald",
  [ArtType.Hjort]: "Jaktfelt",
  [ArtType.Villsvin]: "Kommune", //TODO dobbeltsjekk om villsvin skal være kommune
};

export type SumSettOgSkuttJaktdag = SumSettOgSkuttElg & {
  skuttSpissbukker: number;
  skuttBukkerInnmark: number;
  skuttKyrInnmark: number;
  skuttKalverInnmark: number;
  settBukkInnmark: number;
  settKalverInnmark: number;
  settKyrInnmark: number;
  settUkjenteInnmark: number;
  sumSkuttInnmark: number;
  sumSettInnmark: number;
  isInconsistent: boolean;
  errors: {
    utmark: boolean;
    innmark: boolean;
    okser: boolean;
    spissbukk: boolean;
    kyr: boolean;
    kalver: boolean;
    bukkInnmark: boolean;
    spissbukkInnmark: boolean;
    kyrInnmark: boolean;
    kalverInnmark: boolean;
  };
};

// Utility types for expanding types
// Usage: Expand<YourType> / ExpandRecursively<YourType>
export type Expand<T> = T extends (...args: infer A) => infer R
  ? (...args: Expand<A>) => Expand<R>
  : T extends infer O
    ? { [K in keyof O]: O[K] }
    : never;

export type ExpandRecursively<T> = T extends (...args: infer A) => infer R
  ? (...args: ExpandRecursively<A>) => ExpandRecursively<R>
  : T extends object
    ? T extends infer O
      ? { [K in keyof O]: ExpandRecursively<O[K]> }
      : never
    : T;
