import { CardHeader, CardHeaderProps, Typography } from "@material-tailwind/react";
import { CloudOff, Sync } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import { RouteProps } from "../../../../hooks/useRouteProps";

/**
 * @typedef {Omit<CardHeaderProps, "ref" | "children">} CardHeaderDefaultProps
 * @property {string | number} id - A unique identifier for the card header.
 * @property {RouteProps} [pathProps] - Object that contains the left action, right action, `onLeftActionClick` function, and `onRightActionClick` function.
 */
export type CardHeaderDefaultProps = Omit<CardHeaderProps, "ref" | "children"> & {
  pathProps: RouteProps;
  className?: string;
};

/**
 * CardHeaderDefault is a React component that renders a card header with optional left and right actions.
 * @param {CardHeaderDefaultProps} props - The properties that define the CardHeaderDefault component.
 * @param {React.Ref<HTMLDivElement>} ref - The ref to be forwarded to the CardHeader component.
 * @returns {React.ReactElement} The CardHeaderDefault component.
 */
const CardHeaderDefault: React.ForwardRefRenderFunction<HTMLDivElement, CardHeaderDefaultProps> = (
  { pathProps, className, ...rest },
  ref,
) => {
  const {
    pageTitle,
    leadingText,
    leftAction,
    rightAction,
    onLeftActionClick,
    onRightActionClick,
    dialogs,
  } = pathProps;
  const appData = useContext(AppDataContext);
  const isCardHeaderBackArrow = leftAction === "\u2190";
  const contextIconClassName = "text-2xl text-white pb-1";
  const { t } = useTranslation();

  const showOfflineIndicator = appData.isOnline.value === false;
  const showSyncIndicator = appData.isOnline.value === true && appData.isSynced.value === false;

  return (
    <CardHeader
      id={pageTitle}
      data-testid={`card-header-${pageTitle}-id`}
      ref={ref}
      floated={false}
      shadow={false}
      className={`w-full bg-md-seagray m-0 rounded-none items-center p-2 overflow-visible ${className}`}
      {...rest}
    >
      <div className="flex items-center h-10">
        {/*TODO: semantisk sett så burde dette være en lenke eller en knapp, og ikke en paragraf*/}
        <Typography
          className={`text-white text-center pl-5 cursor-pointer absolute ${
            isCardHeaderBackArrow ? "h-12 text-2xl pt-1 pl-2" : "text-sm"
          }`}
          onClick={onLeftActionClick}
        >
          {leftAction || ""}
        </Typography>
        {(showOfflineIndicator || showSyncIndicator) && <div className="w-6" />}
        <Typography variant="h1" className="text-lg font-bold text-white text-center m-auto">
          {pageTitle || ""}
        </Typography>
        <Typography
          className="text-sm text-white text-center pr-5 cursor-pointer"
          onClick={onRightActionClick}
        >
          {rightAction || ""}
        </Typography>
        {showOfflineIndicator ? (
          <CloudOff className={contextIconClassName} />
        ) : showSyncIndicator ? (
          <IconButton onClick={() => window.location.reload()}>
            <div className="flex flex-row items-start justify-center text-sm text-white">
              <Sync className={contextIconClassName} />
              {t("actions.sync")}
            </div>
          </IconButton>
        ) : (
          ""
        )}
      </div>
      <div>
        <Typography className="text-white px-5 pb-2">{leadingText || ""}</Typography>
      </div>
      {dialogs &&
        dialogs.map((dialog, index) => <React.Fragment key={index}>{dialog}</React.Fragment>)}
    </CardHeader>
  );
};

export default React.forwardRef(CardHeaderDefault);
