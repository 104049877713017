import { Område } from "../../../api/types";
import { Stateful } from "../../../hooks/useStateful";

export default function OmradeBanner({ omrade }: { omrade: Stateful<Område | null | undefined> }) {
  return (
    <div className="px-4 py-2 bg-md-secondary text-md-gray">
      {omrade.value?.Navn} <span className="ml-2 text-xs">{omrade.value?.Id}</span>
    </div>
  );
}
