export default function Field({
  label,
  value,
  show = true,
}: {
  label: string;
  value: JSX.Element | string | null | undefined;
  show?: boolean;
}): JSX.Element {
  return (
    <>
      {show && (
        <div className="flex gap-2">
          <div className="text-md-gray text-nowrap">{label}:</div>
          <div>{value}</div>
        </div>
      )}
    </>
  );
}
