import { formatISO } from "date-fns";
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import AppDataContext, { AppData } from "~/src/contexts/AppDataContext/DataProvider";
import { getOmradeForSingleFavoritt } from "~/src/helpers/omradeHelper";
import { formatISOWithoutTimestamp } from "~/src/helpers/time";
import {
  CreateIndividRequest,
  CreateJaktdagRequest,
  IndividResponse,
  JaktdagResponse,
} from "../../../../api/types";
import { AlderType, ArtType, KjønnType } from "../../../../helpers/types";
import {
  JaktdagRegistrationDataElg,
  SkuttDataElg,
  SumSettOgSkuttElg,
  defaultElg,
} from "./ElgTypes";

export const parseInitialData = (
  initialData?: JaktdagResponse,
  appData?: AppData,
): JaktdagRegistrationDataElg | undefined => {
  const { jaktfelt } = useContext(AppDataContext);

  // pull out individer og lage en skuttData av dem.
  const jaktFeltNavn =
    jaktfelt.data?.data.find((felt) => felt.Id === initialData?.Jaktfelt)?.Navn || "";

  const skuttData: SkuttDataElg = {
    SkuttOkseToOgHalvtÅrPluss: 0,
    SkuttKuToOgHalvtÅrPluss: 0,
    SkuttOkseEttOgHalvtÅr: 0,
    SkuttKuEttOgHalvtÅr: 0,
    SkuttOkseKalv: 0,
    SkuttKuKalv: 0,
  };
  for (const individ of initialData?.Individer || []) {
    const key: keyof SkuttDataElg = findKey(individ.Alder as AlderType, individ.Kjønn as KjønnType);
    if (key) {
      skuttData[key] += 1;
    }
  }

  const favorittOmråde = appData && getOmradeForSingleFavoritt(appData, ArtType.Elg);
  const _data: JaktdagRegistrationDataElg = {
    ...defaultElg,
    Jegernummer: initialData?.Jegernummer || appData?.jegeropplysninger.data?.Jegernummer,
    jaktomradeNavn: jaktFeltNavn || favorittOmråde?.Områdenavn || "",
    ...skuttData,
    Dato: initialData?.Dato || formatISOWithoutTimestamp(new Date())!,
    Id: initialData?.Id || "",
    Jaktfelt: initialData?.Jaktfelt || favorittOmråde?.Områdekode || "",
    SettKalverAlene: initialData?.SettKalverAlene || 0,
    SettKyrMed1Kalv: initialData?.SettKyrMed1Kalv || 0,
    SettKyrMed2EllerFlereKalver: initialData?.SettKyrMed2EllerFlereKalver || 0,
    SettKyrUtenKalvEllerKolle: initialData?.SettKyrUtenKalvEllerKolle || 0,
    SettOkseEllerBukk: initialData?.SettOkseEllerBukk || 0,
    SettUkjente: initialData?.SettUkjente || 0,
    AntallJegere: initialData?.AntallJegere || 0,
    AntallTimerJaktet: initialData?.AntallTimerJaktet || 0,
  };
  return _data;
};

export function sumSettOgSkuttElg(data: JaktdagRegistrationDataElg): SumSettOgSkuttElg {
  const settOgSkutt = {
    skuttOkser: data.SkuttOkseToOgHalvtÅrPluss + data.SkuttOkseEttOgHalvtÅr,
    skuttKyr: data.SkuttKuToOgHalvtÅrPluss + data.SkuttKuEttOgHalvtÅr,
    skuttKalver: data.SkuttOkseKalv + data.SkuttKuKalv,
    settOkser: data.SettOkseEllerBukk || 0,
    settKalver:
      (data.SettKyrMed1Kalv || 0) +
      2 * (data.SettKyrMed2EllerFlereKalver || 0) +
      (data.SettKalverAlene || 0),
    settKyr:
      (data.SettKyrUtenKalvEllerKolle || 0) +
      (data.SettKyrMed1Kalv || 0) +
      (data.SettKyrMed2EllerFlereKalver || 0),
    settUkjente: data.SettUkjente || 0,
  };
  return {
    ...settOgSkutt,
    sumSkutt: settOgSkutt.skuttOkser + settOgSkutt.skuttKyr + settOgSkutt.skuttKalver,
    sumSett:
      settOgSkutt.settOkser +
      settOgSkutt.settKalver +
      settOgSkutt.settKyr +
      settOgSkutt.settUkjente,
  };
}

const findSkutt = (
  skutteDyr: IndividResponse[] | undefined,
  alder: AlderType,
  kjonn: KjønnType,
) => {
  if (!skutteDyr) return [];
  return skutteDyr.filter((individ) => individ.Alder === alder && individ.Kjønn === kjonn);
};

const findKey = (alder: AlderType, kjønn: KjønnType): keyof SkuttDataElg => {
  return skutteTyper.find((field) => field.Alder === alder && field.Kjønn === kjønn)
    ?.Key as keyof SkuttDataElg;
};

const skutteTyper = [
  {
    Alder: AlderType.Voksen,
    Kjønn: KjønnType.Hann,
    Key: "SkuttOkseToOgHalvtÅrPluss",
  },
  {
    Alder: AlderType.Ungdyr,
    Kjønn: KjønnType.Hann,
    Key: "SkuttOkseEttOgHalvtÅr",
  },
  {
    Alder: AlderType.Voksen,
    Kjønn: KjønnType.Hunn,
    Key: "SkuttKuToOgHalvtÅrPluss",
  },
  {
    Alder: AlderType.Ungdyr,
    Kjønn: KjønnType.Hunn,
    Key: "SkuttKuEttOgHalvtÅr",
  },
  {
    Alder: AlderType.Kalv,
    Kjønn: KjønnType.Hann,
    Key: "SkuttOkseKalv",
  },
  {
    Alder: AlderType.Kalv,
    Kjønn: KjønnType.Hunn,
    Key: "SkuttKuKalv",
  },
];

export const toJaktdagRequestElg = (
  data: JaktdagRegistrationDataElg,
  date: Date,
  jegernummer?: number,
  Individer?: IndividResponse[],
) => {
  const jaktdagId = data.Id || uuid();

  let individer = new Array<CreateIndividRequest>();

  const oppdaterAntallSkutt = (alder: AlderType, kjønn: KjønnType) => {
    const key = findKey(alder, kjønn) as keyof SkuttDataElg;
    let skutteAvDenneTypen = findSkutt(Individer, alder, kjønn);
    const antallFraSkjema = data[key];

    if (!antallFraSkjema && !skutteAvDenneTypen.length) return;

    const antallSomSkalLeggesTil = antallFraSkjema - skutteAvDenneTypen.length;
    if (antallSomSkalLeggesTil > 0) {
      for (let i = 0; i < antallSomSkalLeggesTil; i++) {
        individer.push({
          Id: uuid(),
          Dato: formatISO(date, { representation: "date" }),
          JaktdagId: jaktdagId,
          Jaktfelt: data.Jaktfelt,
          Alder: alder,
          Art: ArtType.Elg,
          Kjønn: kjønn,
          Jegernummer: jegernummer,
        });
      }
    } else {
      const antallSomSkalSlettes = skutteAvDenneTypen.length - antallFraSkjema;
      if (antallSomSkalSlettes > 0) {
        const kanSlettes = (dyr: IndividResponse) =>
          !(dyr.SlaktevektKg || dyr.Merkelapp || dyr.Godkjent);

        const dyrSomKanSlettes = skutteAvDenneTypen.filter(kanSlettes);
        const dyrSomIkkeKanSlettes = skutteAvDenneTypen.filter((dyr) => !kanSlettes(dyr));
        if (dyrSomKanSlettes.length < antallSomSkalSlettes) {
          throw new Error("CanNotDelete");
        }

        skutteAvDenneTypen = [
          ...dyrSomIkkeKanSlettes,
          ...dyrSomKanSlettes.slice(0, dyrSomKanSlettes.length - antallSomSkalSlettes),
        ];
      }
    }
    // transformer skutteAvdennetypen from IndividResponse to CreateIndividRequest, med de nye verdiene
    const addableIndivider: CreateIndividRequest[] = skutteAvDenneTypen.map(
      (individ) => ({ ...individ, Dato: formatISO(date) }) as CreateIndividRequest,
    );
    individer = [...individer, ...addableIndivider];
  };

  for (const type of skutteTyper) {
    oppdaterAntallSkutt(type.Alder, type.Kjønn);
  }

  const request: CreateJaktdagRequest = {
    ...data,
    Id: jaktdagId,
    Dato: formatISO(date),
    Art: ArtType.Elg,
    Individer: individer,
  };
  return request;
};
