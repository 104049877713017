import CircularProgress from "@mui/material/CircularProgress";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { ArtIcons } from "~/src/helpers/icons";
import type { JaktomradeResponse } from "../../api/types";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import { harJaktPaArt } from "../../helpers/filters";
import type { ArtType } from "../../helpers/types";
import { OmradeTypeForArt } from "../../helpers/types";
import { Stateful, useStateful } from "../../hooks/useStateful";
import i18n from "../../i18n";
import Combobox, { ComboboxOption } from "../Form/Combobox/Combobox";
import ErrorText from "../Text/ErrorText";
import Text from "../Text/Text";

type AreaPickerProps = {
  onChange: (selected: ComboboxOption<JaktomradeResponse> | null) => void;
  valgtArt?: ArtType;
  defaultOmradekode?: string;
  helperText?: string;
  errorText?: string;
  disabled?: boolean;
  selectedState?: Stateful<ComboboxOption>;
  visAlleOmrader?: boolean;
};

export default function JaktomradePicker({
  onChange,
  valgtArt,
  helperText,
  errorText,
  defaultOmradekode,
  disabled = false,
  selectedState,
  visAlleOmrader = false,
}: AreaPickerProps): JSX.Element {
  const data = React.useContext(AppDataContext);
  const initialSelected = selectedState?.value || {
    label: "",
    id: "",
    data: {} as JaktomradeResponse,
  };

  const [selected, setSelected] = useState<ComboboxOption>(initialSelected);
  const open = useStateful(false);

  const options = useMemo(() => {
    if (data.jaktområder.data !== undefined) {
      const _options: ComboboxOption<JaktomradeResponse>[] = [];

      if (!visAlleOmrader) {
        // bare favorittområder
        [...data.jaktområder.data].forEach((omrade) => {
          if (!valgtArt || harJaktPaArt(valgtArt, omrade)) {
            _options.push({
              label: omrade.Områdenavn ?? ("Navn" in omrade ? (omrade.Navn as string) : ""),
              id: omrade.Områdekode ?? "",
              data: omrade,
            });
          }
        });
      } else {
        // all the områder
        data.vald.data?.data
          .concat(data.jaktfelt.data?.data.concat(data.villreinvald.data?.data || []) || [])
          .forEach((omrade) => {
            if (!valgtArt || harJaktPaArt(valgtArt, omrade)) {
              _options.push({
                label: "Navn" in omrade ? omrade.Navn || "" : "",
                id: omrade.Id ?? "",
                data: omrade,
              });
            }
          });
      }

      const sortedOptions = _options.sort((a, b) => {
        if (a?.label && b?.label) {
          return a.label.localeCompare(b.label, i18n.language);
        } else {
          return 0;
        }
      });
      return sortedOptions;
    }
    return [];
  }, [data.jaktområder.data, valgtArt]);

  useEffect(() => {
    if (!selected?.id) return;

    onChange(selected);

    if (selectedState) {
      selectedState.set(selected);
    }
  }, [selected]);

  useEffect(() => {
    const selectedOption =
      options.length === 1 ? options[0] : options.find((opt) => opt.id === defaultOmradekode);
    if (selectedOption) {
      setSelected(selectedOption);
    }
  }, [options, defaultOmradekode]);

  if (data.jaktområder.isLoading) {
    return <CircularProgress color="success" />;
  }
  let jaktomradeType = valgtArt ? OmradeTypeForArt[valgtArt] : " jaktområde";
  jaktomradeType = jaktomradeType === "Villreinvald" ? "Vald" : jaktomradeType;
  jaktomradeType = jaktomradeType.toLowerCase();

  return (
    <div>
      <div>
        <Combobox
          options={options}
          disabled={disabled}
          onChange={(value) => {
            setSelected(value || { id: "", label: "", data: {} as JaktomradeResponse });
          }}
          label={`${t("actions.select")} ${jaktomradeType}`}
          defaultValue={selected || initialSelected}
          noOptionsText={t("components.jaktomradePicker.noOptionsText")}
          renderOption={(props, option: ComboboxOption<JaktomradeResponse>, state) => {
            if (option.id && option.label) {
              return (
                <li
                  {...props}
                  key={state.index}
                  className={` ${props.className} w-full flex flex-row items-center justify-between p-2`}
                >
                  {option.label}
                  <div className={"flex items-center gap-2 text-md-gray text-xs"}>
                    {ArtIcons({ ...option.data })} {option.id}
                  </div>
                </li>
              );
            }
            return <li key={state.index} />;
          }}
          open={open}
          showIcon={!disabled}
        />
        {selected && !open.value && (
          <div className="text-xs bg-md-greengray text-md-primary relative bottom-2 mt-0 pl-11 pb-1 border-b-2 border-md-secondary">
            {selected.id}
          </div>
        )}
      </div>
      <div className={`ps-2 mb-4 ${selected && !open.value ? "-mt-2" : ""}`}>
        {errorText ? (
          <ErrorText>{errorText}</ErrorText>
        ) : helperText ? (
          <Text variant="small">{helperText}</Text>
        ) : null}
      </div>
    </div>
  );
}
