import { format } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import { ArtType } from "~/src/helpers/types";
import { CreateJaktdagRequest } from "../../../../api/types";

export const defaultElg: JaktdagRegistrationDataElg = {
  AntallJegere: 0,
  AntallTimerJaktet: 0,
  SettOkseEllerBukk: 0,
  SettKyrUtenKalvEllerKolle: 0,
  SettKyrMed1Kalv: 0,
  SettKyrMed2EllerFlereKalver: 0,
  SettKalverAlene: 0,
  SettUkjente: 0,
  SkuttOkseToOgHalvtÅrPluss: 0,
  SkuttOkseEttOgHalvtÅr: 0,
  SkuttKuToOgHalvtÅrPluss: 0,
  SkuttKuEttOgHalvtÅr: 0,
  SkuttOkseKalv: 0,
  SkuttKuKalv: 0,
  jaktomradeNavn: "",
  Id: "",
  Dato: format(new Date(), dateFormatISO),
  Art: ArtType.Elg,
  Jaktfelt: "",
};

export type SettDataElg = {
  SettOkseEllerBukk?: number;
  SettKyrUtenKalvEllerKolle?: number;
  SettKyrMed1Kalv?: number;
  SettKyrMed2EllerFlereKalver?: number;
  SettKalverAlene?: number;
  SettUkjente?: number;
};

export const settElgFields: (keyof SettDataElg)[] = [
  "SettOkseEllerBukk",
  "SettKyrUtenKalvEllerKolle",
  "SettKyrMed1Kalv",
  "SettKyrMed2EllerFlereKalver",
  "SettKalverAlene",
  "SettUkjente",
];

export type SkuttDataElg = {
  SkuttOkseToOgHalvtÅrPluss: number;
  SkuttOkseEttOgHalvtÅr: number;
  SkuttKuToOgHalvtÅrPluss: number;
  SkuttKuEttOgHalvtÅr: number;
  SkuttOkseKalv: number;
  SkuttKuKalv: number;
};

export const skuttElgFields: (keyof SkuttDataElg)[] = [
  "SkuttOkseToOgHalvtÅrPluss",
  "SkuttOkseEttOgHalvtÅr",
  "SkuttKuToOgHalvtÅrPluss",
  "SkuttKuEttOgHalvtÅr",
  "SkuttOkseKalv",
  "SkuttKuKalv",
];

export type JaktdagRegistrationDataElg = {
  jaktomradeNavn?: string;
} & SkuttDataElg &
  CreateJaktdagRequest;

export type SumSettOgSkuttElg = {
  skuttOkser: number;
  skuttKyr: number;
  skuttKalver: number;
  sumSkutt: number;
  settOkser: number;
  settKalver: number;
  settKyr: number;
  settUkjente: number;
  sumSett: number;
};
