export const today = new Date();
export const currentYear = today.getFullYear();
export const currentMonth = today.getMonth();
export const firstSelectableDate = new Date(currentYear, 3, 1);
export const april = 3;
export const numberOfMonths =
  currentMonth >= april ? currentMonth - april + 1 : currentMonth + 12 - april + 1;
export const dateFormatNorsk = "dd.MM.yy";
export const dateFormatISO = "yyyy-MM-dd";
export const calendarClasses = {
  caption: "flex py-2 mb-4",
  caption_label: "text-sm font-medium text-gray-900",
  nav: "flex items-center",
  nav_button: "h-6 w-6 p-1 rounded-md duration-300",
  nav_button_previous: "absolute left-1.5",
  nav_button_next: "absolute right-1.5",
  table: "border-collapse",
  head_row: "flex font-medium text-gray-900",
  head_cell: "m-0.5 w-9 font-normal text-sm",
  row: "flex mt-2",
  cell: "text-gray-600 rounded-full h-9 w-9 text-center  p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
  day: "h-9 w-9 p-0 font-normal",
  day_range_end: "day-range-end",
  day_selected: "rounded-full border-none bg-md-sea-green-medium text-white",
  day_today: "border border-md-sea-green-light rounded-full text-gray-900 ",
  day_outside:
    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
  day_disabled: "text-gray-500 opacity-50",
  day_hidden: "invisible",
};
