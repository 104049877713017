import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Text from "../../components/Text/Text";
import TomtResultat from "../Oversikt/Components/TomtResultat";

const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <TomtResultat>
      <Text variant={"h1"}>Siden finnes ikke</Text>
      <Button className="text-blue-500 underline text-xl" onClick={() => navigate(-1)}>
        Gå tilbake
      </Button>
    </TomtResultat>
  );
};

export default ErrorPage;
