import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext/AuthProvider";
import { useStateful } from "../hooks/useStateful";

const ManagedRoute = (props: { element: JSX.Element }): JSX.Element => {
  const auth = useContext(AuthContext);
  const isLoggedIn = useStateful(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }

    const verifyLogin = async () => {
      if (auth.token().length > 0) {
        // We don't care about token validity here, token is verified in the queryClient
        isLoggedIn.set(true);
      } else {
        isLoggedIn.set(false);
        navigate("/login");
      }
    };
    verifyLogin();
  }, [location.pathname, auth, isLoggedIn, navigate]);

  return isLoggedIn.value ? props.element : <div />;
};

export default ManagedRoute;
