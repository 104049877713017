import ButtonDefault, { ButtonDefaultProps, ButtonVariant } from "./ButtonDefault";

import { artIcon } from "../../helpers/icons";
import { ArtType } from "../../helpers/types";
import { Stateful, useStateful } from "../../hooks/useStateful";

export type ArtButtonProps = {
  art: ArtType;
  selected: boolean;
} & Omit<ButtonDefaultProps, "id" | "label">;

export function ArtButton({ art, selected, ...rest }: ArtButtonProps): JSX.Element {
  const artButtonClassName = `col-span-1 p-3 px-4 m-1 font-semibold text-xl justify-center gap-1 ${
    selected ? "text-white" : "text-md-gray"
  }`;

  return (
    <ButtonDefault
      id={`${art}Button`}
      key={`${art}Button`}
      label={art}
      icon={artIcon(art)}
      variant={selected ? ButtonVariant.PrimarySquareish : ButtonVariant.SecondarySquareish}
      className={artButtonClassName}
      {...rest}
    />
  );
}

export type ArtButtonsProps = {
  selected?: Stateful<ArtType | undefined>;
  onClick?: (which: ArtType) => void;
};
export function ArtButtons({ selected, onClick }: ArtButtonsProps): JSX.Element {
  const localSelected = useStateful<ArtType | undefined>(undefined);
  const _selected = selected !== undefined ? selected : localSelected;
  const artButtons = [
    {
      art: ArtType.Elg,
      onClick: () => {
        _selected.set(ArtType.Elg);
        onClick && onClick(ArtType.Elg);
      },
    },
    {
      art: ArtType.Hjort,
      onClick: () => {
        _selected.set(ArtType.Hjort);
        onClick && onClick(ArtType.Hjort);
      },
    },
    {
      art: ArtType.Rådyr,
      onClick: () => {
        _selected.set(ArtType.Rådyr);
        onClick && onClick(ArtType.Rådyr);
      },
    },
    {
      art: ArtType.Villrein,
      onClick: () => {
        _selected.set(ArtType.Villrein);
        onClick && onClick(ArtType.Villrein);
      },
    },
  ];

  return (
    <div id="velgArtButtons" className="flex flex-col items-stretch justify-evenly p-4 gap-5">
      {artButtons.map((button, index) => {
        return (
          <ArtButton
            {...button}
            key={`${button.art}${index}`}
            selected={button.art == _selected.value}
          />
        );
      })}
    </div>
  );
}
