import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Stateful } from "../../hooks/useStateful";
import ButtonDefault, { ButtonLayout } from "../Button/ButtonDefault";

export enum DialogAlignment {
  Start,
  Center,
}

export type DialogDefaultProps = {
  title: string;
  message: string;
  open: Stateful<boolean>;
  aligment?: DialogAlignment;
  buttons?: ReactElement;
  buttonLayout?: ButtonLayout;
} & React.ComponentProps<"div">;

export default function DialogDefault({
  title,
  message,
  open,
  aligment,
  buttons,
  buttonLayout,
  ...restProps
}: DialogDefaultProps): JSX.Element {
  const { t } = useTranslation();
  const defaultCloseButton = (
    <ButtonDefault id="close" label={t("actions.close")} onClick={() => open.set(false)} />
  );
  const dialogClassName =
    aligment === undefined || aligment === DialogAlignment.Start
      ? ""
      : "flex flex-col justify-center items-center";

  const buttonsClassName =
    buttonLayout === undefined || buttonLayout === ButtonLayout.Horizontal
      ? "flex flex-row justify-end gap-2"
      : "flex flex-col items-center gap-2";

  return (
    <Dialog id={restProps.id} className={dialogClassName} open={open.value} handler={open.set}>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{message || t("")}</DialogBody>
      <DialogFooter className={buttonsClassName}>{buttons || defaultCloseButton}</DialogFooter>
    </Dialog>
  );
}
