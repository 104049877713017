import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLayout } from "../components/Button/ButtonDefault";
import DialogDefault, { DialogAlignment } from "../components/Dialog/DialogDefault";
import { useStateful } from "./useStateful";

export type DialogShowProps = {
  message?: string;
  title?: string;
  buttons?: ReactElement;
};

export type DialogHook = {
  show: (props?: DialogShowProps) => void;
  close: () => void;
  element: ReactElement;
  isOpen: boolean;
};

export type UseDialogProps = {
  title?: string;
  message?: string;
  alignment?: DialogAlignment;
  buttons?: ReactElement;
  buttonLayout?: ButtonLayout;
};

export function useDialog({
  title = "Info",
  message = "",
  buttonLayout = ButtonLayout.Horizontal,
  alignment = DialogAlignment.Start,
  buttons = undefined,
}: UseDialogProps = {}): DialogHook {
  const { t } = useTranslation();
  const defaultTitle = t("actions.info");
  const _open = useStateful<boolean>(false);
  const _title = useStateful<string>(title || defaultTitle);
  const _message = useStateful<string>(message || "");
  const _buttons = useStateful<ReactElement | undefined>(buttons);

  const element = DialogDefault({
    title: _title.value,
    message: _message.value,
    open: _open,
    buttons: _buttons.value,
    buttonLayout: buttonLayout,
    aligment: alignment,
  });

  const show = (props: DialogShowProps = {}) => {
    _title.set(props.title || title || defaultTitle);
    props.message && _message.set(props.message);
    props.buttons && _buttons.set(props.buttons);
    _open.set(true);
  };

  const close = () => {
    _open.set(false);
  };

  return { show, close, element, isOpen: _open.value };
}
