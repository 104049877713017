import { Route, Routes } from "react-router-dom";
import ErrorPage from "../Error/ErrorPage";
import FellingsInfo from "./Fellingsinfo/Fellingsinfo";
import RedigerJaktdag from "./Jaktdag/RedigerJaktdag";
import RegistrerElg from "./Jaktdag/RegistrerElg/RegistrerElg";
import RegistrerHjort from "./Jaktdag/RegistrerHjort/RegistrerHjort";
import RegistrerRadyr from "./RegistrerRadyr/RegistrerRadyr";
import RegistrerVillrein from "./RegistrerVillrein/RegistrerVillrein";
import VelgArt from "./VelgArt";
import Kart from "./components/kart/Kart";

export default function Registrering(): JSX.Element {
  return (
    <Routes>
      <Route index element={<VelgArt />} />
      <Route path="fellingsinfo/:individId" element={<FellingsInfo />} />
      <Route path="jaktdag/:jaktdagId" element={<RedigerJaktdag />} />
      <Route path="elg" element={<RegistrerElg />} />
      <Route path="hjort" element={<RegistrerHjort />} />
      <Route path="radyr" element={<RegistrerRadyr />} />
      <Route path="villrein" element={<RegistrerVillrein />} />
      <Route path="kart" element={<Kart />} />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
}
