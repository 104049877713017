import { ReactNode } from "react";
import { HornIcon } from "../../../helpers/icons";

export default function TomtResultat({
  className,
  children,
}: { children: ReactNode; className?: string }): JSX.Element {
  return (
    <div className={`text-center m-5 ${className}`}>
      <HornIcon className="m-auto" size={"50%"} />
      {children}
    </div>
  );
}
