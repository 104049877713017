import { TFunction } from "i18next";
import { z } from "zod";
import { Stateful } from "../../../../hooks/useStateful";
import { sumSettOgSkuttHjort } from "./HjortHelpers";
import { JaktdagRegistrationDataHjort } from "./HjortTypes";

export const settHjortFields = [
  // utmark
  "SettOkseEllerBukk",
  "SettSpissbukk",
  "SettKalverAlene",
  "SettKyrUtenKalvEllerKolle",
  "SettUkjente",
  // innmark
  "SettBukkInnmark",
  "SettSpissbukkInnmark",
  "SettKalvInnmark",
  "SettKolleInnmark",
  "SettUkjenteInnmark",
];

const _skuttHjortFields = [
  "SkuttBukkToOgHalvtÅrPluss",
  "SkuttBukkEttOgHalvtÅr",
  "SkuttKolleToOgHalvtÅrPluss",
  "SkuttKolleEttOgHalvtÅr",
  "SkuttHannKalv",
  "SkuttHunnKalv",
];

export const skuttHjortFields = _skuttHjortFields
  .map((field) => `skuttInnmark.${field}`)
  .concat(_skuttHjortFields.map((field) => `skuttUtmark.${field}`));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hjortFormValidation = (
  t: TFunction<"translation", undefined>,
  fellingsRapportLevert: Stateful<boolean>,
) => {
  const timerJaktetSchema = z
    .number()
    .max(24, { message: t("form.fields.timerJaktet.validationError") });

  const antallJegereSchema = z
    .number()
    .max(100, { message: t("form.fields.jegereMedVåpen.validationError") });

  return z
    .object({
      Jaktfelt: z.string().min(1, { message: t("pages.registrering.errors.jaktfelt") }),
      Utmark: z.boolean(),
      Innmark: z.boolean(),
      AntallJegere: antallJegereSchema,
      AntallTimerJaktet: timerJaktetSchema,
      AntallJegereInnmark: antallJegereSchema,
      AntallTimerJaktetInnmark: timerJaktetSchema,
      // sett utmark
      SettSpissbukk: z.number(),
      SettOkseEllerBukk: z.number(),
      SettKyrUtenKalvEllerKolle: z.number(),
      SettKalverAlene: z.number(),
      SettUkjente: z.number(),
      // sett innmark
      SettSpissbukkInnmark: z.number(),
      SettBukkInnmark: z.number(),
      SettKolleInnmark: z.number(),
      SettKalvInnmark: z.number(),
      SettUkjenteInnmark: z.number(),
      // skutt innmark
      SkuttBukkToOgHalvtÅrPlussInnmark: z.number(),
      SkuttBukkEttOgHalvtÅrInnmark: z.number(),
      SkuttKolleToOgHalvtÅrPlussInnmark: z.number(),
      SkuttKolleEttOgHalvtÅrInnmark: z.number(),
      SkuttHannKalvInnmark: z.number(),
      SkuttHunnKalvInnmark: z.number(),
      // skutt utmark
      SkuttBukkToOgHalvtÅrPluss: z.number(),
      SkuttBukkEttOgHalvtÅr: z.number(),
      SkuttKolleToOgHalvtÅrPluss: z.number(),
      SkuttKolleEttOgHalvtÅr: z.number(),
      SkuttHannKalv: z.number(),
      SkuttHunnKalv: z.number(),
    })
    .partial()
    .superRefine((data, ctx) => {
      const addIssue = (path: string, message: string) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [path],
          fatal: true,
          message: message,
        });
      };
      if (!data.Innmark && !data.Utmark) {
        addIssue("Utmark", t("pages.registrering.errors.terreng"));
      }

      if (data.Innmark && !data.AntallTimerJaktetInnmark) {
        addIssue("AntallTimerJaktetInnmark", t("form.fields.timerJaktet.validationError"));
      }
      if (data.Innmark && !data.AntallJegereInnmark) {
        addIssue("AntallJegereInnmark", t("form.fields.jegereMedVåpen.validationError"));
      }
      if (data.Utmark && !data.AntallTimerJaktet) {
        addIssue("AntallTimerJaktet", t("form.fields.timerJaktet.validationError"));
      }
      if (data.Utmark && !data.AntallJegere) {
        addIssue("AntallJegere", t("form.fields.jegereMedVåpen.validationError"));
      }
      if (data.Innmark && data.Utmark && data.AntallTimerJaktet && data.AntallTimerJaktetInnmark) {
        if (data.AntallTimerJaktet + data.AntallTimerJaktetInnmark > 24) {
          addIssue("AntallTimerJaktet", t("pages.registrering.errors.antalltimersum"));
          addIssue("AntallTimerJaktetInnmark", t("pages.registrering.errors.antalltimersum"));
        }
      }
      const sum = sumSettOgSkuttHjort(data as JaktdagRegistrationDataHjort);
      if (data.Jaktfelt === undefined) {
        addIssue("Jaktfelt", t("form.fields.jaktfelt.validationError"));
      }
      if (fellingsRapportLevert.value === true) {
        addIssue("Jaktfelt", t("form.fields.jaktfelt.fellingsrapportLevertError"));
      }
      // ---------------- utmark ----------------
      if (sum.utmark.sett.settKoller < sum.utmark.skutt.skuttKoller) {
        ["SettKyrUtenKalvEllerKolle"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttKolleEttOgHalvtÅr", "SkuttKolleToOgHalvtÅrPluss"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
      if (sum.utmark.sett.settKalver < sum.utmark.skutt.skuttKalver) {
        ["SettKalverAlene"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttHannKalv", "SkuttHunnKalv"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
      if (
        sum.utmark.sett.settSpissbukker + sum.utmark.sett.settBukker <
        sum.utmark.skutt.skuttSpissbukker + sum.utmark.skutt.skuttBukker
      ) {
        ["SettSpissbukk", "SettOkseEllerBukk"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttBukkEttOgHalvtÅr", "SkuttBukkToOgHalvtÅrPluss"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
      // ---------------- innmark ----------------
      if (sum.innmark.sett.settKoller < sum.innmark.skutt.skuttKoller) {
        ["SettKolleInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttKolleEttOgHalvtÅrInnmark", "SkuttKolleToOgHalvtÅrPlussInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
      if (sum.innmark.sett.settKalver < sum.innmark.skutt.skuttKalver) {
        ["SettKalvInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttHannKalvInnmark", "SkuttHunnKalvInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
      if (
        sum.innmark.sett.settBukker + sum.innmark.sett.settSpissbukker <
        sum.innmark.skutt.skuttBukker + sum.innmark.skutt.skuttSpissbukker
      ) {
        ["SettBukkInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttBukkToOgHalvtÅrPlussInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
        ["SettSpissbukkInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.settAntall.validationError"));
        });
        ["SkuttBukkEttOgHalvtÅrInnmark"].forEach((path) => {
          addIssue(path, t("form.fields.skuttAntall.validationError"));
        });
      }
    });
};
