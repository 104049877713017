import { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from "axios";
import { t } from "i18next";
import { appConfig } from "../appConfig";
import { AuthData, AuthStorageKeys, Credentials } from "../contexts/AuthContext/AuthProvider";
import { retrieve } from "../contexts/StorageContext/helpers";
import { OmradeType } from "../helpers/types";
import {
  AutentiseringJegerInnloggingApi,
  Configuration,
  IndividerSkutteDyrApi,
  JaktdagerSetteDyrApi,
  JaktomraderJegerApi,
  OmrdeListerApi,
  OpplysningerJegerApi,
} from "./generated";
import {
  ApiError,
  CreateIndividRequest,
  CreateJaktdagRequest,
  Favorittområde,
  IndividResponse,
  JaktdagResponse,
  JaktfeltResponse,
  JaktomradeResponse,
  JegerOpplysningerResponse,
  OmrådeData,
  Områdekode,
  RefreshTokenResponse,
  TokenResponse,
  UpdateIndividRequest,
  UpdateJegerOpplysningerRequest,
  ValdResponse,
  VillreinvaldResponse,
} from "./types";

export type ClientState = "Online" | "Offline" | "NotAuthenticated";

export const NETWORK_ERROR_CODES = [
  "ERR_NETWORK",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_INTERNET_DISCONNECTED",
];
export const NOT_AUTHENTICATED_STATUS_CODES = [401, 403];
export const NOT_CACHED_STATUS_CODE = 418;
export const NO_CONNECTION_STATUS_CODE = -1;

export type JaktdataApiInterface = {
  config: Configuration;
  jegerAuthApi: AutentiseringJegerInnloggingApi;
  jegeropplysningerApi: OpplysningerJegerApi;
  jaktområdeApi: JaktomraderJegerApi;
  områdelisterApi: OmrdeListerApi;
  jaktdagerApi: JaktdagerSetteDyrApi;
  skutteDyrApi: IndividerSkutteDyrApi;
  login: (credentials: Credentials) => Promise<TokenResponse>;
  accessTokenHeader: () => Promise<AxiosRequestConfig>;
  getJegeropplysninger: () => Promise<JegerOpplysningerResponse>;
  putJegeropplysninger: (
    jegeropplysninger: UpdateJegerOpplysningerRequest,
  ) => Promise<JegerOpplysningerResponse>;
  mapError: (error: AxiosError) => ApiError;
};

export default class JaktdataApi implements JaktdataApiInterface {
  config: Configuration;
  jegerAuthApi: AutentiseringJegerInnloggingApi;
  jegeropplysningerApi: OpplysningerJegerApi;
  jaktområdeApi: JaktomraderJegerApi;
  områdelisterApi: OmrdeListerApi;
  jaktdagerApi: JaktdagerSetteDyrApi;
  skutteDyrApi: IndividerSkutteDyrApi;

  constructor(baseUrl: string = appConfig.jaktdataApiBaseUrl) {
    this.config = new Configuration({
      basePath: baseUrl,
      baseOptions: {
        timeout: (appConfig.networkTimeoutLimit.seconds || 60) * 1000,
      },
    });
    this.jegerAuthApi = new AutentiseringJegerInnloggingApi(this.config);
    this.jegeropplysningerApi = new OpplysningerJegerApi(this.config);
    this.jaktområdeApi = new JaktomraderJegerApi(this.config);
    this.områdelisterApi = new OmrdeListerApi(this.config);
    this.jaktdagerApi = new JaktdagerSetteDyrApi(this.config);
    this.skutteDyrApi = new IndividerSkutteDyrApi(this.config);
  }

  async accessTokenHeader(): Promise<AxiosRequestConfig> {
    const accessToken = retrieve<AuthData>(AuthStorageKeys.authData)?.token || "";
    return { headers: { Authorization: "Bearer " + accessToken } };
  }

  mapError(error: AxiosError): ApiError {
    let errorString = error.message;
    let response = error.response;

    if (error.response?.status === NOT_CACHED_STATUS_CODE) {
      errorString = t("errors.connectionError");
    } else if (error.code === "ERR_BAD_REQUEST" && !!response?.data) {
      errorString = Object.values((response.data as { errors: object[] }).errors || []).join(`\n`);
    } else if (!!error.code && NETWORK_ERROR_CODES.includes(error.code)) {
      errorString = t("errors.connectionError");
    } else if (!error.response?.status || error.response.status > 500) {
      errorString = t("errors.serverError", { details: t("errors.tryAgainLater") });
    }

    return {
      statusCode: error.status || error.response?.status || NO_CONNECTION_STATUS_CODE,
      message: errorString,
      axiosError: error,
    };
  }

  mapOmrader(omrader: (JaktfeltResponse | ValdResponse | VillreinvaldResponse | Favorittområde)[]) {
    return omrader.map((omrade) => {
      return { isPending: false, ...omrade };
    });
  }

  async login(credentials: Credentials): Promise<TokenResponse> {
    return await this.jegerAuthApi
      .apiV1JegerauthLoginPost({
        Jegernummer: parseInt(credentials.Jegernummer),
        Fodselsdato: String(credentials.Fodselsdato.toString()),
      })
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async refreshToken(): Promise<RefreshTokenResponse> {
    const authData = retrieve<AuthData>(AuthStorageKeys.authData);
    return await this.jegerAuthApi
      .apiV1JegerauthRenewPost({
        Jegernummer: parseInt(authData?.name || ""),
        Token: authData?.refreshToken,
      })
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async checkClientState(): Promise<ClientState> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jegeropplysningerApi
      .apiV1JegeropplysningerGet(tokenHeader)
      .then((response: AxiosResponse) => {
        const headers = response.headers;
        if (headers instanceof AxiosHeaders && headers.get("x-cached-by") === "service-worker") {
          return "Offline" as ClientState;
        }
        return "Online" as ClientState;
      })
      .catch((error: AxiosError) => {
        const apiError = this.mapError(error);
        const statusCode = apiError.statusCode;
        if (NOT_AUTHENTICATED_STATUS_CODES.includes(statusCode)) {
          return "NotAuthenticated" as ClientState;
        }
        return "Offline" as ClientState;
      });
  }

  async getJegeropplysninger(): Promise<JegerOpplysningerResponse> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jegeropplysningerApi
      .apiV1JegeropplysningerGet(tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async putJegeropplysninger(
    jegeropplysninger: UpdateJegerOpplysningerRequest,
  ): Promise<JegerOpplysningerResponse> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jegeropplysningerApi
      .apiV1JegeropplysningerPut(jegeropplysninger, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getJaktfelt(): Promise<OmrådeData> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.områdelisterApi
      .apiV1OmrderJaktfeltGet(tokenHeader)
      .then((response) => {
        return {
          type: OmradeType.Jaktfelt,
          data: response.data,
        };
      })
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getVald(): Promise<OmrådeData> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.områdelisterApi
      .apiV1OmrderValdGet(tokenHeader)
      .then((response) => {
        return { type: OmradeType.Vald, data: response.data };
      })
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getVillreinVald(): Promise<OmrådeData> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.områdelisterApi
      .apiV1OmrderVillreinvaldGet(tokenHeader)
      .then((response) => {
        return { type: OmradeType.VillreinVald, data: response.data };
      })
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getJaktområder(): Promise<Favorittområde[]> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktområdeApi
      .apiV1JaktomrderGet(tokenHeader)
      .then((response) => this.mapOmrader(response.data))
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async postJaktområde(områdekode: Områdekode): Promise<JaktomradeResponse> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktområdeApi
      .apiV1JaktomrderPost(områdekode, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async deleteJaktområde(områdekode: Områdekode): Promise<object> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktområdeApi
      .apiV1JaktomrderDelete(områdekode, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getJaktdager(sesong: number, jaktfeltId: string): Promise<JaktdagResponse[]> {
    if (!jaktfeltId) {
      throw new Error("jaktfeltId is required");
    }
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerJaktfeltIdSesongGet(sesong, jaktfeltId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getJaktdag(jaktdagId: string): Promise<JaktdagResponse> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerIdGet(jaktdagId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async deleteJaktdag(jaktdagId: string): Promise<void> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerMedindividIdDelete(jaktdagId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async postJaktdag(jaktdag: CreateJaktdagRequest): Promise<string> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerMedindividPost(jaktdag, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async postJaktdagWithoutIndivid(jaktdag: CreateJaktdagRequest): Promise<string> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerPost(jaktdag, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async putJaktdag(jaktdag: CreateJaktdagRequest): Promise<void> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerMedindividPut(jaktdag, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async putJaktdagWithoutIndivid(jaktdag: CreateJaktdagRequest): Promise<void> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.jaktdagerApi
      .apiV1JaktdagerPut(jaktdag, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getSkutteDyr(sesong: number, omradeId: string): Promise<IndividResponse[]> {
    if (!omradeId) {
      throw new Error("omradeId is required");
    }

    const tokenHeader = await this.accessTokenHeader();
    return await this.skutteDyrApi
      .apiV1IndividerOmrdeIdSesongGet(sesong, omradeId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async getIndivid(individId: string): Promise<IndividResponse> {
    if (!individId) {
      throw new Error("individId is required");
    }

    const tokenHeader = await this.accessTokenHeader();
    return await this.skutteDyrApi
      .apiV1IndividerIdGet(individId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async putIndivid(individ: UpdateIndividRequest): Promise<void> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.skutteDyrApi
      .apiV1IndividerPut(individ, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async deleteIndivid(individId: string): Promise<void> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.skutteDyrApi
      .apiV1IndividerIdDelete(individId, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }

  async postIndivid(individ: CreateIndividRequest): Promise<string> {
    const tokenHeader = await this.accessTokenHeader();
    return await this.skutteDyrApi
      .apiV1IndividerPost(individ, tokenHeader)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw this.mapError(error);
      });
  }
}
