import { NOT_AUTHENTICATED_STATUS_CODES } from "~/src/api/jaktdataApi";
import { ApiError } from "~/src/api/types";

export const isDeletedOnServer = (e: ApiError) => {
  return (
    [404, 400].includes(e.statusCode) &&
    (e.message.includes("ikke funnet") || e.message.includes("Id må finnes i databasen"))
  );
};
export const syncStatusForError = (e: ApiError) => {
  return NOT_AUTHENTICATED_STATUS_CODES.includes(e.statusCode) ? "pending" : "error";
};
