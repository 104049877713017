import React, { useContext, useEffect, useRef } from "react";
import "ol/ol.css";
import { Feature, MapBrowserEvent } from "ol";
import OlMap from "ol/Map";
import View from "ol/View";
import { defaults } from "ol/control/defaults";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import { Geometry, Point } from "ol/geom";
import { Draw, Interaction, Modify } from "ol/interaction";
import { DrawEvent } from "ol/interaction/Draw";
import { ModifyEvent } from "ol/interaction/Modify";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Pixel } from "ol/pixel";
import { transform, useGeographic } from "ol/proj";
import { register } from "ol/proj/proj4";
import { Vector } from "ol/source";
import OSM from "ol/source/OSM";
import WMTS, { Options, optionsFromCapabilities } from "ol/source/WMTS";
import { Icon, Style } from "ol/style";
import proj4 from "proj4";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import pinIcon from "~/src/assets/location_on.svg";
import ButtonDefault, { ButtonVariant } from "~/src/components/Button/ButtonDefault";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { useStateful } from "~/src/hooks/useStateful";
import proj4Defs from "./proj4-defs.json";

export type Coordinates = [number, number];

function Kart() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const ui = useContext(UIContext);
  const coordinatesState = useStateful<Coordinates | undefined>(undefined);
  const nord: number = Number(location.state?.nord?.toString().replace(",", "."));
  const ost: number = Number(location.state?.ost?.toString().replace(",", "."));
  const koordinatfesting: string = location.state?.koordinatfesting;
  const tilbakeTilForrigeSide: string = location.state?.referrer;
  const zoomValgtPosisjon: number = 14;
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<OlMap | null>(null);
  const drawRef = useRef<Draw | null>(null);
  const modifyRef = useRef<Modify | null>(null);
  const vectorRef = useRef<VectorLayer<Feature<Geometry>> | null>(null);

  proj4.defs(proj4Defs.defs);
  register(proj4);
  useGeographic();

  const overrideZoomButtonCss = `
    .ol-zoom {
      width: fit-content;
      position: relative;
      top: 10%;
      left: 10px;
      background-color: transparent;
    }
    .ol-zoom button.ol-zoom-out {
      align-items: baseline;
      
      @media (hover: none) { /* Touch devices */
        align-items: center;
      }
    }
    .ol-zoom button {
      background-color: #005E5D;
      border: none;
      color: white;
      font-weight: 100;
      font-size: 3rem;
      min-width: 3rem;
      height: 3rem;
      line-height: 2.5rem;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 2px;
    }
    .ol-zoom button:hover,
    .ol-zoom button:focus {
      color: white;
    }
    .ol-zoom .ol-zoom-in, .ol-zoom .ol-zoom-out {
      border-radius: 50%;
    }
  `;

  useEffect(() => {
    if (location.state?.nord && location.state?.ost && location.state?.koordinatfesting) {
      if (koordinatfesting.startsWith("UTM")) {
        const coords = transform(
          [+ost, +nord],
          epsgKode(koordinatfesting),
          epsgKode("LengdeOgBreddegrad"), //Fordi kartet er registrert til å kunne vise koordinater i lengde og breddegrad pga useGeographic()
        );
        coordinatesState.set([coords[0], coords[1]]);
        mapRef.current
          ?.getView()
          .animate({ zoom: zoomValgtPosisjon, center: [coords[0], coords[1]] });
      } else {
        coordinatesState.set([ost, nord]);
        mapRef.current?.getView().animate({ zoom: zoomValgtPosisjon, center: [ost, nord] });
      }
    }
    function epsgKode(system: string): string {
      switch (system) {
        case "UTM32":
          return "EPSG:32632";
        case "UTM33":
          return "EPSG:32633";
        case "UTM34":
          return "EPSG:32634";
        case "UTM35":
          return "EPSG:32635";
        case "UTM36":
          return "EPSG:32636";
        case "LengdeOgBreddegrad":
        default:
          return "EPSG:4326";
      }
    }
  }, []);

  useEffect(() => {
    //Kalles kun en gang
    if (ref.current && !mapRef.current) {
      const source = new Vector();
      vectorRef.current = new VectorLayer({
        source: source,
        zIndex: 100,
        style: new Style({
          image: new Icon({
            opacity: 1,
            crossOrigin: "*",
            src: pinIcon,
            width: 48,
            height: 48,
            anchor: [0.5, 1],
            anchorYUnits: "fraction",
            anchorXUnits: "fraction",
          }),
        }),
      });

      const parser = new WMTSCapabilities();
      const norgesKart = new TileLayer();

      fetch(
        "https://cache.kartverket.no/capabilities/topo/WMTSCapabilities.xml?request=GetCapabilities",
      )
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          const result = parser.read(text);
          const options = optionsFromCapabilities(result, {
            layer: "topo",
            matrixSet: "EPSG:3857",
          });
          norgesKart.setOpacity(1);
          norgesKart.setSource(new WMTS(options as Options));
        });

      mapRef.current = new OlMap({
        target: ref.current,
        layers: [
          new TileLayer({
            preload: Infinity,
            source: new OSM(),
          }),
          norgesKart,
          vectorRef.current,
        ],
        view: new View({
          center: [18, 63],
          zoom: 4,
          maxZoom: 17,
          enableRotation: false,
        }),
        controls: defaults({
          rotate: false,
          attribution: false,
          zoom: true,
        }),
      });

      drawRef.current = new Draw({
        source: source,
        type: "Point",
        minPoints: 0,
        maxPoints: 1,
      });

      modifyRef.current = new Modify({
        source: source,
        hitDetection: vectorRef.current,
      });

      drawRef.current?.on("drawend", (e: DrawEvent) => {
        mapRef.current?.removeInteraction(drawRef.current as Interaction);
        mapRef.current?.addInteraction(modifyRef.current as Interaction);

        const point = e.feature.getGeometry() as Point;
        let [x, y] = point.getCoordinates();
        coordinatesState.set([x, y]);
      });

      modifyRef.current.on("modifyend", (e: ModifyEvent) => {
        const point = e.features.item(0).getGeometry() as Point;
        let [x, y] = point.getCoordinates();
        coordinatesState.set([x, y]);
      });

      mapRef.current?.on("pointermove", function (e: MapBrowserEvent<PointerEvent>) {
        var pixel = mapRef.current?.getEventPixel(e.originalEvent);
        var hit = mapRef.current?.hasFeatureAtPixel(pixel as Pixel);
        mapRef.current?.getViewport().style.cursor === hit ? "move" : "";
      });

      settRiktigInteraksjon();
    }
  }, [ref, mapRef]);

  function oppdaterPosisjonIKart(coordinates: Coordinates) {
    const feature = vectorRef.current?.getSource()?.getFeatures()[0];

    if (feature) {
      (feature.getGeometry() as Point).setCoordinates(coordinates);
      feature.changed();
    } else {
      const point = new Feature(new Point(coordinates));
      vectorRef.current?.getSource()?.addFeature(point);
    }

    var currentZoom = mapRef.current?.getView().getZoom();
    if (currentZoom !== undefined && currentZoom < zoomValgtPosisjon) {
      mapRef.current?.getView().animate({ zoom: zoomValgtPosisjon, center: coordinates });
    } else {
      mapRef.current?.getView().animate({ center: coordinates });
    }

    settRiktigInteraksjon();
  }

  function settRiktigInteraksjon() {
    //Nullstiller alt først
    mapRef.current?.removeInteraction(drawRef.current as Interaction);
    mapRef.current?.removeInteraction(modifyRef.current as Interaction);

    if (coordinatesState.value) {
      mapRef.current?.addInteraction(modifyRef.current as Interaction);
    } else {
      mapRef.current?.addInteraction(drawRef.current as Interaction);
    }
  }

  useEffect(() => {
    if (coordinatesState.value) {
      oppdaterPosisjonIKart(coordinatesState.value);
    }
  }, [coordinatesState.value]);

  return (
    <>
      <div ref={ref} style={{ width: "100%", height: "100%" }}></div>
      <div
        className={
          "fixed bottom-0 left-0 right-0 bg-white bg-opacity-60 z-50 h-20 w-full flex justify-center items-center space-x-10"
        }
      >
        <ButtonDefault
          id="minPosisjonKartButton"
          label={t("pages.kart.minPosisjon")}
          variant={ButtonVariant.SecondaryWhite}
          disabled={navigator.geolocation === undefined}
          aria-disabled={navigator.geolocation === undefined}
          onClick={async () => {
            function success(position: GeolocationPosition) {
              coordinatesState.set([position.coords.longitude, position.coords.latitude]);
              mapRef.current?.getView().animate({
                zoom: zoomValgtPosisjon,
                center: [position.coords.longitude, position.coords.latitude],
              });
            }

            function error(error: GeolocationPositionError) {
              if (error.code == error.PERMISSION_DENIED) {
                ui.showSnackbar({ message: t("pages.kart.geolocationPositionDeniedError") });
              } else {
                ui.showSnackbar({ message: t("pages.kart.geolocationPositionUnavailableError") });
              }
            }

            const options = {
              enableHighAccuracy: true,
              timeout: 3000,
            };

            navigator.geolocation.getCurrentPosition(success, error, options);
          }}
        ></ButtonDefault>
        <ButtonDefault
          id="bekreftValgKartButton"
          label={t("pages.kart.bekreftValg")}
          variant={ButtonVariant.Primary}
          disabled={!coordinatesState.value}
          aria-disabled={!coordinatesState.value}
          onClick={async () => {
            if (coordinatesState.value) {
              navigate(tilbakeTilForrigeSide, {
                state: {
                  nord: coordinatesState.value[1].toFixed(6).toString(),
                  ost: coordinatesState.value[0].toFixed(6).toString(),
                  koordinatfesting: "LengdeOgBreddegrad",
                },
              });
            }
          }}
        ></ButtonDefault>
      </div>
      <style>{overrideZoomButtonCss}</style>
    </>
  );
}

export default Kart;
