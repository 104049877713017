import {
  Checkbox as MUICheckBox,
  CheckboxProps as MUICheckboxProps,
} from "@material-tailwind/react";

export type CheckboxProps = Omit<MUICheckboxProps, "ref"> & {
  className?: string;
  labelClassName?: string;
};

export default function Checkbox(props: CheckboxProps): JSX.Element {
  return (
    <MUICheckBox
      {...props}
      crossOrigin={""}
      labelProps={{ className: "font-normal " + props.labelClassName }}
    />
  );
}
