import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Text from "~/src/components/Text/Text";

type HelpButtonProps = {
  title: string;
  onClick: () => void;
  id?: string;
};

const HelpButton = ({ title, onClick, id }: HelpButtonProps): JSX.Element => {
  return (
    <button id={id} onClick={onClick} type="button">
      <div
        className="
        flex flex-row
      items-center justify-center
      gap-2 px-2
      rounded-full
      bg-white text-md-primary
      font-normal hover:bg-md-lysegronn"
      >
        <QuestionMarkIcon className="w-4 h-4 border rounded-full border-md-primary " />
        <Text variant="small" className="mt-1">
          {title}
        </Text>
      </div>
    </button>
  );
};

export default HelpButton;
