import { Close } from "@mui/icons-material";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { TFunction } from "i18next";
import Text from "../Text/Text";

export type SnackbarDefaultProps = {
  message: string;
  onClose: () => void;
  undoFn?: () => void;
  autoHideDuration?: number;
  t: TFunction<"translation", undefined>;
} & React.ComponentProps<"div">;

export default function SnackbarDefault({
  message,
  onClose,
  undoFn,
  autoHideDuration = 5000,
  t,
}: SnackbarDefaultProps): JSX.Element {
  const defaultCloseAction = (
    <IconButton size="small" color="inherit" onClick={() => onClose()}>
      <Close fontSize="small" />
    </IconButton>
  );

  const undoAction = undoFn && (
    <div className="flex flex-row justify-between items-center gap-4">
      <Text
        variant="small"
        className="text-md-sjogronn-lys font-bold pr-0 mr-0 hover:cursor-pointer"
        onClick={() => {
          undoFn();
          onClose();
        }}
      >
        {t("actions.undo")}
      </Text>
      {defaultCloseAction}
    </div>
  );

  return (
    <Snackbar
      open={true}
      autoHideDuration={autoHideDuration}
      onClose={(e) => {
        if (e && e.type === "click") {
          e.preventDefault(); // ignore "click-away"
          return;
        }
        onClose();
      }}
      message={message}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      disableWindowBlurListener={true}
    >
      <SnackbarContent
        className="opacity-90"
        message={message}
        action={undoAction || defaultCloseAction}
      />
    </Snackbar>
  );
}
