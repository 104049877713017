/**
 * Checks if the current host is localhost, 127.0.0.1, or within private subnets.
 * @returns {boolean} True if the host is considered local/private, false otherwise.
 */
export function isLocalOrPrivateHost(): boolean {
  const hostname = window.location.hostname;
  const localhostRegex = /^(localhost|127\.0\.0\.1)$/;
  const privateSubnetsRegex =
    /^(10\.\d{1,3}\.\d{1,3}\.\d{1,3}|192\.168\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3})$/;

  return localhostRegex.test(hostname) || privateSubnetsRegex.test(hostname);
}
