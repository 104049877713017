import { Context, useContext } from "react";

export type FormDataContext<T> = {
  formData: T;
  setFormData: (data: T) => void;
  formErrors: Partial<Record<keyof T, string>>;
  setFormErrors: (errors: Partial<Record<keyof T, string>>) => void;
};

export function useFormDataContext<T>(formDataContext: Context<FormDataContext<T> | undefined>) {
  const context = useContext(formDataContext);
  const retryKey = "useFormDataContext-retried";
  const contextRetried = localStorage.getItem(retryKey);
  if (!context) {
    if (!contextRetried || contextRetried === "false") {
      localStorage.setItem(retryKey, "true");
      window.location.reload();
    }
    throw Error(`useFormDataContext must be used within ${formDataContext}`);
  }
  localStorage.setItem(retryKey, "false");
  return context;
}
