import { useTranslation } from "react-i18next";
import elgEldre from "../../assets/img/aldersbestemmelse/elg_eldre.webp";
import elgKalv from "../../assets/img/aldersbestemmelse/elg_kalv.png";
import elgSeint from "../../assets/img/aldersbestemmelse/elg_seint.png";
import elgTidlig from "../../assets/img/aldersbestemmelse/elg_tidlig_utviklet.webp";
import hjortEldre from "../../assets/img/aldersbestemmelse/hjort_eldre.webp";
import hjortEtOgEtHalvt from "../../assets/img/aldersbestemmelse/hjort_etogethalvt.webp";
import hjortHalvt from "../../assets/img/aldersbestemmelse/hjort_halvt.webp";
import hjortToOgEtHalvt from "../../assets/img/aldersbestemmelse/hjort_toogethalvt.webp";
import hjortTreOgEtHalvt from "../../assets/img/aldersbestemmelse/hjort_treogethalvt.webp";
import { AccordionDefault } from "../../components/Accordion/AccordionDefault";

import { useLocation } from "react-router-dom";
import { ArtType } from "../../helpers/types";

function Aldersbestemmelse(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const valgtArt: ArtType = location.state?.art;

  return (
    <div
      className={
        "relative bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-none flex flex-col h-full p-4"
      }
    >
      {(valgtArt === ArtType.Elg || valgtArt === undefined) && (
        <>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.elg.kalv.title")}>
            <img
              alt={t("pages.aldersbestemmelse.elg.kalv.alttext")}
              src={elgKalv}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.elg.kalv.text")}
            </div>
          </AccordionDefault>

          <AccordionDefault headerContent={t("pages.aldersbestemmelse.elg.seint.title")}>
            <img
              alt={t("pages.aldersbestemmelse.elg.seint.alttext")}
              src={elgSeint}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.elg.seint.text")}
            </div>
          </AccordionDefault>

          <AccordionDefault headerContent={t("pages.aldersbestemmelse.elg.tidlig.title")}>
            <img
              alt={t("pages.aldersbestemmelse.elg.tidlig.alttext")}
              src={elgTidlig}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.elg.tidlig.text")}
            </div>
          </AccordionDefault>

          <AccordionDefault headerContent={t("pages.aldersbestemmelse.elg.eldre.title")}>
            <img
              alt={t("pages.aldersbestemmelse.elg.eldre.alttext")}
              src={elgEldre}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.elg.eldre.text")}
            </div>
          </AccordionDefault>
        </>
      )}
      {(valgtArt === ArtType.Hjort || valgtArt === undefined) && (
        <>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.hjort.halvt.title")}>
            <img
              alt={t("pages.aldersbestemmelse.hjort.halvt.alttext")}
              src={hjortHalvt}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.hjort.halvt.text")}
            </div>
          </AccordionDefault>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.hjort.etOgEtHalvt.title")}>
            <img
              alt={t("pages.aldersbestemmelse.hjort.etOgEtHalvt.alttext")}
              src={hjortEtOgEtHalvt}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.hjort.etOgEtHalvt.text")}
            </div>
          </AccordionDefault>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.hjort.toOgEtHalvt.title")}>
            <img
              alt={t("pages.aldersbestemmelse.hjort.toOgEtHalvt.alttext")}
              src={hjortToOgEtHalvt}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.hjort.toOgEtHalvt.text")}
            </div>
          </AccordionDefault>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.hjort.treOgEtHalvt.title")}>
            <img
              alt={t("pages.aldersbestemmelse.hjort.treOgEtHalvt.alttext")}
              src={hjortTreOgEtHalvt}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.hjort.treOgEtHalvt.text")}
            </div>
          </AccordionDefault>
          <AccordionDefault headerContent={t("pages.aldersbestemmelse.hjort.eldre.title")}>
            <img
              alt={t("pages.aldersbestemmelse.hjort.eldre.alttext")}
              src={hjortEldre}
              className="max-w-lg"
            />
            <div className="m-8 whitespace-pre-line">
              {t("pages.aldersbestemmelse.hjort.eldre.text")}
            </div>
          </AccordionDefault>
        </>
      )}
    </div>
  );
}

export default Aldersbestemmelse;
