import { createContext, useContext, useEffect } from "react";
import superjson from "superjson";
import { Individ, Jaktdag } from "~/src/api/types";
import AuthContext from "~/src/contexts/AuthContext/AuthProvider";
import { retrieve } from "~/src/contexts/StorageContext/helpers";
import getAppInfo from "~/src/helpers/getAppInfo";
import { useStateful } from "~/src/hooks/useStateful";

export type AppStorageObjectType =
  | "PendingJaktomrade"
  | "Individ"
  | "Jaktdag"
  | "FormData"
  | "Other";

type AppStorageKey = {
  storageIdentifier: string;
  user: string;
  objectType: AppStorageObjectType;
  objectKey: object;
};

export type StorageItem<T> = {
  objectKey: object;
  objectType?: AppStorageObjectType;
  isPending?: boolean;
  lastUpdated?: Date;
  operation?: "create" | "update" | "delete" | "none";
  syncStatus?: "pending" | "error";
  errors?: Partial<Record<keyof T, string>>;
  data: T;
};

const StorageItemDefaults = {
  isPending: false,
  lastUpdated: new Date(),
  operation: "none",
  objectType: "Other",
  syncStatus: "pending",
  errors: undefined,
};

export class AppStorage {
  private currentUser: string;
  private storage: Storage = localStorage;
  private storageIdentifier: string;

  constructor(currentUser: string, storageIdentifier: string, storage: Storage = localStorage) {
    this.currentUser = currentUser;
    this.storageIdentifier = storageIdentifier;
    this.storage = storage;

    this.clearAllObjectsBelongingToDifferentLogin();
  }

  private clearAllObjectsBelongingToDifferentLogin() {
    for (const storageKeyStr in this.storage) {
      if (!storageKeyStr.includes("storageIdentifier")) {
        continue;
      }
      const storageKey = superjson.parse<AppStorageKey>(storageKeyStr);
      if (storageKey.storageIdentifier !== this.storageIdentifier) {
        continue;
      }
      if (storageKey.user !== this.currentUser) {
        console.log("storage - removing stored objects belonging to different login");
        this.storage.removeItem(storageKeyStr);
      }
    }
  }

  public storageKeyFor(
    objectKey: object,
    objectType: AppStorageObjectType = "Other",
  ): AppStorageKey {
    if (!objectKey) {
      throw new Error("objectKey must be defined and can not be empty");
    }
    if ("storageIdentifier" in objectKey) {
      throw new Error("objectKey must not be of type AppStorageKey");
    }
    return {
      storageIdentifier: this.storageIdentifier,
      user: this.currentUser,
      objectType: objectType,
      objectKey: objectKey,
    };
  }

  getAll<T>(objectType?: AppStorageObjectType): StorageItem<T>[] {
    const objectTypeFilter =
      (objectType && new RegExp(`"objectType"\\s*:\\s*"${objectType}"`)) ||
      new RegExp(`"objectType"`);

    return Object.keys(this.storage)
      .filter((key) => objectTypeFilter!.test(`${key}`))
      .map((key) => this.storage.getItem(`${key}`))
      .filter((value) => value !== null)
      .map((value) => {
        try {
          return superjson.parse(value!) as StorageItem<T>;
        } catch (e) {
          console.error(e);
          return null;
        }
      })
      .filter((value): value is StorageItem<T> => !!value);
  }

  anyPending(): boolean {
    const allObjects = this.getAll<unknown>();
    return allObjects.find((storageItem) => storageItem.isPending) !== undefined;
  }

  get<T>(
    objectKey: object,
    objectType: AppStorageObjectType = "Other",
  ): StorageItem<T> | undefined {
    const storageKey = this.storageKeyFor(objectKey, objectType);
    return retrieve<StorageItem<T>>(superjson.stringify(storageKey));
  }

  add<T>(storageItem: StorageItem<T>) {
    const storageKey = this.storageKeyFor(storageItem.objectKey, storageItem.objectType);
    this.storage.setItem(
      superjson.stringify(storageKey),
      superjson.stringify({ ...StorageItemDefaults, ...storageItem }),
    );
  }

  delete(objectKey: object, objectType: AppStorageObjectType = "Other") {
    const storageKey = this.storageKeyFor(objectKey, objectType);
    this.storage.removeItem(superjson.stringify(storageKey));
  }

  getIndividerForJaktdag(jaktdagId: string): StorageItem<Individ>[] {
    return this.getAll<Individ>("Individ").filter(
      (individ) => individ.data.JaktdagId === jaktdagId,
    );
  }

  setIndividerForJaktdag(jaktdag: Jaktdag) {
    const existingIndivider = jaktdag?.Individer || [];

    const storedIndivider = this.getIndividerForJaktdag(jaktdag.Id || "");
    const createdIndivider = storedIndivider
      .filter((individ) => individ.operation === "create")
      .map((individ) => individ.data);
    const updatedIndivider = storedIndivider
      .filter((individ) => individ.operation === "update")
      .map((individ) => individ.data);
    const deletedIndivider = storedIndivider
      .filter((individ) => individ.operation === "delete")
      .map((individ) => individ.data);

    const filteredIdivider = createdIndivider
      .concat(updatedIndivider)
      .concat(
        existingIndivider.filter(
          (individ) => updatedIndivider.find((i) => i.Id === individ.Id) === undefined,
        ),
      )
      .filter((individ) => deletedIndivider.find((d) => d.Id === individ.Id) === undefined);

    return { ...jaktdag, Individer: filteredIdivider };
  }
}

const StorageContext = createContext<AppStorage>({} as AppStorage);

const StorageProvider = (props: { children: JSX.Element }): JSX.Element => {
  const auth = useContext(AuthContext);
  const appInfo = getAppInfo();
  const appStorage = useStateful<AppStorage>({} as AppStorage);
  useEffect(() => {
    const currentUser = auth.currentUser();
    if (currentUser !== undefined) {
      const _appStorage = new AppStorage(currentUser, appInfo.name);
      appStorage.set(_appStorage);
    }
  }, [auth.currentUser()]);

  return (
    <StorageContext.Provider value={appStorage.value}>{props.children}</StorageContext.Provider>
  );
};

export { StorageContext, StorageProvider as default };
