import Text from "./Text";

const ErrorText = ({ children }: { children: string }): JSX.Element => {
  return (
    <Text variant="small" className="text-md-error-red font-semibold">
      {children}
    </Text>
  );
};

export default ErrorText;
