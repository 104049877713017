import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Individ } from "~/src/api/types";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { formatDmy } from "~/src/helpers/time";
import { useStateful } from "~/src/hooks/useStateful";
import GodkjentInfoBox from "../../../components/Alerts/GodkjentInfoBox";
import InfoAlert from "../../../components/Alerts/InfoAlert";
import AppDataContext from "../../../contexts/AppDataContext/DataProvider";
import { ArtType } from "../../../helpers/types";
import { getIndividQuery, getJaktdagQuery } from "../../../react-query/queries";
import IndividForm from "../components/IndividForm";
import { IndividData } from "../schemas/schema";

export default function FellingsInfo(): JSX.Element {
  const { t } = useTranslation();
  const { individId } = useParams();
  const { jaktfelt, vald, villreinvald, didTrySyncWithItems } = useContext(AppDataContext);
  const storage = useContext(StorageContext);
  const individ = useStateful<Individ | undefined>(undefined);
  const individErrors = useStateful<Partial<Record<keyof Individ, string>> | undefined>(undefined);

  const individResult = useQuery({
    queryKey: ["individ", { individId: individId }],
    queryFn: getIndividQuery,
    enabled: !!individId,
    staleTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (didTrySyncWithItems.value) {
      individResult.refetch();
    }
  }, [didTrySyncWithItems.value]);

  useEffect(() => {
    // use stored individ if it exists
    // (newly created individ only exists in storage, and edited should use stored even if API is available)
    const storedIndivid = storage.get<Individ>({ id: individId }, "Individ");

    if (storedIndivid && storedIndivid.operation !== "delete") {
      individ.set(storedIndivid?.data);
      individErrors.set(storedIndivid?.errors);
    } else if (individResult.isSuccess && typeof individResult.data !== "string") {
      individ.set(individResult.data as Individ);
    }
  }, [individResult.data]);

  const currentOmrade = useMemo(() => {
    if (!individ) return null;

    switch (individ.value?.Art) {
      case ArtType.Hjort:
      case ArtType.Elg:
        return jaktfelt.data?.data.find((felt) => felt.Id === individ.value?.Jaktfelt);
      case ArtType.Villrein:
        return villreinvald.data?.data.find((vald) => vald.Id === individ.value?.Villreinvald);
      case ArtType.Rådyr:
        return vald.data?.data.find((vald) => vald.Id === individ.value?.Vald);
      default:
        return null;
    }
  }, [individ, jaktfelt, villreinvald, vald]);

  const jaktdag = useQuery({
    queryKey: ["jaktdag", { jaktdagId: individ.value?.JaktdagId }],
    queryFn: getJaktdagQuery,
    enabled: !!individ?.value?.JaktdagId,
    staleTime: 0,
  });

  if (individResult.isLoading) {
    return <CircularProgress color="success" />;
  }

  if (!individ.value) {
    return <div className="m-4">{t("errors.notFound", { type: "Individ" })}</div>;
  }

  if (individ?.value?.Godkjent) {
    return <InfoAlert>{t("components.dyretErGodkjent")}</InfoAlert>;
  }

  return (
    <div>
      <div className="flex bg-md-lysegronn py-2 px-4 items-center justify-between">
        <div>{formatDmy(individ?.value?.Dato || individ.value?.Dato)}</div>
        <div>{currentOmrade?.Navn}</div>
        <div className="text-sm text-gray-700">{individ.value?.Jaktfelt}</div>
      </div>
      {jaktdag?.data?.Godkjent && (
        <div className="pt-2 px-2">
          <GodkjentInfoBox>
            {individ.value?.Art === ArtType.Hjort
              ? t("components.jaktdagenErGodkjentHjort")
              : t("components.jaktdagenErGodkjent")}
          </GodkjentInfoBox>
        </div>
      )}
      <IndividForm
        key={`${individId}-${JSON.stringify(individ)}`}
        initialInfo={individ.value as IndividData}
        mode="update"
        jaktdagGodkjent={jaktdag.data?.Godkjent}
        errors={individErrors.value}
      />
    </div>
  );
}
