import { AlderType, KjønnType } from "../../helpers/types";

export enum MalemetodeSlaktevekt {
  Veid = "Veid",
  IkkeVeid = "IkkeVeid",
  Anslatt = "Anslått",
}
export enum Terreng {
  Innmark = "Innmark",
  Utmark = "Utmark",
}
export type Gevirtakker = { hoyre: number; venstre: number };

export enum KjonnAlderType {
  HannVoksen = "Hann-Voksen",
  HunnVoksen = "Hunn-Voksen",
  HannUngdyr = "Hann-Ungdyr",
  HunnUngdyr = "Hunn-Ungdyr",
  HannKalv = "Hann-Kalv",
  HunnKalv = "Hunn-Kalv",
}

export const castToAlderKjonn = (
  alder: AlderType,
  kjonn: KjønnType,
): KjonnAlderType | undefined => {
  if (!alder || !kjonn) return undefined;
  const key = `${kjonn}${alder}` as keyof typeof KjonnAlderType;
  return KjonnAlderType[key] ?? undefined;
};

export const kjonnAlderToAlderAndKjonn = (kjonnAlder: KjonnAlderType): [KjønnType, AlderType] => {
  const [kjonnString, alderString] = kjonnAlder.split("-");
  const kjonn = Object.values(KjønnType).find((kjonn) => kjonn == kjonnString);
  const alder = Object.values(AlderType).find((alder) => alder == alderString);

  return [kjonn!, alder!] as const;
};

// Function to cast a string to MalemetodeSlaktevekt
export const castToMalemetodeSlaktevekt = (input: string): MalemetodeSlaktevekt | undefined => {
  const validValues: MalemetodeSlaktevekt[] = Object.values(MalemetodeSlaktevekt).filter(
    (met) => met,
  );
  if (validValues.includes(input as MalemetodeSlaktevekt)) {
    return input as MalemetodeSlaktevekt;
  }
  return undefined;
};

export const castToTerreng = (input: string): Terreng | undefined => {
  const validValues: Terreng[] = Object.values(Terreng);
  if (validValues.includes(input as Terreng)) {
    return input as Terreng;
  }
  return undefined;
};
