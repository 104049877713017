import { useTranslation } from "react-i18next";
import { DialogHook, DialogShowProps, UseDialogProps, useDialog } from "~/src/hooks/useDialog";
import ButtonDefault, { ButtonLayout, ButtonVariant } from "../components/Button/ButtonDefault";
import { DialogAlignment } from "../components/Dialog/DialogDefault";

export type ConfirmationButtonsProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export type UseConfirmationDialogProps = ConfirmationButtonsProps & UseDialogProps;

export type ConfirmationDialogHook = Omit<DialogHook, "show"> & {
  show: (props?: DialogShowProps & ConfirmationButtonsProps) => void;
};

export function useConfirmationDialog({
  title = undefined,
  message = undefined,
  onConfirm = undefined,
  onCancel = undefined,
  confirmButtonText = undefined,
  cancelButtonText = "Avbryt",
  buttonLayout = ButtonLayout.Horizontal,
  alignment = DialogAlignment.Start,
}: UseConfirmationDialogProps): ConfirmationDialogHook {
  const { t } = useTranslation();
  const dialog = useDialog({ title, message, buttonLayout, alignment });
  const confirmationButtons = (props?: ConfirmationButtonsProps) => {
    return (
      <>
        <ButtonDefault
          id="CancelButton"
          variant={ButtonVariant.Secondary}
          onClick={() => {
            dialog.close();
            (props?.onCancel && props.onCancel()) || (onCancel && onCancel());
          }}
          label={props?.cancelButtonText || cancelButtonText || t("actions.cancel")}
          className="ml-2 mr-2"
        />
        <ButtonDefault
          id="ConfirmButton"
          variant={ButtonVariant.Primary}
          onClick={() => {
            dialog.close();
            (props?.onConfirm && props.onConfirm()) || (onConfirm && onConfirm());
          }}
          label={props?.confirmButtonText || confirmButtonText || t("actions.confirm")}
          className="mr-2"
        />
      </>
    );
  };

  return {
    ...dialog,
    show: (props?: DialogShowProps & ConfirmationButtonsProps) => {
      dialog.show({ ...props, ...{ buttons: props?.buttons || confirmationButtons(props) } });
    },
  };
}
