const HeaderRight = ({ label, children }: { label?: string; children?: React.ReactNode }) => {
  return (
    <div className="flex items-center justify-end w-full mr-6 text-md-gray font-normal text-opacity-70 text-base">
      <div>{label}</div>
      <div className="w-11 text-center font-medium">{children}</div>
    </div>
  );
};

export default HeaderRight;
