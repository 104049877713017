import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonDefault, { ButtonVariant } from "../../../../components/Button/ButtonDefault";

export type SuccessDialogButtonsProps = {
  valgtOmradekode?: string | number;
};

const SuccessDialogButtons = (props: SuccessDialogButtonsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToOversiktJakdager = () =>
    navigate("/oversikt", { state: { activeTab: 0, valgtOmradekode: props.valgtOmradekode } });
  const navigateToOversiktSkutteDyr = () =>
    navigate("/oversikt", { state: { activeTab: 1, valgtOmradekode: props.valgtOmradekode } });
  const navigateToRegistrering = () => navigate("/registrering");
  return (
    <>
      <ButtonDefault
        id={"registreringSuccessDialogFellingsInfoButton"}
        variant={ButtonVariant.SecondaryWhite}
        label={t("pages.registrering.dialogs.overforingSuccess.leggTilFellingsInfoButtonText")}
        onClick={() => navigateToOversiktSkutteDyr()}
      />
      <ButtonDefault
        id={"registreringSuccessDialogSeMineRegistreringerButton"}
        variant={ButtonVariant.SecondaryWhite}
        label={t("pages.registrering.dialogs.overforingSuccess.seMineRegistreringerButtonText")}
        onClick={() => navigateToOversiktJakdager()}
      />
      <ButtonDefault
        id={"registreringSuccessDialogNyRegistreringButton"}
        variant={ButtonVariant.SecondaryWhite}
        label={t("pages.registrering.dialogs.overforingSuccess.nyRegistreringButtonText")}
        onClick={() => navigateToRegistrering()}
      />
      <ButtonDefault
        id={"registreringSuccessDialogLukkButton"}
        label={t("actions.close")}
        onClick={() => navigateToOversiktJakdager()}
      />
    </>
  );
};

export default SuccessDialogButtons;
