import { Alert, Collapse } from "@mui/material";
import { PropsWithChildren, useContext } from "react";
import { AlertState } from "~/src/components/Alerts/AlertDefault";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { useStateful } from "~/src/hooks/useStateful";

type InfoAlertProps = PropsWithChildren & {
  canClose?: boolean;
  id?: string;
  onClose?: () => void;
};

export default function InfoAlert({
  children,
  canClose,
  id,
  onClose,
}: InfoAlertProps): JSX.Element {
  const storageKey = { name: "InfoAlert", id: id };
  const storage = useContext(StorageContext);
  let state: AlertState = { show: true };
  if (id) {
    state = storage?.get<AlertState>(storageKey)?.data || state;
  }
  const isOpen = useStateful<boolean>(state.show);
  const handleClose = () => {
    isOpen.set(false);
    if (id) {
      storage?.add({ objectKey: storageKey, data: { show: false } });
    }
    onClose && onClose();
  };

  return (
    <Collapse in={isOpen.value}>
      <Alert
        severity="info"
        icon={false}
        onClose={canClose ? handleClose : undefined}
        className="bg-md-info rounded mx-4 mt-4 py-2 px-3"
      >
        {children}
      </Alert>
    </Collapse>
  );
}
