import { Route, Routes } from "react-router-dom";
import JaktdagDetaljer from "./JaktdagDetaljer";
import Oversikt from "./Oversikt";
import SkuttDyrDetaljer from "./SkuttDyrDetaljer";
import SkuttDyrDetaljerVillsvin from "./SkuttDyrDetaljerVillsvin";

export default function OversiktLayout(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Oversikt />} />
      <Route path="skuttdyrdetaljer/villsvin/:individId" element={<SkuttDyrDetaljerVillsvin />} />
      <Route path="skuttdyrdetaljer/:art/:individId" element={<SkuttDyrDetaljer />} />
      <Route path="jaktdagdetaljer/:jaktdagId" element={<JaktdagDetaljer />} />
    </Routes>
  );
}
