import { UTCDate } from "@date-fns/utc";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { createContext, useContext, useEffect } from "react";
import { ClientState } from "~/src/api/jaktdataApi";
import { jaktdataApi } from "~/src/appGlobals";
import {
  SyncJaktomraderResultDefaults,
  syncJaktomrader,
} from "~/src/contexts/AppDataContext/JaktomradeSync";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { Stateful, useStateful } from "~/src/hooks/useStateful";
import { Favorittområde, JegerOpplysningerResponse, OmrådeData } from "../../api/types";
import {
  getJaktfeltQuery,
  getJaktområderQuery,
  getJegeropplysningerQuery,
  getValdQuery,
  getVillreinValdQuery,
} from "../../react-query/queries";
import { SyncIndividerResultDefaults, syncIndivider } from "./IndividSync";
import { SyncJaktdagerResultDefaults, syncJaktdager } from "./JaktdagSync";

export type AppData = {
  jegeropplysninger: UseQueryResult<JegerOpplysningerResponse>;
  jaktfelt: UseQueryResult<OmrådeData>;
  vald: UseQueryResult<OmrådeData>;
  villreinvald: UseQueryResult<OmrådeData>;
  jaktområder: UseQueryResult<Favorittområde[]>;
  allLoaded: boolean;
  isOnline: Stateful<boolean | undefined>;
  isSynced: Stateful<boolean | undefined>;
  lastSynced: Stateful<string | undefined>;
  didTrySyncWithItems: Stateful<boolean | undefined>;
};

const lastSyncedStorageKey = { name: "lastSynced" };

const lastSyncedDateFormat = "dd. LLL 'kl.' HH:mm";

const AppDataContext = createContext<AppData>({} as AppData);
const DataProvider = (props: { children: JSX.Element }): JSX.Element => {
  const storage = useContext(StorageContext);
  const ui = useContext(UIContext);
  const isOnline = useStateful<boolean | undefined>(undefined);
  const isSynced = useStateful<boolean | undefined>(undefined);
  const didTrySyncWithItems = useStateful<boolean | undefined>(undefined);
  const lastSynced = useStateful<string | undefined>(undefined);

  useEffect(() => {
    lastSynced.set(storage.get<string>(lastSyncedStorageKey)?.data);
    const checkIfReallyOnline = async () => {
      jaktdataApi.checkClientState().then((state: ClientState) => {
        isOnline.set(["Online", "NotAuthenticated"].includes(state));
      });
    };
    window.addEventListener("online", checkIfReallyOnline);
    window.addEventListener("offline", () => {
      isOnline.set(false);
      isSynced.set(!storage.anyPending());
    });

    checkIfReallyOnline();
    return () => {
      window.removeEventListener("online", () => checkIfReallyOnline);
      window.removeEventListener("offline", () => isOnline.set(false));
    };
  }, []);

  useEffect(() => {
    if (isSynced.value === true) {
      const lastSyncedDateStr = format(
        parseISO(new UTCDate().toISOString()),
        lastSyncedDateFormat,
      ).toLowerCase();
      storage.add({ objectKey: lastSyncedStorageKey, data: lastSyncedDateStr });
      lastSynced.set(lastSyncedDateStr);
    }
  }, [isSynced.value]);

  const jegeropplysninger = useQuery({
    queryKey: ["jegeropplysninger"],
    queryFn: getJegeropplysningerQuery,
    enabled: isOnline.value,
  });
  const jaktfelt = useQuery({
    queryKey: ["jaktfelt"],
    queryFn: getJaktfeltQuery,
    enabled: isOnline.value,
  });
  const vald = useQuery({ queryKey: ["vald"], queryFn: getValdQuery });
  const villreinvald = useQuery({
    queryKey: ["villreinvald"],
    queryFn: getVillreinValdQuery,
    enabled: isOnline.value,
  });
  const jaktområder = useQuery({
    queryKey: ["jaktomrader"],
    queryFn: async () => {
      return getJaktområderQuery().then((omrader) => {
        return omrader.concat(
          storage
            .getAll<Favorittområde>("PendingJaktomrade")
            .filter((item) => item.operation !== "delete")
            .map((item) => item.data),
        );
      });
    },
    enabled: isOnline.value,
  });

  const allLoaded = [jegeropplysninger, jaktfelt, vald, villreinvald, jaktområder].every(
    (result) => result.isSuccess,
  );

  const appData: AppData = {
    jegeropplysninger,
    jaktfelt,
    vald,
    villreinvald,
    jaktområder,
    allLoaded,
    isOnline,
    isSynced,
    lastSynced,
    didTrySyncWithItems,
  };

  useEffect(() => {
    if (isOnline.value !== true || !allLoaded) return;

    // cache jaktdager og individer
    const year = new Date().getFullYear();
    jaktområder.data
      ?.filter((omrade) => !omrade.isPending && !!omrade.Områdekode)
      .forEach((jaktomrade) => {
        const omradekode = jaktomrade.Områdekode || "";
        jaktdataApi.getJaktdager(year, omradekode).then((jaktdager) => {
          jaktdager.forEach((jaktdag) => jaktdataApi.getJaktdag(jaktdag.Id || ""));
        });
        jaktdataApi.getSkutteDyr(year, omradekode).then((individer) => {
          individer.forEach((individ) => {
            jaktdataApi.getIndivid(individ.Id || "");
          });
        });
      });

    let jaktomraderSynced = SyncJaktomraderResultDefaults;
    let jaktdagerSynced = SyncJaktdagerResultDefaults;
    let individerSynced = SyncIndividerResultDefaults;

    syncJaktomrader(storage, ui)
      .then((result) => {
        jaktomraderSynced = result || jaktomraderSynced;
      })
      .finally(() => {
        syncJaktdager(storage, ui)
          .then((result) => {
            jaktdagerSynced = result || jaktdagerSynced;
          })
          .finally(() => {
            syncIndivider(storage, ui)
              ?.then((result) => {
                individerSynced = result || individerSynced;
              })
              .finally(() => {
                isSynced.set(!storage.anyPending());
                if (jaktdagerSynced.totalItemsHandled + individerSynced.totalItemsHandled > 0) {
                  didTrySyncWithItems.set(true);
                  setTimeout(() => {
                    didTrySyncWithItems.set(false);
                  }, 100);
                }
              });
          });
      });
  }, [allLoaded, isOnline.value]);

  return <AppDataContext.Provider value={appData}>{props.children}</AppDataContext.Provider>;
};

export { AppDataContext as default, DataProvider };
