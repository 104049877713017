import Tabs from "@mui/material/Tabs";
import { PropsWithChildren } from "react";

type TabsDefaultProps = PropsWithChildren & {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export default function TabsDefault({ value, children, onChange }: TabsDefaultProps): JSX.Element {
  return (
    <Tabs value={value} onChange={onChange} variant="fullWidth">
      {children}
    </Tabs>
  );
}
