import { CheckCircle } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { PropsWithChildren } from "react";

const GodkjentInfoBox = ({ children }: PropsWithChildren) => {
  return (
    <Alert
      severity="success"
      className="text-black rounded mx-4 mt-4 py-1 px-3 bg-md-info"
      icon={false}
    >
      <div className="flex items-center gap-2">
        {<CheckCircle className="text-md-primary" />} {children}
      </div>
    </Alert>
  );
};

export default GodkjentInfoBox;
