import { Search } from "@mui/icons-material";
import type { AutocompleteRenderOptionState, FilterOptionsState } from "@mui/material";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import React, { HTMLAttributes, useRef } from "react";
import { Stateful } from "../../../hooks/useStateful";
import ErrorText from "../../Text/ErrorText";
import Text from "../../Text/Text";

type ComboboxProps = {
  options: ComboboxOption[];
  onChange: (selected: ComboboxOption | null) => void;
  label: string;
  defaultValue?: ComboboxOption;
  noOptionsText?: string;
  open?: Stateful<boolean>;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: ComboboxOption,
    state: AutocompleteRenderOptionState,
  ) => JSX.Element;
  optionsLimit?: number;
  disabled?: boolean;
  errorHelpText?: string;
  showIcon?: boolean;
  helpText?: string;
  disableInput?: boolean;
  readOnlyInput?: boolean;
  inputMode?: "number" | "text";
};

export default function Combobox({
  options,
  onChange,
  label,
  defaultValue,
  noOptionsText,
  renderOption,
  open,
  optionsLimit = 42,
  disabled,
  errorHelpText,
  helpText,
  showIcon,
  disableInput = false,
  readOnlyInput = false,
  inputMode = "text",
}: ComboboxProps): JSX.Element {
  const defaultFilterOptions = createFilterOptions<ComboboxOption>();

  const anchorRef = useRef<HTMLDivElement>(null);
  const filterOptions = (options: ComboboxOption[], state: FilterOptionsState<ComboboxOption>) => {
    return defaultFilterOptions(options, state).slice(0, optionsLimit);
  };

  const providedRenderOption = renderOption;
  const optionsWithEmptyValue = options.concat([{ label: "", id: "", data: {} }]);
  const optionsPopperOffset = showIcon ? -16 : 0;
  return (
    <div className="w-full" ref={anchorRef}>
      <div className="flex flex-grow items-center bg-md-greengray text-md-primary rounded-t-md border-b-2 border-md-secondary">
        {showIcon && <Search className={`ml-2 mt-1 opacity-0"}`} />}
        <Autocomplete
          size="small"
          key={defaultValue?.id}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.label}
          options={optionsWithEmptyValue}
          defaultValue={defaultValue}
          filterOptions={filterOptions}
          onOpen={() => (open ? open.set(true) : undefined)}
          onClose={() => (open ? open.set(false) : undefined)}
          openOnFocus={true}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          fullWidth
          noOptionsText={noOptionsText ?? undefined}
          disabled={(options.length === 0 || disabled) ?? undefined}
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [optionsPopperOffset, 2] },
                },
              ],
              style: {
                width: anchorRef?.current?.clientWidth || 200,
                minHeight: 200,
              },
            },
            paper: {
              sx: {
                wordBreak: "break-word",
              },
            },
          }}
          sx={{
            "& .MuiFormLabel-root": {
              fontSize: 14,
              overflow: "hidden",
              whiteSpace: "pre-line",
              textOverflow: "ellipsis",
              height: 100,
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiOutlinedInput-root": {
              paddingBlockEnd: "0",
            },
            "& label": {
              color: "inherit !important",
              opacity: disabled ? "0" : "1 !important",
            },
            "& .MuiFilledInput-root": {
              background: "inherit !important",
            },
          }}
          onChange={(_e, value) => {
            onChange(value);
          }}
          renderOption={(props, option, state) => {
            if (providedRenderOption) {
              return providedRenderOption(props, option, state);
            } else if (option.id && option.label) {
              return (
                <li
                  {...props}
                  key={state.index}
                  className={` ${props.className} w-full flex flex-row items-center justify-between p-2`}
                >
                  {option.label}
                </li>
              );
            }
            return <li key={state.index} />;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={options.length === 0 ? noOptionsText : label}
              variant="filled"
              className={"bg-md-greengray rounded-t-lg"}
              inputProps={{
                ...params.inputProps,
                type: inputMode,
                readOnly: !!readOnlyInput,
                disabled: !!disableInput || options.length === 0,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id == value.id}
          autoHighlight={true}
          autoSelect={true}
        />
      </div>
      <div>
        {errorHelpText ? (
          <ErrorText>{errorHelpText}</ErrorText>
        ) : helpText ? (
          <Text>{helpText}</Text>
        ) : null}
      </div>
    </div>
  );
}

export type ComboboxOption<T = object> = {
  label: string;
  id: string;
  data?: T | null;
};
