import { format } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import { ArtType } from "../../../helpers/types";
import IndividForm from "../components/IndividForm";
import { IndividData } from "../schemas/schema";

const DEFAULT_RADYR: IndividData = {
  Id: "",
  Dato: format(new Date(), dateFormatISO),
  Art: ArtType.Rådyr,
  Vald: "",
};

export default function RegistrerRadyr(): JSX.Element {
  return <IndividForm initialInfo={DEFAULT_RADYR} mode="create" />;
}
