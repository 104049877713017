import { createTheme } from "@mui/material";
import defaultTheme from "tailwindcss/defaultTheme";

// theme extracted from tailwind.config for reusability
export const theme = {
  extend: {
    screens: {
      sm: "380px",
      md: "768px",
      lg: "976px",
      xl: "1440px",
      max: "976px",
      min: "380px",
    },
    minWidth: {
      "86": "22rem",
    },
  },
  colors: {
    transparent: "transparent",
    current: "currentColor",
    white: "#ffffff",
    black: "#000000",
    "md-primary": "#005E5D",
    "md-secondary": "#d0e3e2",
    "md-seagray": "#005e5d",
    "md-sjogronn-lys": "#40c1ac",
    "md-lysegronn": "#d0e3e2",
    "md-greengray": "#ebf3f3",
    "md-gray": "#4e4e4e",
    "md-neutral": "#222222",
    "md-brown": "#584446",
    "md-error-red": "#b3261e",
    "md-error-red-light": "#fca49f",
    "md-error-bg-red-light": "#f2e1e1",
    "md-warning-light": "#FCE4CA",
    "md-warning-dark": "#DE8215",
    "md-info": "#B9D9EB",
    "md-sea-green-dark": "#337E7D",
    "md-sea-green-medium": "#005E5D",
    "md-sea-green-light": "#40C1AC",
    "md-creme-light": "#FCFAF6",
    "md-creme-medium": "#faefd9",
  },
  fontFamily: {
    sans: [
      '-apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI", "Roboto", "Helvetica Neue", "Arial"',
      ...defaultTheme.fontFamily.sans,
    ],
  },
};
export const muiOverrides = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: theme.colors["md-primary"],
    },
    secondary: {
      main: theme.colors["md-secondary"],
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.colors["md-secondary"],
          },
          borderBottom: "2px solid",
          borderBottomColor: theme.colors["md-secondary"],
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          fontSize: "1.95rem",
        },
        label: {
          marginTop: "7px",
          fontSize: "0.95rem",
        },
      },
    },
  },
});

export const materialTailwindThemeOverrides = {
  input: {
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-10",
        },
        input: {
          width: "w-full",
        },
      },
      variants: {
        standard: {
          base: {
            input: {
              borderWidth: "0",
            },
          },
        },
      },
    },
  },
};
