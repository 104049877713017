/* tslint:disable */
/* eslint-disable */
/**
 * Hjorteviltregisteret API Jaktdata
 * Jaktdata API gir eksterne aktører mulighet for å lagre og lese ut jaktdata (sette og skutte dyr) fra Hjorteviltregisteret. For å få tilgang til dette APIet, er man nødt til å ha API-bruker-rollen i registeret.  Autentisering er beskrevet mer under endepunktene for å hente tokens (JWT).   Ta gjerne kontakt for tilbakemeldinger på APIet, ønsker og forbedringsforslag.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest
 */
export interface HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest
     */
    'Brukernavn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest
     */
    'Passord'?: string | null;
}
/**
 * 
 * @export
 * @interface HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest
 */
export interface HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest {
    /**
     * 
     * @type {number}
     * @memberof HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest
     */
    'Jegernummer': number;
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest
     */
    'Fodselsdato': string;
}
/**
 * 
 * @export
 * @interface HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest
 */
export interface HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest
     */
    'Brukernavn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest
     */
    'Token'?: string | null;
}
/**
 * 
 * @export
 * @interface HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest
 */
export interface HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest {
    /**
     * 
     * @type {number}
     * @memberof HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest
     */
    'Jegernummer': number;
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest
     */
    'Token'?: string | null;
}
/**
 * 
 * @export
 * @interface HjorteviltBrukereV1AutentiseringSharedTokenResponse
 */
export interface HjorteviltBrukereV1AutentiseringSharedTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringSharedTokenResponse
     */
    'AccessToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HjorteviltBrukereV1AutentiseringSharedTokenResponse
     */
    'AccessTokenExpiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof HjorteviltBrukereV1AutentiseringSharedTokenResponse
     */
    'RefreshToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HjorteviltBrukereV1AutentiseringSharedTokenResponse
     */
    'RefreshTokenExpiration'?: number;
}
/**
 * Skjema for å legge til jaktområde for jeger
 * @export
 * @interface HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest
 */
export interface HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest {
    /**
     * Kode for jaktområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest
     */
    'Områdekode': string;
    /**
     * Type område; Kommune, Vald, Jaktfelt eller Villreinvald
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest
     */
    'Områdetype': string;
}
/**
 * Skjema for å slette jegers jaktområde
 * @export
 * @interface HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest
 */
export interface HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest {
    /**
     * Kode for jaktområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest
     */
    'Områdekode': string;
    /**
     * Type område; Kommune, Vald, Jaktfelt eller Villreinvald
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest
     */
    'Områdetype': string;
}
/**
 * Opplysninger om jaktområde for jeger.
 * @export
 * @interface HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
 */
export interface HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse {
    /**
     * Type jaktområde; Kommune, Villreinvald, Vald eller Jaktfelt
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'Områdetype'?: string | null;
    /**
     * Kode for jaktområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'Områdekode'?: string | null;
    /**
     * Navn på jaktområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'Områdenavn'?: string | null;
    /**
     * Navn på tilhørende kommune
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørerendeKommunenavn'?: string | null;
    /**
     * Kode på tilhørende kommune
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørendeKommunekode'?: string | null;
    /**
     * Navn på tilhørende vald
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørerendeValdnavn'?: string | null;
    /**
     * Kode på tilhørende vald
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørendeValdkode'?: string | null;
    /**
     * Navn på tilhørende villreinområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørendeVillreinområdenavn'?: string | null;
    /**
     * Kode på tilhørende villreinområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'TilhørendeVillreinområdekode'?: string | null;
    /**
     * Navn på jaktområdeansvarlig
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'AnsvarligPersonaliaNavn'?: string | null;
    /**
     * E-post til jaktområdeansvarlig
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'AnsvarligPersonaliaEpost'?: string | null;
    /**
     * Telefonnummer til jaktområdeansvarlig
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'AnsvarligPersonaliaTlf'?: string | null;
    /**
     * Om området har jakt på elg
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'JaktPåElg'?: boolean;
    /**
     * Om området har jakt på hjort
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'JaktPåHjort'?: boolean;
    /**
     * Om området har jakt på rådyr
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'JaktPåRådyr'?: boolean;
    /**
     * Om området har jakt på villrein
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'JaktPåVillrein'?: boolean;
    /**
     * Om området har jakt på villsvin
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'JaktPåVillsvin'?: boolean;
    /**
     * Om området er åpent for å registrere skutte elg
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'ÅpenForRegistreringElg'?: boolean;
    /**
     * Om området er åpent for å registrere skutte hjort
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'ÅpenForRegistreringHjort'?: boolean;
    /**
     * Om området er åpent for å registrere skutte rådyr
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'ÅpenForRegistreringRådyr'?: boolean;
    /**
     * Om området er åpent for å registrere skutte villrein
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'ÅpenForRegistreringVillrein'?: boolean;
    /**
     * Om området er åpent for å registrere skutte villsvin
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'ÅpenForRegistreringVillsvin'?: boolean;
    /**
     * Tidspunktet jeger valgte området som sitt jaktområde
     * @type {string}
     * @memberof HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse
     */
    'SistOppdatert'?: string;
}
/**
 * Opplysninger om jeger.
 * @export
 * @interface HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
 */
export interface HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse {
    /**
     * Jegernummer
     * @type {number}
     * @memberof HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
     */
    'Jegernummer'?: number;
    /**
     * Navn på jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
     */
    'Navn'?: string | null;
    /**
     * E-post til jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
     */
    'Epost'?: string | null;
    /**
     * Telefonnummer til jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
     */
    'Telefonnummer'?: string | null;
    /**
     * Har jeger registrert Navn og Telefonnummer
     * @type {boolean}
     * @memberof HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse
     */
    'HarGyldigProfil'?: boolean;
}
/**
 * Skjema for å redigere opplysninger for jeger
 * @export
 * @interface HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest
 */
export interface HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest {
    /**
     * Navn på jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest
     */
    'Navn': string;
    /**
     * E-post til jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest
     */
    'Epost'?: string | null;
    /**
     * Telefonnummer til jeger
     * @type {string}
     * @memberof HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest
     */
    'Telefonnummer': string;
}
/**
 * Skjema for å opprette et nytt individ
 * @export
 * @interface HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
 */
export interface HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Id': string;
    /**
     * ID for jaktdagen som individet tilhører. Påkrevd for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'JaktdagId'?: string | null;
    /**
     * Fellingdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort, Elg, Rådyr eller Villrein
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Art': string;
    /**
     * Alderen til dyret. Kalv, Ungdyr eller Voksen
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Alder': string;
    /**
     * Kjønnet til dyret. Hann eller Hunn
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Kjønn': string;
    /**
     * Innmark eller Utmark. Skal kun brukes for Hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Terreng'?: string | null;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for dyret, kun aktuelt for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Jaktfelt'?: string | null;
    /**
     * Vald-ID for dyret, kun aktuelt for rådyr.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Vald'?: string | null;
    /**
     * Villreinvald-ID for dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Villreinvald'?: string | null;
    /**
     * Merkelappnummer registrert på dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Merkelapp'?: string | null;
    /**
     * Kontrollkort registrert på dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Kontrollkort'?: string | null;
    /**
     * Antall gevirtakker til venstre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'GevirtakkerVenstre'?: number | null;
    /**
     * Antall gevirtakker til høyre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'GevirtakkerHøyre'?: number | null;
    /**
     * Målemetode for slaktevekt. Veid, Anslått eller IkkeVeid
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'MålemetodeSlaktevekt'?: string | null;
    /**
     * Slaktevekt for dyret i kilo. Påkrevd hvis målemetode er Veid eller Anslått
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'SlaktevektKg'?: number | null;
    /**
     * Antall kalver som var i følge med hunndyr. Kun aktuelt for voksne hunndyr.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'AntallKalverIfølgeMedHunndyret'?: number | null;
    /**
     * Indikerer om dyret var i følge med mordyr. Kun aktuelt for kalver.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'FeltKalvIkkeIfølgeMedMorDyr'?: boolean | null;
    /**
     * Indikerer om dyret hadde melk i juret. Kun aktuelt for voksne hunndyr.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'MelkIJuret'?: boolean | null;
    /**
     * Tekstlig beskrivelse av fellingssted
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Fellingssted'?: string | null;
    /**
     * Format for koordinatfesting. Mulige verdier: LengdeOgBreddegrad, UTM32, UTM33, UTM34, UTM35, UTM36
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Koordinatfesting'?: string | null;
    /**
     * Øst-koordinat for stedfesting
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KoordinatØst'?: string | null;
    /**
     * Nord-koordinat for stedfesting
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KoordinatNord'?: string | null;
    /**
     * Indikerer om det er levert hjerneprøve for CWD.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'LevertHjerneprøve'?: boolean | null;
    /**
     * Eventuelle merknader for dyret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'Merknad'?: string | null;
    /**
     * Send kvittering til innlogget jeger.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KvitteringEgen'?: boolean | null;
    /**
     * Send kvittering til jaktleder. Gjelder kun jegerautentiserte brukere.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KvitteringJaktleder'?: boolean | null;
    /**
     * Send kvittering til valdansvarlig. Gjelder kun jegerautentiserte brukere.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KvitteringValdansvarlig'?: boolean | null;
    /**
     * Send kvittering til annen e-post. Gjelder kun jegerautentiserte brukere.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest
     */
    'KvitteringAnnen'?: string | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1IndividDeleteIndividDeleteIndividRequest
 */
export interface HjorteviltJaktdataV1IndividDeleteIndividDeleteIndividRequest {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividDeleteIndividDeleteIndividRequest
     */
    'Id': string;
}
/**
 * Detaljer for et skutt dyr (individ)
 * @export
 * @interface HjorteviltJaktdataV1IndividSharedIndividResponse
 */
export interface HjorteviltJaktdataV1IndividSharedIndividResponse {
    /**
     * Uri er en unik url for et individ, som også kan benyttes for å hente all info om individet.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Uri'?: string | null;
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Id'?: string;
    /**
     * Fellingdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Dato'?: string | null;
    /**
     * Indikerer om fellingsdato for individ er utenfor standard jakttid på arten
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'UtenforJakttid'?: boolean;
    /**
     * Indikerer om dyret er registrert i et område som er overvåket
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'SkuttIOvervåkområde'?: boolean;
    /**
     * Jaktsesong hvor dyret ble felt
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Sesong'?: number;
    /**
     * Arten til dyret. Hjort, Elg, Rådyr eller Villrein
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Art'?: string | null;
    /**
     * Alderen til dyret. Kalv, Ungdyr eller Voksen
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Alder'?: string | null;
    /**
     * Kjønnet til dyret. Hann eller Hunn
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Kjønn'?: string | null;
    /**
     * Brukernavn (e-post) til brukeren som har registrert dyret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Registrator'?: string | null;
    /**
     * Jegernummeret til jegeren som har registrert dyret.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Jegernummer'?: number | null;
    /**
     * Fullt navn på den som har registrert dyret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'RegistrertAv'?: string | null;
    /**
     * Registreringsdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'RegistrertDato'?: string | null;
    /**
     * Fullt navn på den som har gjort siste endringer på dyret. Ikke implementert enda.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'SistEndretAv'?: string | null;
    /**
     * Dato for når dyret sist ble endret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'SistEndretDato'?: string | null;
    /**
     * Merkelappnummer registrert på dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Merkelapp'?: string | null;
    /**
     * Kontrollkort registrert på dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Kontrollkort'?: string | null;
    /**
     * Antall gevirtakker til venstre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'GevirtakkerVenstre'?: number | null;
    /**
     * Antall gevirtakker til høyre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'GevirtakkerHøyre'?: number | null;
    /**
     * Målemetode for slaktevekt. Veid, Anslått eller IkkeVeid
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'MålemetodeSlaktevekt'?: string | null;
    /**
     * Slaktevekt for dyret i kilo
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'SlaktevektKg'?: number | null;
    /**
     * Innmark, Utmark eller Ukjent. Kun oppgitt for Hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Terreng'?: string | null;
    /**
     * Antall kalver som var i følge med hunndyr. Kun aktuelt for voksne hunndyr.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'AntallKalverIfølgeMedHunndyret'?: number | null;
    /**
     * Indikerer om dyret var i følge med mordyr. Kun aktuelt for kalver.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'FeltKalvIkkeIfølgeMedMorDyr'?: boolean | null;
    /**
     * Indikerer om dyret hadde melk i juret. Kun aktuelt for voksne hunndyr.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'MelkIJuret'?: boolean | null;
    /**
     * Tekstlig beskrivelse av fellingssted
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Fellingssted'?: string | null;
    /**
     * Format for koordinatfesting. LengdeOgBreddegrad, UTM32, UTM33, UTM34, UTM35, UTM36
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Koordinatfesting'?: string | null;
    /**
     * Øst-koordinat for stedfesting.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'KoordinatØst'?: string | null;
    /**
     * Nord-koordinat for stedfesting
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'KoordinatNord'?: string | null;
    /**
     * Indikerer om det er levert hjerneprøve for CWD.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'LevertHjerneprøve'?: boolean | null;
    /**
     * Eventuelle merknader satt på dyret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Merknad'?: string | null;
    /**
     * Om dyret er godkjent i registeret. Etter godkjenning kan ikke dyret endres.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Godkjent'?: boolean;
    /**
     * ID for jaktdagen som individet tilhører. Kun aktuelt for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'JaktdagId'?: string | null;
    /**
     * Jaktfelt-ID for dyret, kun aktuelt for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Jaktfelt'?: string | null;
    /**
     * Vald-ID for dyret, kun aktuelt for rådyr.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Vald'?: string | null;
    /**
     * Villreinvald-ID for dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'Villreinvald'?: string | null;
    /**
     * Dyrets svar på CWD-prøve. Kan ha flere statuser: \"Ukjent om prøve er tatt\", \"Ikke levert\", \"Venter på svar\", og prøvesvar foreligger med enten \"Positiv\", \"Negativ\" eller \"Uegnet materiale\" som status.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'CWDPrøvesvar'?: string | null;
    /**
     * Dyrets eksakte alder.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividSharedIndividResponse
     */
    'EksaktAlder'?: number | null;
}
/**
 * Skjema for å redigere et individ (skutt dyr)
 * @export
 * @interface HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
 */
export interface HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Id': string;
    /**
     * ID for jaktdagen som individet tilhører. Påkrevd for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'JaktdagId'?: string | null;
    /**
     * Fellingdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort, Elg, Rådyr eller Villrein
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Art': string;
    /**
     * Alderen til dyret. Kalv, Ungdyr eller Voksen
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Alder': string;
    /**
     * Kjønnet til dyret. Hann eller Hunn
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Kjønn': string;
    /**
     * Innmark eller Utmark. Skal kun brukes for Hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Terreng'?: string | null;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for dyret, kun aktuelt for elg og hjort.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Jaktfelt'?: string | null;
    /**
     * Vald-ID for dyret, kun aktuelt for rådyr.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Vald'?: string | null;
    /**
     * Villreinvald-ID for dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Villreinvald'?: string | null;
    /**
     * Merkelappnummer registrert på dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Merkelapp'?: string | null;
    /**
     * Kontrollkort registrert på dyret, kun aktuelt for villrein.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Kontrollkort'?: string | null;
    /**
     * Antall gevirtakker til venstre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'GevirtakkerVenstre'?: number | null;
    /**
     * Antall gevirtakker til høyre
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'GevirtakkerHøyre'?: number | null;
    /**
     * Målemetode for slaktevekt. Veid, Anslått eller IkkeVeid
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'MålemetodeSlaktevekt'?: string | null;
    /**
     * Slaktevekt for dyret i kilo. Påkrevd hvis målemetode er Veid eller Anslått
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'SlaktevektKg'?: number | null;
    /**
     * Antall kalver som var i følge med hunndyr. Kun aktuelt for voksne hunndyr.
     * @type {number}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'AntallKalverIfølgeMedHunndyret'?: number | null;
    /**
     * Indikerer om dyret var i følge med mordyr. Kun aktuelt for kalver.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'FeltKalvIkkeIfølgeMedMorDyr'?: boolean | null;
    /**
     * Indikerer om dyret hadde melk i juret. Kun aktuelt for voksne hunndyr.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'MelkIJuret'?: boolean | null;
    /**
     * Tekstlig beskrivelse av fellingssted
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Fellingssted'?: string | null;
    /**
     * Format for koordinatfesting. Mulige verdier: LengdeOgBreddegrad, UTM32, UTM33, UTM34, UTM35, UTM36
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Koordinatfesting'?: string | null;
    /**
     * Øst-koordinat for stedfesting
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KoordinatØst'?: string | null;
    /**
     * Nord-koordinat for stedfesting
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KoordinatNord'?: string | null;
    /**
     * Indikerer om det er levert hjerneprøve for CWD.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'LevertHjerneprøve'?: boolean | null;
    /**
     * Eventuelle merknader for dyret.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'Merknad'?: string | null;
    /**
     * Send kvittering til innlogget jeger.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KvitteringEgen'?: boolean | null;
    /**
     * Send kvittering til jaktleder. Gjelder kun jegerautentiserte brukere.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KvitteringJaktleder'?: boolean | null;
    /**
     * Send kvittering til valdansvarlig. Gjelder kun jegerautentiserte brukere.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KvitteringValdansvarlig'?: boolean | null;
    /**
     * Send kvittering til annen e-post. Gjelder kun jegerautentiserte brukere.
     * @type {string}
     * @memberof HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest
     */
    'KvitteringAnnen'?: string | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
 */
export interface HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Id': string;
    /**
     * Fellingsdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort eller Elg
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Art': string;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for jaktdagen, på formatet 1000J0001
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Jaktfelt': string;
    /**
     * Om jaktdagen omhandler utmark, kun aktuelt for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Utmark'?: boolean | null;
    /**
     * Antall timer jaktet. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'AntallTimerJaktet'?: number | null;
    /**
     * Antall jegere. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'AntallJegere'?: number | null;
    /**
     * Antall sette spissbukk. Gjelder kun hjort på utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettSpissbukk'?: number | null;
    /**
     * Antall okser eller bukker sett. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettOkseEllerBukk'?: number | null;
    /**
     * Antall kyr uten kalv (elg) eller koller (hjort) på utmark sett.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKyrUtenKalvEllerKolle'?: number | null;
    /**
     * Antall kyr sett med 1 kalv. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKyrMed1Kalv'?: number | null;
    /**
     * Antall kyr sett med 2 eller flere kalver. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKyrMed2EllerFlereKalver'?: number | null;
    /**
     * Antall kalver sett alene (elg) eller antall kalver sett på utmark (hjort).
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKalverAlene'?: number | null;
    /**
     * Antall ukjente dyr sett.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettUkjente'?: number | null;
    /**
     * Om jaktdagen omhandler utmark. Kun aktuelt for hjort.  Dette og feltene som gjelder innmark brukes kun for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'Innmark'?: boolean | null;
    /**
     * Antall timer jaktet på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'AntallTimerJaktetInnmark'?: number | null;
    /**
     * Antall jegere på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'AntallJegereInnmark'?: number | null;
    /**
     * Antall spissbukk sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettSpissbukkInnmark'?: number | null;
    /**
     * Antall bukker sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettBukkInnmark'?: number | null;
    /**
     * Antall koller sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKolleInnmark'?: number | null;
    /**
     * Antall kalver sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettKalvInnmark'?: number | null;
    /**
     * Antall ukjente sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettUkjenteInnmark'?: number | null;
    /**
     * Antallet sette bjørn på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettBjørn'?: number | null;
    /**
     * Antallet sette jerv på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettJerv'?: number | null;
    /**
     * Antallet sette gauper på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettGaupe'?: number | null;
    /**
     * Antallet sette ulver på jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest
     */
    'SettUlv'?: number | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
 */
export interface HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Id': string;
    /**
     * Fellingsdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort eller Elg
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Art': string;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for jaktdagen, på formatet 1000J0001
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Jaktfelt': string;
    /**
     * Om jaktdagen omhandler utmark, kun aktuelt for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Utmark'?: boolean | null;
    /**
     * Antall timer jaktet. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'AntallTimerJaktet'?: number | null;
    /**
     * Antall jegere. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'AntallJegere'?: number | null;
    /**
     * Antall sette spissbukk. Gjelder kun hjort på utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettSpissbukk'?: number | null;
    /**
     * Antall okser eller bukker sett. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettOkseEllerBukk'?: number | null;
    /**
     * Antall kyr uten kalv (elg) eller koller (hjort) på utmark sett.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKyrUtenKalvEllerKolle'?: number | null;
    /**
     * Antall kyr sett med 1 kalv. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKyrMed1Kalv'?: number | null;
    /**
     * Antall kyr sett med 2 eller flere kalver. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKyrMed2EllerFlereKalver'?: number | null;
    /**
     * Antall kalver sett alene (elg) eller antall kalver sett på utmark (hjort).
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKalverAlene'?: number | null;
    /**
     * Antall ukjente dyr sett.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettUkjente'?: number | null;
    /**
     * Om jaktdagen omhandler utmark. Kun aktuelt for hjort.  Dette og feltene som gjelder innmark brukes kun for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Innmark'?: boolean | null;
    /**
     * Antall timer jaktet på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'AntallTimerJaktetInnmark'?: number | null;
    /**
     * Antall jegere på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'AntallJegereInnmark'?: number | null;
    /**
     * Antall spissbukk sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettSpissbukkInnmark'?: number | null;
    /**
     * Antall bukker sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettBukkInnmark'?: number | null;
    /**
     * Antall koller sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKolleInnmark'?: number | null;
    /**
     * Antall kalver sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettKalvInnmark'?: number | null;
    /**
     * Antall ukjente sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettUkjenteInnmark'?: number | null;
    /**
     * Antallet sette bjørn på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettBjørn'?: number | null;
    /**
     * Antallet sette jerv på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettJerv'?: number | null;
    /**
     * Antallet sette gauper på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettGaupe'?: number | null;
    /**
     * Antallet sette ulver på jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'SettUlv'?: number | null;
    /**
     * 
     * @type {Array<HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest>}
     * @memberof HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest
     */
    'Individer'?: Array<HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest> | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagDeleteJaktdagDeleteJaktdagRequest
 */
export interface HjorteviltJaktdataV1JaktdagDeleteJaktdagDeleteJaktdagRequest {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagDeleteJaktdagDeleteJaktdagRequest
     */
    'Id': string;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagDeleteJaktdagMedIndividDeleteJaktdagMedIndividRequest
 */
export interface HjorteviltJaktdataV1JaktdagDeleteJaktdagMedIndividDeleteJaktdagMedIndividRequest {
    /**
     * 
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagDeleteJaktdagMedIndividDeleteJaktdagMedIndividRequest
     */
    'Id': string;
}
/**
 * Detaljer for en jaktdag (dagsrapport for sette dyr)
 * @export
 * @interface HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
 */
export interface HjorteviltJaktdataV1JaktdagSharedJaktdagResponse {
    /**
     * Uri er en unik url for en jaktdag, som også kan benyttes for å hente all info om jaktdag.
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Uri'?: string | null;
    /**
     * Unik identifikator for jaktdag (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Id'?: string;
    /**
     * Dato for jaktdagen
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Dato'?: string | null;
    /**
     * Indikerer om datoen for jaktdagen er utenfor standard jakttid på arten
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'UtenforJakttid'?: boolean;
    /**
     * Jaktsesong for jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Sesong'?: number;
    /**
     * Arten jaktdagen gjelder. Kun Hjort eller Elg er gyldig for jaktdag
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Art'?: string | null;
    /**
     * Brukernavn (e-post) til brukeren som har registrert jaktdagen.
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Registrator'?: string | null;
    /**
     * Jegernummeret til jegeren som har registrert jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Jegernummer'?: number | null;
    /**
     * Fullt navn på den som har registrert jaktdagen.
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'RegistrertAv'?: string | null;
    /**
     * Om jaktdagen omhandler utmark, kun aktuelt for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Utmark'?: boolean | null;
    /**
     * Antall timer jaktet. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'AntallTimerJaktet'?: number | null;
    /**
     * Antall jegere. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'AntallJegere'?: number | null;
    /**
     * Antall sette spissbukk. Gjelder kun hjort på utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettSpissbukk'?: number;
    /**
     * Antall okser eller bukker sett. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettOkseEllerBukk'?: number;
    /**
     * Antall kyr uten kalv (elg) eller koller (hjort) på utmark sett.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKyrUtenKalvEllerKolle'?: number;
    /**
     * Antall kyr sett med 1 kalv. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKyrMed1Kalv'?: number;
    /**
     * Antall kyr sett med 2 eller flere kalver. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKyrMed2EllerFlereKalver'?: number;
    /**
     * Antall kalver sett alene (elg) eller antall kalver sett på utmark (hjort).
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKalverAlene'?: number;
    /**
     * Antall ukjente dyr sett.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettUkjente'?: number;
    /**
     * Antall dyr sett totalt.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettTotalt'?: number;
    /**
     * Om jaktdagen omhandler utmark. Kun aktuelt for hjort.  Dette og feltene som gjelder innmark brukes kun for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Innmark'?: boolean | null;
    /**
     * Antall timer jaktet på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'AntallTimerJaktetInnmark'?: number | null;
    /**
     * Antall jegere på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'AntallJegereInnmark'?: number | null;
    /**
     * Antall spissbukk sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettSpissbukkInnmark'?: number;
    /**
     * Antall bukker sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettBukkInnmark'?: number;
    /**
     * Antall koller sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKolleInnmark'?: number;
    /**
     * Antall kalver sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettKalvInnmark'?: number;
    /**
     * Antall ukjente sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettUkjenteInnmark'?: number;
    /**
     * Antall sett totalt på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettTotaltInnmark'?: number;
    /**
     * Antallet sette bjørn på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettBjørn'?: number;
    /**
     * Antallet sette jerv på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettJerv'?: number;
    /**
     * Antallet sette gauper på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettGaupe'?: number;
    /**
     * Antallet sette ulver på jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'SettUlv'?: number;
    /**
     * Om jaktdagen er godkjent i registeret. Etter godkjenning kan ikke jatdagen endres.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Godkjent'?: boolean;
    /**
     * Jaktfelt-ID for jaktdagen
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Jaktfelt'?: string | null;
    /**
     * En liste med skutte dyr (Individ) som er registrert på jaktdagen
     * @type {Array<HjorteviltJaktdataV1IndividSharedIndividResponse>}
     * @memberof HjorteviltJaktdataV1JaktdagSharedJaktdagResponse
     */
    'Individer'?: Array<HjorteviltJaktdataV1IndividSharedIndividResponse> | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
 */
export interface HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Id': string;
    /**
     * Fellingsdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort eller Elg
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Art': string;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for jaktdagen, på formatet 1000J0001
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Jaktfelt': string;
    /**
     * Om jaktdagen omhandler utmark, kun aktuelt for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Utmark'?: boolean | null;
    /**
     * Antall timer jaktet. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'AntallTimerJaktet'?: number | null;
    /**
     * Antall jegere. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'AntallJegere'?: number | null;
    /**
     * Antall sette spissbukk. Gjelder kun hjort på utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettSpissbukk'?: number | null;
    /**
     * Antall okser eller bukker sett. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettOkseEllerBukk'?: number | null;
    /**
     * Antall kyr uten kalv (elg) eller koller (hjort) på utmark sett.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKyrUtenKalvEllerKolle'?: number | null;
    /**
     * Antall kyr sett med 1 kalv. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKyrMed1Kalv'?: number | null;
    /**
     * Antall kyr sett med 2 eller flere kalver. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKyrMed2EllerFlereKalver'?: number | null;
    /**
     * Antall kalver sett alene (elg) eller antall kalver sett på utmark (hjort).
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKalverAlene'?: number | null;
    /**
     * Antall ukjente dyr sett.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettUkjente'?: number | null;
    /**
     * Om jaktdagen omhandler utmark. Kun aktuelt for hjort.  Dette og feltene som gjelder innmark brukes kun for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Innmark'?: boolean | null;
    /**
     * Antall timer jaktet på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'AntallTimerJaktetInnmark'?: number | null;
    /**
     * Antall jegere på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'AntallJegereInnmark'?: number | null;
    /**
     * Antall spissbukk sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettSpissbukkInnmark'?: number | null;
    /**
     * Antall bukker sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettBukkInnmark'?: number | null;
    /**
     * Antall koller sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKolleInnmark'?: number | null;
    /**
     * Antall kalver sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettKalvInnmark'?: number | null;
    /**
     * Antall ukjente sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettUkjenteInnmark'?: number | null;
    /**
     * Antallet sette bjørn på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettBjørn'?: number | null;
    /**
     * Antallet sette jerv på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettJerv'?: number | null;
    /**
     * Antallet sette gauper på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettGaupe'?: number | null;
    /**
     * Antallet sette ulver på jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'SettUlv'?: number | null;
    /**
     * 
     * @type {Array<HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest>}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest
     */
    'Individer'?: Array<HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest> | null;
}
/**
 * 
 * @export
 * @interface HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
 */
export interface HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest {
    /**
     * Unik identifikator for dyret (GUID)
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Id': string;
    /**
     * Fellingsdato for dyret
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Dato': string;
    /**
     * Arten til dyret. Hjort eller Elg
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Art': string;
    /**
     * Hvis jaktdagen skal knyttes til en spesiell jeger, kan jegernummeret til jegeren sendes inn her.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Jegernummer'?: number | null;
    /**
     * Jaktfelt-ID for jaktdagen, på formatet 1000J0001
     * @type {string}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Jaktfelt': string;
    /**
     * Om jaktdagen omhandler utmark, kun aktuelt for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Utmark'?: boolean | null;
    /**
     * Antall timer jaktet. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'AntallTimerJaktet'?: number | null;
    /**
     * Antall jegere. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'AntallJegere'?: number | null;
    /**
     * Antall sette spissbukk. Gjelder kun hjort på utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettSpissbukk'?: number | null;
    /**
     * Antall okser eller bukker sett. For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettOkseEllerBukk'?: number | null;
    /**
     * Antall kyr uten kalv (elg) eller koller (hjort) på utmark sett.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKyrUtenKalvEllerKolle'?: number | null;
    /**
     * Antall kyr sett med 1 kalv. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKyrMed1Kalv'?: number | null;
    /**
     * Antall kyr sett med 2 eller flere kalver. Kun aktuelt for elg.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKyrMed2EllerFlereKalver'?: number | null;
    /**
     * Antall kalver sett alene (elg) eller antall kalver sett på utmark (hjort).
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKalverAlene'?: number | null;
    /**
     * Antall ukjente dyr sett.  For hjort gjelder dette utmark.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettUkjente'?: number | null;
    /**
     * Om jaktdagen omhandler utmark. Kun aktuelt for hjort.  Dette og feltene som gjelder innmark brukes kun for hjort.
     * @type {boolean}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'Innmark'?: boolean | null;
    /**
     * Antall timer jaktet på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'AntallTimerJaktetInnmark'?: number | null;
    /**
     * Antall jegere på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'AntallJegereInnmark'?: number | null;
    /**
     * Antall spissbukk sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettSpissbukkInnmark'?: number | null;
    /**
     * Antall bukker sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettBukkInnmark'?: number | null;
    /**
     * Antall koller sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKolleInnmark'?: number | null;
    /**
     * Antall kalver sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettKalvInnmark'?: number | null;
    /**
     * Antall ukjente sett på innmark. Kun aktuelt for hjort.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettUkjenteInnmark'?: number | null;
    /**
     * Antallet sette bjørn på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettBjørn'?: number | null;
    /**
     * Antallet sette jerv på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettJerv'?: number | null;
    /**
     * Antallet sette gauper på jaktdagen.
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettGaupe'?: number | null;
    /**
     * Antallet sette ulver på jaktdagen
     * @type {number}
     * @memberof HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest
     */
    'SettUlv'?: number | null;
}
/**
 * Informasjon om et jaktfelt, som brukes for å registere elg og hjort
 * @export
 * @interface HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
 */
export interface HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse {
    /**
     * ID for jaktfeltet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'Id'?: string | null;
    /**
     * Navnet på jaktfeltet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'Navn'?: string | null;
    /**
     * ID for valdet som jaktfeltet hører til
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'ValdId'?: string | null;
    /**
     * Navn på valdet som jaktfeltet hører til
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'ValdNavn'?: string | null;
    /**
     * Kommunenummer for kommunen som jaktfeltet ligger i
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'KommuneNummer'?: string | null;
    /**
     * Navn på kommunen som jaktfeltet ligger i
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'KommuneNavn'?: string | null;
    /**
     * Om området har jakt på elg
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'JaktPåElg'?: boolean;
    /**
     * Om området har jakt på hjort
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'JaktPåHjort'?: boolean;
    /**
     * Om området har jakt på rådyr
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'JaktPåRådyr'?: boolean;
    /**
     * Om området har jakt på villrein
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'JaktPåVillrein'?: boolean;
    /**
     * Om området har jakt på villsvin
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse
     */
    'JaktPåVillsvin'?: boolean;
}
/**
 * Informasjon om en kommune
 * @export
 * @interface HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
 */
export interface HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse {
    /**
     * Kommunenummer
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
     */
    'Kommunenummer'?: string | null;
    /**
     * Navnet på kommunen
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
     */
    'Navn'?: string | null;
    /**
     * Angir om kommunen er sammenslått
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
     */
    'Sammenslått'?: boolean;
    /**
     * Angir om kommunen er udefinert
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
     */
    'Udefinert'?: boolean;
    /**
     * Sammensetning av kommunenummer og kommunenavn
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse
     */
    'NavnMedKode'?: string | null;
}
/**
 * Informasjon om et vald, som brukes for å registrere skutte rådyr
 * @export
 * @interface HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
 */
export interface HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse {
    /**
     * Id for valdet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'Id'?: string | null;
    /**
     * Navn på valdet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'Navn'?: string | null;
    /**
     * Kommunenummer for kommunen som valdet ligger i
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'KommuneNummer'?: string | null;
    /**
     * Navn på kommunen som valdet ligger i
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'KommuneNavn'?: string | null;
    /**
     * Om området har jakt på elg
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'JaktPåElg'?: boolean;
    /**
     * Om området har jakt på hjort
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'JaktPåHjort'?: boolean;
    /**
     * Om området har jakt på rådyr
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'JaktPåRådyr'?: boolean;
    /**
     * Om området har jakt på villrein
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'JaktPåVillrein'?: boolean;
    /**
     * Om området har jakt på villsvin
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse
     */
    'JaktPåVillsvin'?: boolean;
}
/**
 * Informasjon om et villreinvald, som brukes for å registrere skutte villrein
 * @export
 * @interface HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
 */
export interface HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse {
    /**
     * Id for villreinvaldet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'Id'?: string | null;
    /**
     * Navnet på villreinvaldet
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'Navn'?: string | null;
    /**
     * ID for villreinområdet som villreinvaldet hører til
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'VillreinområdeId'?: string | null;
    /**
     * Navn på villreinområdet som villreinvaldet hører til
     * @type {string}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'VillreinområdeNavn'?: string | null;
    /**
     * Om området har jakt på elg
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'JaktPåElg'?: boolean;
    /**
     * Om området har jakt på hjort
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'JaktPåHjort'?: boolean;
    /**
     * Om området har jakt på rådyr
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'JaktPåRådyr'?: boolean;
    /**
     * Om området har jakt på villrein
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'JaktPåVillrein'?: boolean;
    /**
     * Om området har jakt på villsvin
     * @type {boolean}
     * @memberof HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse
     */
    'JaktPåVillsvin'?: boolean;
}
/**
 * 
 * @export
 * @interface MicrosoftAspNetCoreMvcProblemDetails
 */
export interface MicrosoftAspNetCoreMvcProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AutentiseringBrukerInnloggingApi - axios parameter creator
 * @export
 */
export const AutentiseringBrukerInnloggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du ha en bruker med rollen API-bruker.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og passord.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest} [hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest] Brukernavn og passord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BrukerauthLoginPost: async (hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest?: HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/brukerauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest] Brukernavn og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BrukerauthRenewPost: async (hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/brukerauth/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutentiseringBrukerInnloggingApi - functional programming interface
 * @export
 */
export const AutentiseringBrukerInnloggingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutentiseringBrukerInnloggingApiAxiosParamCreator(configuration)
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du ha en bruker med rollen API-bruker.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og passord.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest} [hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest] Brukernavn og passord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest?: HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutentiseringBrukerInnloggingApi.apiV1BrukerauthLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest] Brukernavn og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutentiseringBrukerInnloggingApi.apiV1BrukerauthRenewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AutentiseringBrukerInnloggingApi - factory interface
 * @export
 */
export const AutentiseringBrukerInnloggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutentiseringBrukerInnloggingApiFp(configuration)
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du ha en bruker med rollen API-bruker.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og passord.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest} [hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest] Brukernavn og passord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest?: HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse> {
            return localVarFp.apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest] Brukernavn og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse> {
            return localVarFp.apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutentiseringBrukerInnloggingApi - object-oriented interface
 * @export
 * @class AutentiseringBrukerInnloggingApi
 * @extends {BaseAPI}
 */
export class AutentiseringBrukerInnloggingApi extends BaseAPI {
    /**
     * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du ha en bruker med rollen API-bruker.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
     * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og passord.
     * @param {HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest} [hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest] Brukernavn og passord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutentiseringBrukerInnloggingApi
     */
    public apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest?: HjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options?: RawAxiosRequestConfig) {
        return AutentiseringBrukerInnloggingApiFp(this.configuration).apiV1BrukerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnBrukerLoggInnBrukerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
     * @summary Hent tokens (access- og refreshtoken) ved hjelp av brukernavn og refreshtoken.
     * @param {HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest] Brukernavn og refreshtoken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutentiseringBrukerInnloggingApi
     */
    public apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options?: RawAxiosRequestConfig) {
        return AutentiseringBrukerInnloggingApiFp(this.configuration).apiV1BrukerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenBrukerRefreshTokenBrukerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AutentiseringJegerInnloggingApi - axios parameter creator
 * @export
 */
export const AutentiseringJegerInnloggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du logge inn som jeger.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og fødselsdato.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest} [hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest] Jegernummer og fødselsdato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegerauthLoginPost: async (hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest?: HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jegerauth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest] Jegernummer og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegerauthRenewPost: async (hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jegerauth/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutentiseringJegerInnloggingApi - functional programming interface
 * @export
 */
export const AutentiseringJegerInnloggingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutentiseringJegerInnloggingApiAxiosParamCreator(configuration)
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du logge inn som jeger.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og fødselsdato.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest} [hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest] Jegernummer og fødselsdato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest?: HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutentiseringJegerInnloggingApi.apiV1JegerauthLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest] Jegernummer og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AutentiseringJegerInnloggingApi.apiV1JegerauthRenewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AutentiseringJegerInnloggingApi - factory interface
 * @export
 */
export const AutentiseringJegerInnloggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutentiseringJegerInnloggingApiFp(configuration)
    return {
        /**
         * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du logge inn som jeger.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og fødselsdato.
         * @param {HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest} [hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest] Jegernummer og fødselsdato
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest?: HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse> {
            return localVarFp.apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
         * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og refreshtoken.
         * @param {HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest] Jegernummer og refreshtoken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1AutentiseringSharedTokenResponse> {
            return localVarFp.apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutentiseringJegerInnloggingApi - object-oriented interface
 * @export
 * @class AutentiseringJegerInnloggingApi
 * @extends {BaseAPI}
 */
export class AutentiseringJegerInnloggingApi extends BaseAPI {
    /**
     * For å få tilgang til de adgangsbegrensede delene av hjorteviltregisterets API, må du logge inn som jeger.  Du får tilbake tokens (access- og refreshtoken), som deretter brukes for autentisering mot andre adgangsbegrensede endepunkt.                Accesstoken har normalt en gyldighet på 5 timer, mens refreshtoken har en gyldighet på 30 dager. I responsen får  du også timestamp for levetiden til tokenene.                For å autentisere deg, legger du til accesstoken du får i \"Authorization\"-headeren, på formatet \"Bearer ACCESSTOKEN\".                Når accesstoken er utgått må du enten autentisere på nytt med brukernavn og passord, eller bruke refreshtoken mot \"renew\"-endepunktet.
     * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og fødselsdato.
     * @param {HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest} [hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest] Jegernummer og fødselsdato
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutentiseringJegerInnloggingApi
     */
    public apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest?: HjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options?: RawAxiosRequestConfig) {
        return AutentiseringJegerInnloggingApiFp(this.configuration).apiV1JegerauthLoginPost(hjorteviltBrukereV1AutentiseringLoggInnJegerLoggInnJegerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For å fornye accesstoken, kan du sende inn refreshtoken og brukernavn for å få generert et nytt token-par.                Du får en ny accesstoken med fem timers varighet.    Du får også en ny refreshtoken, som må brukes ved neste forespørsel om accesstoken.
     * @summary Hent tokens (access- og refreshtoken) ved hjelp av jegernummer og refreshtoken.
     * @param {HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest} [hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest] Jegernummer og refreshtoken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutentiseringJegerInnloggingApi
     */
    public apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest?: HjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options?: RawAxiosRequestConfig) {
        return AutentiseringJegerInnloggingApiFp(this.configuration).apiV1JegerauthRenewPost(hjorteviltBrukereV1AutentiseringRefreshTokenJegerRefreshTokenJegerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IndividerSkutteDyrApi - axios parameter creator
 * @export
 */
export const IndividerSkutteDyrApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Slett eksisterende individ (skutt dyr).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1IndividerIdDelete', 'id', id)
            const localVarPath = `/api/v1/individer/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent individ (skutt dyr) basert på unik ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1IndividerIdGet', 'id', id)
            const localVarPath = `/api/v1/individer/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elg og hjort registreres på jaktfelt, rådyr registreres på vald og villrein registreres på villreinvald. For å  få lister over aktive IDer for områder, bruk egne endepunkt for å laste ned disse.                Elg og hjort kan hentes og redigeres på tvers av brukere og jegere. Det samme kan gjøres med rådyr nå i versjon 1 av api\'et, mens registreringer av villrein fortsatt er  personlige og du vil kun få tilbake liste over egne registreringer.                Elg og hjort har en referanse til en tilhørende jaktdag.
         * @summary Hent individer (skutte dyr) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} omrdeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerOmrdeIdSesongGet: async (sesong: number, omrdeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sesong' is not null or undefined
            assertParamExists('apiV1IndividerOmrdeIdSesongGet', 'sesong', sesong)
            // verify required parameter 'omrdeId' is not null or undefined
            assertParamExists('apiV1IndividerOmrdeIdSesongGet', 'omrdeId', omrdeId)
            const localVarPath = `/api/v1/individer/{OmrådeId}/{Sesong}`
                .replace(`{${"Sesong"}}`, encodeURIComponent(String(sesong)))
                .replace(`{${"OmrådeId"}}`, encodeURIComponent(String(omrdeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Merk at for elg og hjort må disse individene knyttes opp mot en jaktdag som allerede må finnes i løsningen.  Det er også krav til at jaktdagen er gyldig etter oppretting av nytt individ, for eksempel at det nye skutte  dyret må være sett.                Eksempel på forespørsel om å opprette en skutt elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"JaktdagId\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Alder\": \"Kalv\",          \"Kjønn\": \"Hann\",          \"Jaktfelt\": \"5001J0016\",          \"GevirtakkerVenstre\": 4,          \"GevirtakkerHøyre\": 2,          \"MålemetodeSlaktevekt\": \"Veid\",          \"SlaktevektKg\": 45,          \"Fellingssted\": \"Storsvaet\",          \"Koordinatfesting\": \"LengdeOgBreddegrad\",          \"KoordinatØst\": \"7.482371\",          \"KoordinatNord\": \"62.959503\"      }                Eksempel på forespørsel om å opprette et skutt rådyr:                    {          \"Id\": \"4fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"Dato\": \"2022-09-15\",          \"Art\": \"Rådyr\",          \"Alder\": \"Voksen\",          \"Kjønn\": \"Hann\",          \"Vald\": \"5001V0010\",          \"Fellingssted\": \"Storsvaet\"      }
         * @summary Opprett nytt individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest} [hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest] Se CreateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerPost: async (hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest?: HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/individer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Husk at du må sende all informasjon på nytt for å redigere et individ. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for individer for elg og rådyr under POST-dokumentasjonen.
         * @summary Redigerer eksisterende individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest} [hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest] Se UpdateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerPut: async (hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest?: HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/individer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndividerSkutteDyrApi - functional programming interface
 * @export
 */
export const IndividerSkutteDyrApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndividerSkutteDyrApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Slett eksisterende individ (skutt dyr).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IndividerIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IndividerIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndividerSkutteDyrApi.apiV1IndividerIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent individ (skutt dyr) basert på unik ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IndividerIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltJaktdataV1IndividSharedIndividResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IndividerIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndividerSkutteDyrApi.apiV1IndividerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Elg og hjort registreres på jaktfelt, rådyr registreres på vald og villrein registreres på villreinvald. For å  få lister over aktive IDer for områder, bruk egne endepunkt for å laste ned disse.                Elg og hjort kan hentes og redigeres på tvers av brukere og jegere. Det samme kan gjøres med rådyr nå i versjon 1 av api\'et, mens registreringer av villrein fortsatt er  personlige og du vil kun få tilbake liste over egne registreringer.                Elg og hjort har en referanse til en tilhørende jaktdag.
         * @summary Hent individer (skutte dyr) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} omrdeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IndividerOmrdeIdSesongGet(sesong: number, omrdeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltJaktdataV1IndividSharedIndividResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IndividerOmrdeIdSesongGet(sesong, omrdeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndividerSkutteDyrApi.apiV1IndividerOmrdeIdSesongGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Merk at for elg og hjort må disse individene knyttes opp mot en jaktdag som allerede må finnes i løsningen.  Det er også krav til at jaktdagen er gyldig etter oppretting av nytt individ, for eksempel at det nye skutte  dyret må være sett.                Eksempel på forespørsel om å opprette en skutt elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"JaktdagId\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Alder\": \"Kalv\",          \"Kjønn\": \"Hann\",          \"Jaktfelt\": \"5001J0016\",          \"GevirtakkerVenstre\": 4,          \"GevirtakkerHøyre\": 2,          \"MålemetodeSlaktevekt\": \"Veid\",          \"SlaktevektKg\": 45,          \"Fellingssted\": \"Storsvaet\",          \"Koordinatfesting\": \"LengdeOgBreddegrad\",          \"KoordinatØst\": \"7.482371\",          \"KoordinatNord\": \"62.959503\"      }                Eksempel på forespørsel om å opprette et skutt rådyr:                    {          \"Id\": \"4fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"Dato\": \"2022-09-15\",          \"Art\": \"Rådyr\",          \"Alder\": \"Voksen\",          \"Kjønn\": \"Hann\",          \"Vald\": \"5001V0010\",          \"Fellingssted\": \"Storsvaet\"      }
         * @summary Opprett nytt individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest} [hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest] Se CreateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest?: HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndividerSkutteDyrApi.apiV1IndividerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Husk at du må sende all informasjon på nytt for å redigere et individ. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for individer for elg og rådyr under POST-dokumentasjonen.
         * @summary Redigerer eksisterende individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest} [hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest] Se UpdateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest?: HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IndividerSkutteDyrApi.apiV1IndividerPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IndividerSkutteDyrApi - factory interface
 * @export
 */
export const IndividerSkutteDyrApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndividerSkutteDyrApiFp(configuration)
    return {
        /**
         * 
         * @summary Slett eksisterende individ (skutt dyr).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1IndividerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent individ (skutt dyr) basert på unik ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltJaktdataV1IndividSharedIndividResponse> {
            return localVarFp.apiV1IndividerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elg og hjort registreres på jaktfelt, rådyr registreres på vald og villrein registreres på villreinvald. For å  få lister over aktive IDer for områder, bruk egne endepunkt for å laste ned disse.                Elg og hjort kan hentes og redigeres på tvers av brukere og jegere. Det samme kan gjøres med rådyr nå i versjon 1 av api\'et, mens registreringer av villrein fortsatt er  personlige og du vil kun få tilbake liste over egne registreringer.                Elg og hjort har en referanse til en tilhørende jaktdag.
         * @summary Hent individer (skutte dyr) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} omrdeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerOmrdeIdSesongGet(sesong: number, omrdeId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltJaktdataV1IndividSharedIndividResponse>> {
            return localVarFp.apiV1IndividerOmrdeIdSesongGet(sesong, omrdeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Merk at for elg og hjort må disse individene knyttes opp mot en jaktdag som allerede må finnes i løsningen.  Det er også krav til at jaktdagen er gyldig etter oppretting av nytt individ, for eksempel at det nye skutte  dyret må være sett.                Eksempel på forespørsel om å opprette en skutt elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"JaktdagId\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Alder\": \"Kalv\",          \"Kjønn\": \"Hann\",          \"Jaktfelt\": \"5001J0016\",          \"GevirtakkerVenstre\": 4,          \"GevirtakkerHøyre\": 2,          \"MålemetodeSlaktevekt\": \"Veid\",          \"SlaktevektKg\": 45,          \"Fellingssted\": \"Storsvaet\",          \"Koordinatfesting\": \"LengdeOgBreddegrad\",          \"KoordinatØst\": \"7.482371\",          \"KoordinatNord\": \"62.959503\"      }                Eksempel på forespørsel om å opprette et skutt rådyr:                    {          \"Id\": \"4fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"Dato\": \"2022-09-15\",          \"Art\": \"Rådyr\",          \"Alder\": \"Voksen\",          \"Kjønn\": \"Hann\",          \"Vald\": \"5001V0010\",          \"Fellingssted\": \"Storsvaet\"      }
         * @summary Opprett nytt individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest} [hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest] Se CreateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest?: HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Husk at du må sende all informasjon på nytt for å redigere et individ. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for individer for elg og rådyr under POST-dokumentasjonen.
         * @summary Redigerer eksisterende individ (skutt dyr).
         * @param {HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest} [hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest] Se UpdateIndividRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest?: HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IndividerSkutteDyrApi - object-oriented interface
 * @export
 * @class IndividerSkutteDyrApi
 * @extends {BaseAPI}
 */
export class IndividerSkutteDyrApi extends BaseAPI {
    /**
     * 
     * @summary Slett eksisterende individ (skutt dyr).
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividerSkutteDyrApi
     */
    public apiV1IndividerIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return IndividerSkutteDyrApiFp(this.configuration).apiV1IndividerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent individ (skutt dyr) basert på unik ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividerSkutteDyrApi
     */
    public apiV1IndividerIdGet(id: string, options?: RawAxiosRequestConfig) {
        return IndividerSkutteDyrApiFp(this.configuration).apiV1IndividerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elg og hjort registreres på jaktfelt, rådyr registreres på vald og villrein registreres på villreinvald. For å  få lister over aktive IDer for områder, bruk egne endepunkt for å laste ned disse.                Elg og hjort kan hentes og redigeres på tvers av brukere og jegere. Det samme kan gjøres med rådyr nå i versjon 1 av api\'et, mens registreringer av villrein fortsatt er  personlige og du vil kun få tilbake liste over egne registreringer.                Elg og hjort har en referanse til en tilhørende jaktdag.
     * @summary Hent individer (skutte dyr) for område og jaktsesong.
     * @param {number} sesong 
     * @param {string} omrdeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividerSkutteDyrApi
     */
    public apiV1IndividerOmrdeIdSesongGet(sesong: number, omrdeId: string, options?: RawAxiosRequestConfig) {
        return IndividerSkutteDyrApiFp(this.configuration).apiV1IndividerOmrdeIdSesongGet(sesong, omrdeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Merk at for elg og hjort må disse individene knyttes opp mot en jaktdag som allerede må finnes i løsningen.  Det er også krav til at jaktdagen er gyldig etter oppretting av nytt individ, for eksempel at det nye skutte  dyret må være sett.                Eksempel på forespørsel om å opprette en skutt elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"JaktdagId\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Alder\": \"Kalv\",          \"Kjønn\": \"Hann\",          \"Jaktfelt\": \"5001J0016\",          \"GevirtakkerVenstre\": 4,          \"GevirtakkerHøyre\": 2,          \"MålemetodeSlaktevekt\": \"Veid\",          \"SlaktevektKg\": 45,          \"Fellingssted\": \"Storsvaet\",          \"Koordinatfesting\": \"LengdeOgBreddegrad\",          \"KoordinatØst\": \"7.482371\",          \"KoordinatNord\": \"62.959503\"      }                Eksempel på forespørsel om å opprette et skutt rådyr:                    {          \"Id\": \"4fa85f64-5717-4562-b3fc-2c963f66afa6\",          \"Dato\": \"2022-09-15\",          \"Art\": \"Rådyr\",          \"Alder\": \"Voksen\",          \"Kjønn\": \"Hann\",          \"Vald\": \"5001V0010\",          \"Fellingssted\": \"Storsvaet\"      }
     * @summary Opprett nytt individ (skutt dyr).
     * @param {HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest} [hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest] Se CreateIndividRequest for utforming og krav
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividerSkutteDyrApi
     */
    public apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest?: HjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options?: RawAxiosRequestConfig) {
        return IndividerSkutteDyrApiFp(this.configuration).apiV1IndividerPost(hjorteviltJaktdataV1IndividCreateIndividCreateIndividRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Husk at du må sende all informasjon på nytt for å redigere et individ. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for individer for elg og rådyr under POST-dokumentasjonen.
     * @summary Redigerer eksisterende individ (skutt dyr).
     * @param {HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest} [hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest] Se UpdateIndividRequest for utforming og krav
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividerSkutteDyrApi
     */
    public apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest?: HjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options?: RawAxiosRequestConfig) {
        return IndividerSkutteDyrApiFp(this.configuration).apiV1IndividerPut(hjorteviltJaktdataV1IndividUpdateIndividUpdateIndividRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JaktdagerSetteDyrApi - axios parameter creator
 * @export
 */
export const JaktdagerSetteDyrApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Slett eksisterende jaktdag.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1JaktdagerIdDelete', 'id', id)
            const localVarPath = `/api/v1/jaktdager/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent jaktdag basert på unik ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1JaktdagerIdGet', 'id', id)
            const localVarPath = `/api/v1/jaktdager/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Brukes bare for elg og hjort.  Jaktdag omtales også noen ganger som sett-skjema, og inneholder informasjon om jaktinnsats og sette dyr.  Skutte dyr (individ) knyttes opp til en jaktdag.                Jaktdager og individ for elg og hjort er synlige og redigerbare på tvers av alle brukere og jegere.
         * @summary Hent jaktdager (elg og hjort) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} jaktfeltId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerJaktfeltIdSesongGet: async (sesong: number, jaktfeltId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sesong' is not null or undefined
            assertParamExists('apiV1JaktdagerJaktfeltIdSesongGet', 'sesong', sesong)
            // verify required parameter 'jaktfeltId' is not null or undefined
            assertParamExists('apiV1JaktdagerJaktfeltIdSesongGet', 'jaktfeltId', jaktfeltId)
            const localVarPath = `/api/v1/jaktdager/{JaktfeltId}/{Sesong}`
                .replace(`{${"Sesong"}}`, encodeURIComponent(String(sesong)))
                .replace(`{${"JaktfeltId"}}`, encodeURIComponent(String(jaktfeltId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Slett eksisterende jaktdag og tilhørende individer.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1JaktdagerMedindividIdDelete', 'id', id)
            const localVarPath = `/api/v1/jaktdager/medindivid/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opprett ny jaktdag, og send med tilhørende skutte dyr i samme request.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest] Se CreateJaktdagMedIndividRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividPost: async (hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktdager/medindivid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividPut: async (hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktdager/medindivid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Det er ikke anledning til sende inn skutte dyr gjennom dette endepunktet.  Det må gjøres mot egne endepunkt etter at jaktdagen er opprettet.                Eksempel på forespørsel om å opprette en jaktdag for elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Jaktfelt\": \"5001J0016\",          \"AntallTimerJaktet\": 4,          \"AntallJegere\": 8,          \"SettOkseEllerBukk\": 1,          \"SettKyrUtenKalvEllerKolle\": 0,          \"SettKyrMed1Kalv\": 1,          \"SettKyrMed2EllerFlereKalver\": 1,          \"SettKalverAlene\": 0,          \"SettUkjente\": 2      }                Eksempel på forespørsel om å opprette en jaktdag for hjort:                    {          \"Id\": \"3fa85f64-5718-4562-b3fc-2c963f63afa6\",          \"Dato\": \"2022-09-25\",          \"Art\": \"Hjort\",          \"Jaktfelt\": \"5001J0016\",          \"Utmark\": true,          \"AntallTimerJaktet\": 5,          \"AntallJegere\": 4,          \"SettSpissbukk\": 1,          \"SettOkseEllerBukk\": 2,          \"SettKyrUtenKalvEllerKolle\": 1,          \"SettKalverAlene\": 2,          \"SettUkjente\": 1,          \"Innmark\": false      }
         * @summary Opprett ny jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest] Se CreateJaktdagRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerPost: async (hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktdager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endring av dato og jaktfelt vil gi samme endring på jaktdagens skutte dyr.    Husk at du må sende all informasjon på nytt for å redigere jaktdagen. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for jaktdager for elg og hjort under POST-dokumentasjonen.
         * @summary Rediger eksisterende jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest] Se UpdateJaktdagRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerPut: async (hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktdager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JaktdagerSetteDyrApi - functional programming interface
 * @export
 */
export const JaktdagerSetteDyrApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JaktdagerSetteDyrApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Slett eksisterende jaktdag.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent jaktdag basert på unik ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltJaktdataV1JaktdagSharedJaktdagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Brukes bare for elg og hjort.  Jaktdag omtales også noen ganger som sett-skjema, og inneholder informasjon om jaktinnsats og sette dyr.  Skutte dyr (individ) knyttes opp til en jaktdag.                Jaktdager og individ for elg og hjort er synlige og redigerbare på tvers av alle brukere og jegere.
         * @summary Hent jaktdager (elg og hjort) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} jaktfeltId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerJaktfeltIdSesongGet(sesong: number, jaktfeltId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltJaktdataV1JaktdagSharedJaktdagResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerJaktfeltIdSesongGet(sesong, jaktfeltId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerJaktfeltIdSesongGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Slett eksisterende jaktdag og tilhørende individer.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerMedindividIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerMedindividIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerMedindividIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Opprett ny jaktdag, og send med tilhørende skutte dyr i samme request.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest] Se CreateJaktdagMedIndividRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerMedindividPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerMedindividPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Det er ikke anledning til sende inn skutte dyr gjennom dette endepunktet.  Det må gjøres mot egne endepunkt etter at jaktdagen er opprettet.                Eksempel på forespørsel om å opprette en jaktdag for elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Jaktfelt\": \"5001J0016\",          \"AntallTimerJaktet\": 4,          \"AntallJegere\": 8,          \"SettOkseEllerBukk\": 1,          \"SettKyrUtenKalvEllerKolle\": 0,          \"SettKyrMed1Kalv\": 1,          \"SettKyrMed2EllerFlereKalver\": 1,          \"SettKalverAlene\": 0,          \"SettUkjente\": 2      }                Eksempel på forespørsel om å opprette en jaktdag for hjort:                    {          \"Id\": \"3fa85f64-5718-4562-b3fc-2c963f63afa6\",          \"Dato\": \"2022-09-25\",          \"Art\": \"Hjort\",          \"Jaktfelt\": \"5001J0016\",          \"Utmark\": true,          \"AntallTimerJaktet\": 5,          \"AntallJegere\": 4,          \"SettSpissbukk\": 1,          \"SettOkseEllerBukk\": 2,          \"SettKyrUtenKalvEllerKolle\": 1,          \"SettKalverAlene\": 2,          \"SettUkjente\": 1,          \"Innmark\": false      }
         * @summary Opprett ny jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest] Se CreateJaktdagRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endring av dato og jaktfelt vil gi samme endring på jaktdagens skutte dyr.    Husk at du må sende all informasjon på nytt for å redigere jaktdagen. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for jaktdager for elg og hjort under POST-dokumentasjonen.
         * @summary Rediger eksisterende jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest] Se UpdateJaktdagRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktdagerSetteDyrApi.apiV1JaktdagerPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JaktdagerSetteDyrApi - factory interface
 * @export
 */
export const JaktdagerSetteDyrApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JaktdagerSetteDyrApiFp(configuration)
    return {
        /**
         * 
         * @summary Slett eksisterende jaktdag.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1JaktdagerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent jaktdag basert på unik ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltJaktdataV1JaktdagSharedJaktdagResponse> {
            return localVarFp.apiV1JaktdagerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Brukes bare for elg og hjort.  Jaktdag omtales også noen ganger som sett-skjema, og inneholder informasjon om jaktinnsats og sette dyr.  Skutte dyr (individ) knyttes opp til en jaktdag.                Jaktdager og individ for elg og hjort er synlige og redigerbare på tvers av alle brukere og jegere.
         * @summary Hent jaktdager (elg og hjort) for område og jaktsesong.
         * @param {number} sesong 
         * @param {string} jaktfeltId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerJaktfeltIdSesongGet(sesong: number, jaktfeltId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltJaktdataV1JaktdagSharedJaktdagResponse>> {
            return localVarFp.apiV1JaktdagerJaktfeltIdSesongGet(sesong, jaktfeltId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Slett eksisterende jaktdag og tilhørende individer.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1JaktdagerMedindividIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Opprett ny jaktdag, og send med tilhørende skutte dyr i samme request.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest] Se CreateJaktdagMedIndividRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Det er ikke anledning til sende inn skutte dyr gjennom dette endepunktet.  Det må gjøres mot egne endepunkt etter at jaktdagen er opprettet.                Eksempel på forespørsel om å opprette en jaktdag for elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Jaktfelt\": \"5001J0016\",          \"AntallTimerJaktet\": 4,          \"AntallJegere\": 8,          \"SettOkseEllerBukk\": 1,          \"SettKyrUtenKalvEllerKolle\": 0,          \"SettKyrMed1Kalv\": 1,          \"SettKyrMed2EllerFlereKalver\": 1,          \"SettKalverAlene\": 0,          \"SettUkjente\": 2      }                Eksempel på forespørsel om å opprette en jaktdag for hjort:                    {          \"Id\": \"3fa85f64-5718-4562-b3fc-2c963f63afa6\",          \"Dato\": \"2022-09-25\",          \"Art\": \"Hjort\",          \"Jaktfelt\": \"5001J0016\",          \"Utmark\": true,          \"AntallTimerJaktet\": 5,          \"AntallJegere\": 4,          \"SettSpissbukk\": 1,          \"SettOkseEllerBukk\": 2,          \"SettKyrUtenKalvEllerKolle\": 1,          \"SettKalverAlene\": 2,          \"SettUkjente\": 1,          \"Innmark\": false      }
         * @summary Opprett ny jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest] Se CreateJaktdagRequest for utform og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endring av dato og jaktfelt vil gi samme endring på jaktdagens skutte dyr.    Husk at du må sende all informasjon på nytt for å redigere jaktdagen. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for jaktdager for elg og hjort under POST-dokumentasjonen.
         * @summary Rediger eksisterende jaktdag.
         * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest] Se UpdateJaktdagRequest for utforming og krav
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JaktdagerSetteDyrApi - object-oriented interface
 * @export
 * @class JaktdagerSetteDyrApi
 * @extends {BaseAPI}
 */
export class JaktdagerSetteDyrApi extends BaseAPI {
    /**
     * 
     * @summary Slett eksisterende jaktdag.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent jaktdag basert på unik ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerIdGet(id: string, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Brukes bare for elg og hjort.  Jaktdag omtales også noen ganger som sett-skjema, og inneholder informasjon om jaktinnsats og sette dyr.  Skutte dyr (individ) knyttes opp til en jaktdag.                Jaktdager og individ for elg og hjort er synlige og redigerbare på tvers av alle brukere og jegere.
     * @summary Hent jaktdager (elg og hjort) for område og jaktsesong.
     * @param {number} sesong 
     * @param {string} jaktfeltId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerJaktfeltIdSesongGet(sesong: number, jaktfeltId: string, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerJaktfeltIdSesongGet(sesong, jaktfeltId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Slett eksisterende jaktdag og tilhørende individer.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerMedindividIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerMedindividIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Opprett ny jaktdag, og send med tilhørende skutte dyr i samme request.
     * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest] Se CreateJaktdagMedIndividRequest for utform og krav
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerMedindividPost(hjorteviltJaktdataV1JaktdagCreateJaktdagMedIndividCreateJaktdagMedIndividRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerMedindividPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagMedIndividUpdateJaktdagMedIndividRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Det er ikke anledning til sende inn skutte dyr gjennom dette endepunktet.  Det må gjøres mot egne endepunkt etter at jaktdagen er opprettet.                Eksempel på forespørsel om å opprette en jaktdag for elg:                    {          \"Id\": \"3fa85f64-5717-4562-b3fc-2c963f66aff8\",          \"Dato\": \"2022-10-05\",          \"Art\": \"Elg\",          \"Jaktfelt\": \"5001J0016\",          \"AntallTimerJaktet\": 4,          \"AntallJegere\": 8,          \"SettOkseEllerBukk\": 1,          \"SettKyrUtenKalvEllerKolle\": 0,          \"SettKyrMed1Kalv\": 1,          \"SettKyrMed2EllerFlereKalver\": 1,          \"SettKalverAlene\": 0,          \"SettUkjente\": 2      }                Eksempel på forespørsel om å opprette en jaktdag for hjort:                    {          \"Id\": \"3fa85f64-5718-4562-b3fc-2c963f63afa6\",          \"Dato\": \"2022-09-25\",          \"Art\": \"Hjort\",          \"Jaktfelt\": \"5001J0016\",          \"Utmark\": true,          \"AntallTimerJaktet\": 5,          \"AntallJegere\": 4,          \"SettSpissbukk\": 1,          \"SettOkseEllerBukk\": 2,          \"SettKyrUtenKalvEllerKolle\": 1,          \"SettKalverAlene\": 2,          \"SettUkjente\": 1,          \"Innmark\": false      }
     * @summary Opprett ny jaktdag.
     * @param {HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest} [hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest] Se CreateJaktdagRequest for utform og krav
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest?: HjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerPost(hjorteviltJaktdataV1JaktdagCreateJaktdagCreateJaktdagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endring av dato og jaktfelt vil gi samme endring på jaktdagens skutte dyr.    Husk at du må sende all informasjon på nytt for å redigere jaktdagen. Vi vurderer å implementere et eget PATCH-endepunkt for å  ha mulighet til å endre enkelte felter. Gi oss beskjed hvis dette er ønskelig.                Se eksempler på requester for jaktdager for elg og hjort under POST-dokumentasjonen.
     * @summary Rediger eksisterende jaktdag.
     * @param {HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest} [hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest] Se UpdateJaktdagRequest for utforming og krav
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktdagerSetteDyrApi
     */
    public apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest?: HjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options?: RawAxiosRequestConfig) {
        return JaktdagerSetteDyrApiFp(this.configuration).apiV1JaktdagerPut(hjorteviltJaktdataV1JaktdagUpdateJaktdagUpdateJaktdagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JaktomraderJegerApi - axios parameter creator
 * @export
 */
export const JaktomraderJegerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Slett jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest} [hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderDelete: async (hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest?: HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktområder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent jaktområder for innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktområder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Legg til jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest} [hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderPost: async (hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest?: HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jaktområder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JaktomraderJegerApi - functional programming interface
 * @export
 */
export const JaktomraderJegerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JaktomraderJegerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Slett jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest} [hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest?: HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktomraderJegerApi.apiV1JaktomrderDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent jaktområder for innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktomrderGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktomrderGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktomraderJegerApi.apiV1JaktomrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Legg til jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest} [hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest?: HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JaktomraderJegerApi.apiV1JaktomrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JaktomraderJegerApi - factory interface
 * @export
 */
export const JaktomraderJegerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JaktomraderJegerApiFp(configuration)
    return {
        /**
         * 
         * @summary Slett jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest} [hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest?: HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent jaktområder for innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse>> {
            return localVarFp.apiV1JaktomrderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Legg til jaktområde for innlogget jeger.
         * @param {HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest} [hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest?: HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1JaktomraderSharedJaktomradeResponse> {
            return localVarFp.apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JaktomraderJegerApi - object-oriented interface
 * @export
 * @class JaktomraderJegerApi
 * @extends {BaseAPI}
 */
export class JaktomraderJegerApi extends BaseAPI {
    /**
     * 
     * @summary Slett jaktområde for innlogget jeger.
     * @param {HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest} [hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktomraderJegerApi
     */
    public apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest?: HjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options?: RawAxiosRequestConfig) {
        return JaktomraderJegerApiFp(this.configuration).apiV1JaktomrderDelete(hjorteviltBrukereV1JaktomraderDeleteJaktomradeDeleteJaktomradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent jaktområder for innlogget jeger.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktomraderJegerApi
     */
    public apiV1JaktomrderGet(options?: RawAxiosRequestConfig) {
        return JaktomraderJegerApiFp(this.configuration).apiV1JaktomrderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Legg til jaktområde for innlogget jeger.
     * @param {HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest} [hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JaktomraderJegerApi
     */
    public apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest?: HjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options?: RawAxiosRequestConfig) {
        return JaktomraderJegerApiFp(this.configuration).apiV1JaktomrderPost(hjorteviltBrukereV1JaktomraderCreateJaktomradeCreateJaktomradeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OmrdeListerApi - axios parameter creator
 * @export
 */
export const OmrdeListerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hent liste over alle aktive jaktfelt. Brukes for å registrere elg og hjort.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderJaktfeltGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/områder/jaktfelt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent liste over alle kommuner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderKommunerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/områder/kommuner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent liste over alle aktive vald. Brukes for å registrere rådyr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderValdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/områder/vald`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hent liste over alle aktive villreinvald. Brukes for å registrere villrein.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderVillreinvaldGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/områder/villreinvald`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OmrdeListerApi - functional programming interface
 * @export
 */
export const OmrdeListerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OmrdeListerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hent liste over alle aktive jaktfelt. Brukes for å registrere elg og hjort.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OmrderJaktfeltGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OmrderJaktfeltGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OmrdeListerApi.apiV1OmrderJaktfeltGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent liste over alle kommuner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OmrderKommunerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OmrderKommunerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OmrdeListerApi.apiV1OmrderKommunerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent liste over alle aktive vald. Brukes for å registrere rådyr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OmrderValdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OmrderValdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OmrdeListerApi.apiV1OmrderValdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hent liste over alle aktive villreinvald. Brukes for å registrere villrein.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OmrderVillreinvaldGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OmrderVillreinvaldGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OmrdeListerApi.apiV1OmrderVillreinvaldGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OmrdeListerApi - factory interface
 * @export
 */
export const OmrdeListerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OmrdeListerApiFp(configuration)
    return {
        /**
         * 
         * @summary Hent liste over alle aktive jaktfelt. Brukes for å registrere elg og hjort.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderJaktfeltGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleJaktfeltJaktfeltResponse>> {
            return localVarFp.apiV1OmrderJaktfeltGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent liste over alle kommuner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderKommunerGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleKommunerKommuneResponse>> {
            return localVarFp.apiV1OmrderKommunerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent liste over alle aktive vald. Brukes for å registrere rådyr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderValdGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleValdValdResponse>> {
            return localVarFp.apiV1OmrderValdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hent liste over alle aktive villreinvald. Brukes for å registrere villrein.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OmrderVillreinvaldGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<HjorteviltOmraderV1OmradeListerGetAlleVillreinvaldVillreinvaldResponse>> {
            return localVarFp.apiV1OmrderVillreinvaldGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OmrdeListerApi - object-oriented interface
 * @export
 * @class OmrdeListerApi
 * @extends {BaseAPI}
 */
export class OmrdeListerApi extends BaseAPI {
    /**
     * 
     * @summary Hent liste over alle aktive jaktfelt. Brukes for å registrere elg og hjort.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmrdeListerApi
     */
    public apiV1OmrderJaktfeltGet(options?: RawAxiosRequestConfig) {
        return OmrdeListerApiFp(this.configuration).apiV1OmrderJaktfeltGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent liste over alle kommuner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmrdeListerApi
     */
    public apiV1OmrderKommunerGet(options?: RawAxiosRequestConfig) {
        return OmrdeListerApiFp(this.configuration).apiV1OmrderKommunerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent liste over alle aktive vald. Brukes for å registrere rådyr.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmrdeListerApi
     */
    public apiV1OmrderValdGet(options?: RawAxiosRequestConfig) {
        return OmrdeListerApiFp(this.configuration).apiV1OmrderValdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hent liste over alle aktive villreinvald. Brukes for å registrere villrein.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmrdeListerApi
     */
    public apiV1OmrderVillreinvaldGet(options?: RawAxiosRequestConfig) {
        return OmrdeListerApiFp(this.configuration).apiV1OmrderVillreinvaldGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpplysningerJegerApi - axios parameter creator
 * @export
 */
export const OpplysningerJegerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Hent informasjon om innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegeropplysningerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jegeropplysninger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endre informasjon om innlogget jeger.
         * @param {HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest} [hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegeropplysningerPut: async (hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest?: HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jegeropplysninger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpplysningerJegerApi - functional programming interface
 * @export
 */
export const OpplysningerJegerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpplysningerJegerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Hent informasjon om innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JegeropplysningerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JegeropplysningerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpplysningerJegerApi.apiV1JegeropplysningerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Endre informasjon om innlogget jeger.
         * @param {HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest} [hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest?: HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OpplysningerJegerApi.apiV1JegeropplysningerPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OpplysningerJegerApi - factory interface
 * @export
 */
export const OpplysningerJegerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpplysningerJegerApiFp(configuration)
    return {
        /**
         * 
         * @summary Hent informasjon om innlogget jeger.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegeropplysningerGet(options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse> {
            return localVarFp.apiV1JegeropplysningerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endre informasjon om innlogget jeger.
         * @param {HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest} [hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest?: HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options?: RawAxiosRequestConfig): AxiosPromise<HjorteviltBrukereV1JegerOpplysningerSharedJegerOpplysningerResponse> {
            return localVarFp.apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpplysningerJegerApi - object-oriented interface
 * @export
 * @class OpplysningerJegerApi
 * @extends {BaseAPI}
 */
export class OpplysningerJegerApi extends BaseAPI {
    /**
     * 
     * @summary Hent informasjon om innlogget jeger.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpplysningerJegerApi
     */
    public apiV1JegeropplysningerGet(options?: RawAxiosRequestConfig) {
        return OpplysningerJegerApiFp(this.configuration).apiV1JegeropplysningerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endre informasjon om innlogget jeger.
     * @param {HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest} [hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpplysningerJegerApi
     */
    public apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest?: HjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options?: RawAxiosRequestConfig) {
        return OpplysningerJegerApiFp(this.configuration).apiV1JegeropplysningerPut(hjorteviltBrukereV1JegerOpplysningerUpdateJegerOpplysningerUpdateJegerOpplysningerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



