import Warning from "@mui/icons-material/Warning";

export default function ErrorAlert({ text }: { text: string }): JSX.Element {
  return (
    <div className="bg-md-error-red-light border rounded border-md-error-red m-5 py-2 px-3 flex items-center">
      <Warning className="text-md-error-red mr-3" />
      {text}
    </div>
  );
}
