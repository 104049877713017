import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "~/src/components/Form/Checkbox";
import Text from "~/src/components/Text/Text";

type VelgTerrengProps = {
  innmark: boolean;
  utmark: boolean;
  setInnmark: (value: boolean) => void;
  setUtmark: (value: boolean) => void;
  errors: boolean;
};

const VelgTerreng = ({ innmark, utmark, setInnmark, setUtmark, errors }: VelgTerrengProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col py-4">
      <div className="flex flex-col gap-2">
        <Text variant="h6">{t("pages.registrerArt.hjort.velgTerreng")}</Text>
        <Text
          variant="small"
          className={`${errors && !innmark && !utmark ? "text-md-error-red font-semibold" : ""}`}
        >
          {t("pages.registrerArt.hjort.terrengHelpMessage")}
        </Text>
      </div>
      <div className="flex flex-row gap-10">
        <Checkbox
          label={t("pages.fellingsinfo.innmark")}
          checked={!!innmark}
          onChange={(e) => {
            setInnmark(Boolean(e.target.checked));
          }}
        />
        <Checkbox
          label={t("pages.fellingsinfo.utmark")}
          checked={!!utmark}
          onChange={(e) => {
            setUtmark(Boolean(e.target.checked));
          }}
        />
      </div>
    </div>
  );
};

export default VelgTerreng;
