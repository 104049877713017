import { Link } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorList from "~/src/components/Form/ErrorList";
import { StorageContext, StorageItem } from "~/src/contexts/StorageContext/StorageProvider";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { ApiError, Individ, IndividResponse, Område } from "../../api/types";
import { jaktdataApi } from "../../appGlobals";
import AlertDefault, { AlertVariant } from "../../components/Alerts/AlertDefault";
import GodkjentInfoBox from "../../components/Alerts/GodkjentInfoBox";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";
import PageTitle from "../../components/PageTitle";
import ShowMoreToggle from "../../components/ShowMoreToggle";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import { getKjonnOgAlderByArt } from "../../helpers/artHelper";
import { getOmradeForIndivid } from "../../helpers/omradeHelper";
import { toStringWithFullMonth } from "../../helpers/time";
import { AlderType, ArtType, KjønnType } from "../../helpers/types";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useStateful } from "../../hooks/useStateful";
import { getJaktdagQuery, getSkuttDyrQuery } from "../../react-query/queries";
import { MalemetodeSlaktevekt } from "../Registrering/types";
import Field from "./Components/Field";
import OmradeBanner from "./Components/OmradeBanner";
import { getReadableEnumLowercase, isMissingOvervakData } from "./helpers";

export default function SkuttDyrDetaljer(): JSX.Element {
  const tBase = "pages.oversikt.skuttDyrDetaljer.";
  const { individId } = useParams();
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const queryClient = useQueryClient();
  const ui = useContext(UIContext);
  const omrade = useStateful<Område | null | undefined>(null);
  const isRegByOther = useStateful(false);
  const canEditIndivid = useStateful(false);
  const ikkeVeid = `${t("pages.registrerArt.omSkuttDyr.ikkeVeid")}`;
  const { showSnackbar } = useContext(UIContext);
  const storage = useContext(StorageContext);
  const [skuttDyr, setSkuttDyr] = useState<IndividResponse | null | undefined>(null);
  const [storedIndivid, setStoredIndivid] = useState<StorageItem<Individ> | null | undefined>(null);

  const skuttDyrResult = useQuery({
    queryKey: ["individId-" + individId, { individId: individId }],
    queryFn: getSkuttDyrQuery,
    staleTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (appData.didTrySyncWithItems.value) {
      skuttDyrResult.refetch();
    }
  }, [appData.didTrySyncWithItems.value]);

  useEffect(() => {
    // use stored individ if it exists
    // (newly created individ only exists in storage, and edited should use stored even if API is available)
    const storedIndivid = storage.get<Individ>({ id: individId }, "Individ");

    // need to show sync status for all operation types, but only set skuttDyr if not delete, because stored object only contains id
    if (storedIndivid) setStoredIndivid(storedIndivid);
    if (storedIndivid && storedIndivid.operation !== "delete") {
      setSkuttDyr(storedIndivid?.data);
    } else if (skuttDyrResult.isSuccess && typeof skuttDyrResult.data !== "string") {
      setSkuttDyr(skuttDyrResult.data);
      setStoredIndivid(undefined);
    }
  }, [skuttDyrResult.data, appData.isOnline.value, appData.isSynced.value]);

  // not implementing usage of offline storage for now here,
  // jaktdag is only used to check if it's approved, which it won't be if it's in storage queue
  const jaktdag = useQuery({
    queryKey: ["jaktdag", { jaktdagId: skuttDyr?.JaktdagId }],
    queryFn: getJaktdagQuery,
    staleTime: 0,
  }).data;

  useEffect(() => {
    if (!skuttDyr) return;

    omrade.set(getOmradeForIndivid(skuttDyr, appData));
    isRegByOther.set(appData.jegeropplysninger.data?.Jegernummer !== skuttDyr?.Jegernummer);
    canEditIndivid.set(!skuttDyr?.Godkjent);
  }, [skuttDyr]);

  const handleDeleteClick = () => {
    const regByOtherText = t(tBase + "deleteDialogMessageRegByOther").replace(
      "{{name}}",
      skuttDyr?.RegistrertAv ?? "",
    );
    const hasCWDProvesvar =
      skuttDyr?.CWDPrøvesvar === "Positiv" || skuttDyr?.CWDPrøvesvar === "Negativ";
    deleteDialog.show({
      message: hasCWDProvesvar
        ? t(tBase + "deleteDialogMessageCWD")
        : isRegByOther.value
          ? regByOtherText
          : t(tBase + "deleteDialogMessage"),
    });
  };
  const handleConfirmDelete = () => {
    if (!skuttDyr?.Id) return;

    const storedIndivid = storage.get<Individ>({ id: skuttDyr.Id }, "Individ");

    if (storedIndivid?.operation === "create") {
      storage.delete({ id: skuttDyr.Id }, "Individ");

      navigate("/oversikt");
      return;
    }

    jaktdataApi
      .deleteIndivid(skuttDyr.Id)
      .then(() => {
        if (skuttDyr.JaktdagId && skuttDyr.JaktdagId != "") {
          const jaktdagQueryKey = { queryKey: ["jaktdag", { jaktdagId: skuttDyr.JaktdagId }] };
          queryClient.invalidateQueries(jaktdagQueryKey).then(() => {
            queryClient.refetchQueries(jaktdagQueryKey).then(() => {
              navigate("/oversikt");
            });
          });
        } else {
          navigate("/oversikt");
        }
      })
      .catch((e: ApiError) => {
        if (e.axiosError?.code === "ERR_NETWORK") {
          storage.add({
            objectType: "Individ",
            objectKey: { id: skuttDyr.Id },
            data: skuttDyr,
            isPending: true,
            operation: "delete",
            lastUpdated: new Date(),
          });

          showSnackbar({
            message: t("sync.noNetworkWillSyncLater"),
          });

          navigate("/oversikt");
          return;
        }

        ui.showSnackbar({ message: e.message });
        navigate("/oversikt");
      });
  };

  const deleteDialog = useConfirmationDialog({
    title: t(tBase + "deleteDialogTitle"),
    message: "",
    onConfirm: handleConfirmDelete,
    confirmButtonText: t("actions.delete"),
  });

  const storageKey = `formState-/registrering/fellingsinfo/${skuttDyr?.Id}`;
  const hasDraft = !!localStorage.getItem(storageKey);
  useEffect(() => {
    if (hasDraft && skuttDyr?.Godkjent) {
      localStorage.removeItem(storageKey);
    }
  }, []);

  const removeDraft = () => {
    localStorage.removeItem(storageKey);
    navigate(-1);
  };

  if (!skuttDyr) {
    return <div className="m-4">{t("errors.notFound", { type: "Individ" })}</div>;
  }

  const removeStoredIndivid = () => {
    if (!storedIndivid) {
      return;
    }
    storage.delete(storedIndivid.objectKey, "Individ");
    window.location.reload();
  };

  const endreIndivid = () => navigate("/registrering/fellingsinfo/" + skuttDyr?.Id);

  const showLastSynced =
    appData.isOnline.value === false ||
    (appData.isOnline.value === true &&
      appData.isSynced.value === false &&
      appData.lastSynced.value !== undefined);

  return (
    <div>
      {skuttDyr && (
        <>
          <PageTitle width={250}>
            {skuttDyr.Art} {getKjonnOgAlderByArt(skuttDyr)}
          </PageTitle>

          {showLastSynced && (
            <div className="flex flex-row justify-end items-end pr-4 w-full">
              {`${t("sync.sistSynkronisert")} ${appData.lastSynced.value}`}
            </div>
          )}

          <OmradeBanner omrade={omrade} />

          {jaktdag?.Godkjent && canEditIndivid.value && (
            <GodkjentInfoBox>{t(tBase + "jaktdagenErGodkjent")}</GodkjentInfoBox>
          )}

          {!canEditIndivid.value && (
            <GodkjentInfoBox>
              {skuttDyr.Art !== ArtType.Villrein
                ? t(tBase + "kanIkkeEndres")
                : t(tBase + "kanIkkeEndresVillrein")}
            </GodkjentInfoBox>
          )}

          {hasDraft && !jaktdag?.Godkjent && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t("pages.oversikt.hasIndividDraftDescription") + " "}
              <Link onClick={removeDraft}>{t("draft.deleteDraft")}</Link>
            </AlertDefault>
          )}

          {skuttDyr.UtenforJakttid && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t(tBase + "utenforJakttid")}
            </AlertDefault>
          )}

          {isMissingOvervakData(skuttDyr) && (
            <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
              {t(tBase + "skuttIOvervakomrade")}
            </AlertDefault>
          )}

          {storedIndivid?.isPending && showLastSynced && (
            <AlertDefault variant={AlertVariant.Sync} showIcon className="mx-4 mt-4">
              {t("sync.pending")}
            </AlertDefault>
          )}

          {storedIndivid?.syncStatus === "error" && (
            <AlertDefault variant={AlertVariant.SyncError} showIcon className="mx-4 mt-4">
              <div>{t("sync.syncError")}</div>

              {storedIndivid.errors && <ErrorList errors={storedIndivid.errors} />}
              {appData.isOnline &&
                (storedIndivid.operation === "update" ? (
                  <Link className="cursor-pointer" onClick={removeStoredIndivid}>
                    {t("actions.discardAndRestore")}
                  </Link>
                ) : (
                  <Link className="cursor-pointer" onClick={endreIndivid}>{`${t(
                    "actions.endre",
                  )} ${t("pages.fellingsinfo.title").toLowerCase()}`}</Link>
                ))}
            </AlertDefault>
          )}

          <div className="p-4 flex flex-col gap-2">
            <Field
              show={skuttDyr.Art === ArtType.Hjort}
              label={t(tBase + "terreng")}
              value={skuttDyr.Terreng}
            />

            <Field
              label={t("pages.registrerArt.skutt")}
              value={toStringWithFullMonth(skuttDyr.Dato)}
            />

            <Field
              label={t(tBase + "slaktevekt")}
              value={
                skuttDyr.SlaktevektKg
                  ? `${skuttDyr.SlaktevektKg} kg ${getReadableEnumLowercase(
                      skuttDyr.MålemetodeSlaktevekt,
                    )}`
                  : skuttDyr.MålemetodeSlaktevekt === MalemetodeSlaktevekt.IkkeVeid
                    ? ikkeVeid
                    : " - "
              }
            />

            <Field
              label={t(tBase + "merkelappnr")}
              value={skuttDyr.Merkelapp ? skuttDyr.Merkelapp : "-"}
            />

            <Field
              show={skuttDyr.Art === ArtType.Villrein}
              label={t(tBase + "kontrollkort")}
              value={skuttDyr.Kontrollkort ? skuttDyr.Kontrollkort : "-"}
            />

            <Field
              label={t(tBase + "cwdProvesvar")}
              value={skuttDyr.CWDPrøvesvar ? skuttDyr.CWDPrøvesvar : "-"}
            />

            <Field
              show={!!skuttDyr.EksaktAlder}
              label={t(tBase + "eksaktAlder")}
              value={skuttDyr.EksaktAlder + " år"}
            />

            <Field
              show={skuttDyr.Kjønn === KjønnType.Hunn && skuttDyr.MelkIJuret !== null}
              label={t(tBase + "melkIJuret")}
              value={skuttDyr.MelkIJuret ? "Ja" : "Nei"}
            />

            <Field
              show={
                skuttDyr.Alder === AlderType.Voksen &&
                (skuttDyr.Kjønn === KjønnType.Hann ||
                  (skuttDyr.Kjønn === KjønnType.Hunn && skuttDyr.Art === ArtType.Villrein)) &&
                (!!skuttDyr.GevirtakkerVenstre || !!skuttDyr.GevirtakkerHøyre)
              }
              label={t(tBase + "gevirtakker")}
              value={(skuttDyr.GevirtakkerVenstre ?? "") + "-" + (skuttDyr.GevirtakkerHøyre ?? "")}
            />

            <Field
              show={!!skuttDyr.Fellingssted}
              label={t(tBase + "fellingssted")}
              value={skuttDyr.Fellingssted}
            />

            <Field
              label={t(tBase + "registrertAv")}
              value={
                skuttDyr.RegistrertAv && skuttDyr.RegistrertDato
                  ? `${skuttDyr.RegistrertAv} - ${toStringWithFullMonth(skuttDyr.RegistrertDato)}`
                  : "-"
              }
            />

            <Field
              show={!!skuttDyr.SistEndretAv && !!skuttDyr.SistEndretDato}
              label={t(tBase + "sistEndretAv")}
              value={
                skuttDyr.SistEndretAv
                  ? `${skuttDyr.SistEndretAv} - ${toStringWithFullMonth(skuttDyr.SistEndretDato)}`
                  : "-"
              }
            />

            <Field
              show={!!skuttDyr.Merknad}
              label={t(tBase + "merknad")}
              value={<ShowMoreToggle limit={50}>{skuttDyr.Merknad}</ShowMoreToggle>}
            />

            {!storedIndivid?.isPending && <div>{t(tBase + "overførtTilHVR")}</div>}
          </div>

          {canEditIndivid.value && (
            <div className="p-4 text-right space-x-3 sticky bottom-0 bg-white">
              <ButtonDefault
                id="SkuttDyrDetaljerSlett"
                label={t("actions.delete")}
                variant={ButtonVariant.SecondaryWhite}
                onClick={handleDeleteClick}
              />
              <ButtonDefault
                id="SkuttDyrDetaljerEndre"
                label={t("actions.endre")}
                variant={ButtonVariant.Primary}
                onClick={endreIndivid}
              />
            </div>
          )}
          {deleteDialog.element}
        </>
      )}
    </div>
  );
}
