import { UTCDate } from "@date-fns/utc";
import { createContext } from "react";
import { removeAll, retrieve } from "../StorageContext/helpers";

export const AuthStorageKeys = {
  authData: "authData",
};

export type Credentials = {
  Jegernummer: string;
  Fodselsdato: string;
};

export type AuthData = {
  name: string;
  token: string;
  refreshToken: string;
  tokenExpiresAt: UTCDate;
  refreshTokenExpiresAt: UTCDate;
};

export type AuthErrorType = "InvalidCredentials" | "MissingAuthData" | "TokenExpired" | "Unknown";
export class AuthError extends Error {
  constructor(message: AuthErrorType) {
    super(message);
    this.name = "AuthError";
  }
}

abstract class AuthProvider {
  public authData(): AuthData | undefined {
    // StorageProvider kan ikke brukes her ettersom den er avhengig av å ha en aktiv AuthContext
    return retrieve<AuthData>(AuthStorageKeys.authData);
  }

  public token(): string {
    return this.authData()?.token ?? "";
  }

  public refreshTokenExpired(): boolean {
    const refreshTokenExpiresAt = this.authData()?.refreshTokenExpiresAt;
    return (
      refreshTokenExpiresAt === undefined || new UTCDate(refreshTokenExpiresAt) < new UTCDate()
    );
  }

  public currentUser(): string | undefined {
    return this.authData()?.name;
  }

  public async verify(): Promise<AuthData> {
    // Denne trenger strengt tatt ikke være async nå som localStorage brukes istedet for IDB (som er async).
    let authData = this.authData();
    const tokenExpiresAt = authData?.tokenExpiresAt;
    const tokenExpired =
      tokenExpiresAt === undefined || new UTCDate(tokenExpiresAt) < new UTCDate();

    if (tokenExpired && !this.refreshTokenExpired()) {
      return this.handleRefresh();
    }

    return new Promise((resolve, reject) => {
      if (!!authData && !!authData.token && !tokenExpired) {
        resolve(authData);
      } else if (authData === undefined) {
        reject(new AuthError("MissingAuthData"));
      } else if (tokenExpired) {
        this.handleLogout();
        reject(new AuthError("TokenExpired"));
      }
      reject(new AuthError("Unknown"));
    });
  }

  abstract handleLogin(credentials: Credentials): Promise<AuthData>;
  abstract handleRefresh(): Promise<AuthData>;

  async handleLogout(): Promise<void> {
    removeAll([AuthStorageKeys.authData]);
  }
}

const AuthContext = createContext({} as AuthProvider);

export { AuthContext as default, AuthProvider };
