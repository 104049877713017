import { ArrowDropDown, Search } from "@mui/icons-material";
import React from "react";
import InputDefault, { InputDefaultProps } from "./InputDefault";

export type InputFilterDefaultProps = InputDefaultProps;

const InputFilterDefault: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputFilterDefaultProps
> = ({ ...rest }, ref) => {
  return (
    <div className="flex flex-row w-full items-center bg-md-greengray gap-2 pl-2">
      <Search />
      <InputDefault
        {...rest}
        ref={ref}
        variant="standard"
        containerProps={{ className: "bg-md-greengray" }}
        inputClasses={"w-full"}
        leftIcon={<ArrowDropDown />}
      />
    </div>
  );
};

export default React.forwardRef(InputFilterDefault);
