import { Card, CardHeader, Typography } from "@material-tailwind/react";
import * as env from "env-var";
import { entries } from "idb-keyval";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonDefault from "../../components/Button/ButtonDefault";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import AuthContext from "../../contexts/AuthContext/AuthProvider";

const tableHeader = ["key", "value"];

export default function Debug(): JSX.Element {
  const appData = useContext(AppDataContext);
  const auth = useContext(AuthContext);
  const [idbData, setIdbData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function verifyAccess() {
      if (env.get("NODE_ENV").asString() === "production") {
        navigate("/");
      }
    }

    async function updateIdbData() {
      entries().then((_entries) => setIdbData(Object.fromEntries(_entries)));
    }

    verifyAccess();
    updateIdbData();
  }, []);

  const tableRows = [
    {
      key: "AppDataContext",
      value: JSON.stringify(appData, null, 2),
    },
    {
      key: "idb-keyval store",
      value: JSON.stringify(idbData, null, 2),
    },
  ];

  return (
    <Card className="flex flex-col items-left border-b-black" color="transparent" shadow={true}>
      <CardHeader
        floated={false}
        shadow={false}
        className="w-full bg-md-seagray m-0 rounded-none items-center"
      >
        <Typography className="h1 font-bold text-white text-center">Debug</Typography>
      </CardHeader>
      <table className="text-left">
        <thead>
          <tr>
            {tableHeader.map((head) => (
              <th key={head} className="border-b border-md-seagray bg-md-greengray p-4">
                <Typography variant="small" className="font-normal leading-none">
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map(({ key, value }, index) => {
            const isLast = index === tableRows.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-md-seagray";

            return (
              <tr key={key}>
                <td className={classes}>
                  <Typography variant="small" className="font-normal">
                    {key}
                  </Typography>
                </td>
                <td className={classes}>
                  <pre>{value}</pre>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ButtonDefault
        id="debugPageLogoutButton"
        label="Logg ut"
        onClick={async () => {
          await auth.handleLogout();
          window.location.reload();
        }}
      />
    </Card>
  );
}
