import { BottomNavigationAction, BottomNavigationActionProps } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

export type NavigationButtonDefaultProps = Omit<BottomNavigationActionProps, "ref" | "children"> & {
  id: string | number;
  label: string;
  icon: JSX.Element;
  ref?: React.Ref<HTMLButtonElement>;
  path: string;
  // selected: boolean;
};

const NavigationButtonDefault: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  NavigationButtonDefaultProps
> = ({ id, label, icon, ...rest }, ref) => {
  const defaultClassName = "text-white";
  const { pathname } = useLocation();

  return (
    <BottomNavigationAction
      ref={ref}
      id={id}
      data-testid={id}
      label={label}
      icon={icon}
      className={
        "py-6 px-3 text-xs " +
        (pathname.startsWith(rest.path) ? " bg-md-secondary text-md-seagray" : defaultClassName)
      }
      {...rest}
    />
  );
};

export default React.forwardRef(NavigationButtonDefault);
