import { Close } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ButtonDefault from "../Button/ButtonDefault";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<PromptResult>;
}

interface PromptResult {
  readonly outcome: "accepted" | "dismissed";
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const InstallApp = () => {
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const HIDE_INSTALL_BANNER = "HideInstallBanner";
  const isHidden = localStorage.getItem(HIDE_INSTALL_BANNER) === "true";

  const beforeInstallPrompt = async (event: BeforeInstallPromptEvent) => {
    if (!/Android/i.test(navigator.userAgent)) return;

    setInstallPrompt(event);

    setShowInstallButton(!isHidden);
  };
  window.addEventListener("beforeinstallprompt", (event) => beforeInstallPrompt(event));

  async function handleInstall() {
    if (!installPrompt) {
      return;
    }

    const result = await installPrompt.prompt();

    if (result.outcome === "accepted") handleHideBanner();
  }

  function handleHideBanner() {
    setShowInstallButton(false);
    localStorage.setItem(HIDE_INSTALL_BANNER, "true");
  }

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
      setInstallPrompt(null);
    };
  }, []);

  return (
    <>
      <Collapse in={showInstallButton}>
        <div className="flex items-center justify-center py-2 ">
          <span className="text-xs mr-2">{t("components.installApp.androidBanner")}</span>
          <ButtonDefault id="InstallBtn" onClick={handleInstall}>
            {t("components.installApp.install")}
          </ButtonDefault>
          <IconButton onClick={handleHideBanner}>
            <Close />
          </IconButton>
        </div>
      </Collapse>
    </>
  );
};

export default InstallApp;
