import { PhonelinkErase } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import InternalLink from "~/src/components/Links/InternalLink";
import { onEnterPressed } from "~/src/helpers/accessibilityHelpers";

const deleteDataAndReload = async () => {
  localStorage.clear();

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
  window.location.reload();
};

function Om(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <div className="m-8 whitespace-pre-line">{t("pages.omSos.text")}</div>
      <div
        className="flex flex-row items-center pl-10"
        onClick={deleteDataAndReload}
        onKeyDown={(e) => onEnterPressed(e, deleteDataAndReload)}
      >
        <InternalLink
          path="#"
          text={t("pages.omSos.clearDataAndReload")}
          icon={<PhonelinkErase />}
        />
      </div>
    </>
  );
}

export default Om;
