import { useTranslation } from "react-i18next";
import Counter from "~/src/components/Counter/Counter";
import Text from "~/src/components/Text/Text";
import { JaktdagRegistrationDataElg } from "../RegistrerElg/ElgTypes";
import { JaktdagRegistrationDataHjort } from "../RegistrerHjort/HjortTypes";

type AcceptableTypes = JaktdagRegistrationDataElg | JaktdagRegistrationDataHjort;

type AntallJegereOgTimerJaktetProps<T extends AcceptableTypes> = {
  disabled?: boolean;
  formData: T;
  setFormData: (data: T) => void;
  errors: Partial<Record<keyof T, string>>;
  setErrors: (errors: Partial<Record<keyof object, string>>) => void;
  innmark?: boolean;
};

const AntallJegereOgTimerJaktet = <T extends AcceptableTypes>({
  formData,
  setFormData,
  errors,
  setErrors,
  disabled = false,
  innmark = false,
}: AntallJegereOgTimerJaktetProps<T>) => {
  const { t } = useTranslation();

  const antallJegere = innmark ? "AntallJegereInnmark" : "AntallJegere";
  const antallTimerJaktet = innmark ? "AntallTimerJaktetInnmark" : "AntallTimerJaktet";

  return (
    <div className="space-y-2">
      <Counter
        id="jegereMedVåpen"
        maxCount={99}
        label={t("form.fields.jegereMedVåpen.label")}
        error={errors[antallJegere]}
        onCountChanged={(count) => {
          const _formData = { ...formData };
          _formData[antallJegere] = count;
          const _errors = { ...errors };
          _errors[antallJegere] = "";
          setFormData(_formData);
          setErrors(_errors);
        }}
        disabled={disabled}
        value={formData[antallJegere] || 0}
      />
      <Counter
        id="timerJaktet"
        label={t("form.fields.timerJaktet.label")}
        error={errors[antallTimerJaktet]}
        maxCount={24}
        onCountChanged={(count) => {
          const _formData = { ...formData };
          _formData[antallTimerJaktet] = count;
          const _errors = { ...errors };
          _errors[antallTimerJaktet] = "";
          setFormData(_formData);
          setErrors(_errors);
        }}
        disabled={disabled}
        value={formData[antallTimerJaktet] || 0}
      />
      <Text variant="small">{t("form.fields.timerJaktet.helpText")}</Text>
    </div>
  );
};

export default AntallJegereOgTimerJaktet;
