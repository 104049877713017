import { Email } from "@mui/icons-material";
import Call from "@mui/icons-material/Call";
import { useTranslation } from "react-i18next";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";

export default function (): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <div className="m-8 whitespace-pre-line">
        {t("pages.kontakt.text")}

        <div className="mt-5">
          <ButtonDefault
            id="PhoneNo"
            variant={ButtonVariant.Secondary}
            onClick={() => (window.location.href = "tel:+47 74 33 53 10")}
            label="Ring 74 33 53 10"
            icon={<Call />}
          />
        </div>
        <div className="mt-5">
          <ButtonDefault
            id="EmailAdress"
            variant={ButtonVariant.Secondary}
            onClick={() => (window.location.href = "mailto:support@naturdata.no")}
            label="E-post support@naturdata.no"
            icon={<Email />}
          />
        </div>
      </div>
    </>
  );
}
