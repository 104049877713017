import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "~/src/components/Text/Text";
import AuthContext from "../../contexts/AuthContext/AuthProvider";
import JegeropplysningerForm from "../MinSide/Components/JegeropplysningerForm";

export default function Velkommen(): JSX.Element {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center gap-2 pt-8 h-screen">
      <Text className="text-center" variant="h3">
        {t("pages.velkommen.title")}
      </Text>
      <JegeropplysningerForm
        mode="velkommen"
        onCancel={async () => {
          auth.handleLogout().then(() => navigate("/login"));
        }}
      />
    </div>
  );
}
