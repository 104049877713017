import { useTranslation } from "react-i18next";
import packageFile from "../../package.json";

export type AppInfo = {
  name: string;
  description: string;
  version: string;
};

const getAppInfo = (): AppInfo => {
  const { t } = useTranslation();
  const name = t("app.name");
  const description = t("app.description");
  const version = "v " + packageFile.version;

  return { name, description, version };
};

export default getAppInfo;
