import { Route, Routes } from "react-router-dom";
import { DataProvider } from "~/src/contexts/AppDataContext/DataProvider";
import Velkommen from "../Velkommen/Velkommen";
import MainLayout from "./Layout";

const RequireDataLayout = () => {
  return (
    <DataProvider>
      <Routes>
        <Route path="/velkommen" element={<Velkommen />} />
        <Route path="/*" element={<MainLayout />} />
      </Routes>
    </DataProvider>
  );
};

export default RequireDataLayout;
