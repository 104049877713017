import { Favorittområde, Område } from "../api/types";
import { ArtType } from "./types";

export const firstNonEmpty = (array: (string | undefined)[]) => {
  return array.find((e) => !!e);
};

export const harJaktPaArt = (
  art: ArtType | undefined,
  omrade: Område | Favorittområde,
): boolean => {
  if (art === undefined) {
    return false;
  }
  switch (art) {
    case ArtType.Elg:
      return omrade.JaktPåElg || false;
    case ArtType.Hjort:
      return omrade.JaktPåHjort || false;
    case ArtType.Rådyr:
      return omrade.JaktPåRådyr || false;
    case ArtType.Villrein:
      return omrade.JaktPåVillrein || false;
    case ArtType.Villsvin:
      return omrade.JaktPåVillsvin || false;
    default:
      return false;
  }
};

export function NOT_EMPTY<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
