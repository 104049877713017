import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from "@material-tailwind/react";

export type TextProps = Omit<MUITypographyProps, "ref"> & { className?: string };

export default function Text(props: TextProps): JSX.Element {
  let fontWeight = "";
  switch (props.variant) {
    case undefined:
    case "small":
    case "paragraph":
      fontWeight = "font-normal";
      break;
    default:
      fontWeight = "font-semibold";
  }
  return <MUITypography {...props} className={fontWeight + " " + props.className} />;
}
