import { Button } from "@material-tailwind/react";
import { ButtonProps } from "@material-tailwind/react/components/Button";
import React, { ReactNode } from "react";

// TODO: bruke union types istedet for enums?

export enum ButtonLayout {
  Horizontal,
  Vertical,
}

export enum ButtonVariant {
  Primary,
  Secondary,
  SecondaryWhite,
  PrimarySquareish,
  SecondarySquareish,
}

export type ButtonDefaultProps = Omit<ButtonProps, "ref" | "children" | "variant" | "icon"> & {
  id: string | number;
  label?: string;
  icon?: JSX.Element;
  variant?: ButtonVariant;
  rounded?: boolean;
  children?: ReactNode;
  containerClassName?: string;
};

const ButtonDefault: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonDefaultProps> = (
  {
    id,
    label,
    icon,
    fullWidth = false,
    variant = ButtonVariant.Primary,
    children,
    containerClassName = "flex items-center justify-center space-x-2",
    ...rest
  },
  ref,
): JSX.Element => {
  let Icon = null;
  if (icon) Icon = React.cloneElement(icon, { className: "" });
  let defaultClassName =
    "normal-case text-sm font-normal py-2 px-4 min-w-24 h-12 border-4 border-transparent";
  switch (variant) {
    case ButtonVariant.Secondary: {
      defaultClassName += " bg-md-secondary rounded-full text-md-primary ";
      break;
    }
    case ButtonVariant.SecondaryWhite: {
      defaultClassName += " bg-white rounded-full text-md-primary border border-md-primary ";
      break;
    }
    case ButtonVariant.PrimarySquareish: {
      defaultClassName += " bg-md-primary rounded-5 fill-white ";
      containerClassName += " gap-4 ";
      break;
    }
    case ButtonVariant.SecondarySquareish: {
      defaultClassName +=
        "   p-8 bg-md-secondary rounded-5 font-thin text-md-primary fill-md-brown md-sea-green-medium border border-md-sea-green-dark ";
      break;
    }
    default: {
      // Applies to ButtonVarian.Primary and other unhandled variants.
      defaultClassName += " bg-md-primary rounded-full";
      break;
    }
  }
  return (
    <Button
      ref={ref}
      data-testid={id}
      name={label}
      aria-label={label}
      aria-labelledby={label}
      fullWidth={fullWidth}
      {...rest}
      className={`${defaultClassName} ${rest.className}`}
    >
      <div className={containerClassName}>
        {icon && <div>{Icon}</div>}
        <div>{children || label}</div>
      </div>
    </Button>
  );
};

export default React.forwardRef(ButtonDefault);
