import { formatISO } from "date-fns";
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import AppDataContext, { AppData } from "~/src/contexts/AppDataContext/DataProvider";
import { getOmradeForSingleFavoritt } from "~/src/helpers/omradeHelper";
import { formatISOWithoutTimestamp } from "~/src/helpers/time";
import {
  CreateIndividRequest,
  CreateJaktdagRequest,
  IndividResponse,
  JaktdagResponse,
} from "../../../../api/types";
import { AlderType, ArtType, KjønnType } from "../../../../helpers/types";
import { Terreng } from "../../types";
import {
  JaktdagRegistrationDataHjort,
  SkuttDataHjortInnmark,
  SkuttDataHjortUtmark,
  SumSettHjort,
  SumSettOgSkuttHjortTerreng,
  SumSkuttHjort,
  defaultHjort,
  skuttHjortTypes,
} from "./HjortTypes";

type SkuttDataHjort = SkuttDataHjortInnmark & SkuttDataHjortUtmark;

const findKey = (alder: AlderType, kjønn: KjønnType, terreng: Terreng): keyof SkuttDataHjort => {
  return skuttHjortTypes.find(
    (field) => field.alder === alder && field.kjønn === kjønn && field.terreng === terreng,
  )?.key as keyof SkuttDataHjort;
};

export const parseInitialData = (
  initialData?: JaktdagResponse,
  appData?: AppData,
): JaktdagRegistrationDataHjort | undefined => {
  const { jaktfelt } = useContext(AppDataContext);

  // pull out individer og lage en skuttData av dem.
  const jaktFeltNavn =
    jaktfelt.data?.data.find((felt) => felt.Id === initialData?.Jaktfelt)?.Navn || "";

  const skuttData: SkuttDataHjort = {
    // set all to 0
    SkuttBukkToOgHalvtÅrPlussInnmark: 0,
    SkuttBukkEttOgHalvtÅrInnmark: 0,
    SkuttKolleToOgHalvtÅrPlussInnmark: 0,
    SkuttKolleEttOgHalvtÅrInnmark: 0,
    SkuttHannKalvInnmark: 0,
    SkuttHunnKalvInnmark: 0,
    SkuttBukkToOgHalvtÅrPluss: 0,
    SkuttBukkEttOgHalvtÅr: 0,
    SkuttKolleToOgHalvtÅrPluss: 0,
    SkuttKolleEttOgHalvtÅr: 0,
    SkuttHannKalv: 0,
    SkuttHunnKalv: 0,
  };
  for (const individ of initialData?.Individer || []) {
    const key = findKey(
      individ.Alder as AlderType,
      individ.Kjønn as KjønnType,
      individ.Terreng as Terreng,
    );
    if (key) {
      skuttData[key] += 1;
    }
  }
  const favorittOmråde = appData && getOmradeForSingleFavoritt(appData, ArtType.Hjort);
  const _data: JaktdagRegistrationDataHjort = {
    ...defaultHjort,
    jaktomradeNavn: jaktFeltNavn || favorittOmråde?.Områdenavn || "",
    Jegernummer: appData?.jegeropplysninger.data?.Jegernummer,
    ...skuttData,
    Dato: initialData?.Dato || formatISOWithoutTimestamp(new Date())!,
    Id: initialData?.Id || "",
    Jaktfelt: initialData?.Jaktfelt || favorittOmråde?.Områdekode || "",

    AntallJegere: initialData?.AntallJegere || 0,
    AntallTimerJaktet: initialData?.AntallTimerJaktet || 0,

    AntallJegereInnmark: initialData?.AntallJegereInnmark || 0,
    AntallTimerJaktetInnmark: initialData?.AntallTimerJaktetInnmark || 0,

    // utmark
    SettSpissbukk: initialData?.SettSpissbukk || 0,
    SettOkseEllerBukk: initialData?.SettOkseEllerBukk || 0,
    SettKyrUtenKalvEllerKolle: initialData?.SettKyrUtenKalvEllerKolle || 0,
    SettKalverAlene: initialData?.SettKalverAlene || 0,
    SettUkjente: initialData?.SettUkjente || 0,

    // innmark
    SettSpissbukkInnmark: initialData?.SettSpissbukkInnmark || 0,
    SettBukkInnmark: initialData?.SettBukkInnmark || 0,
    SettKolleInnmark: initialData?.SettKolleInnmark || 0,
    SettKalvInnmark: initialData?.SettKalvInnmark || 0,
    SettUkjenteInnmark: initialData?.SettUkjenteInnmark || 0,

    Innmark: initialData?.Innmark || false,
    Utmark: initialData?.Utmark || false,
  };
  return _data;
};

function sumSett(data: JaktdagRegistrationDataHjort, terreng: Terreng): SumSettHjort {
  const sett =
    terreng == Terreng.Innmark
      ? {
          settBukker: data.SettBukkInnmark || 0,
          settSpissbukker: data.SettSpissbukkInnmark || 0,
          settKalver: data.SettKalvInnmark || 0,
          settKoller: data.SettKolleInnmark || 0,
          settUkjente: data.SettUkjenteInnmark || 0,
        }
      : {
          settBukker: data.SettOkseEllerBukk || 0,
          settSpissbukker: data.SettSpissbukk || 0,
          settKalver: data.SettKalverAlene || 0,
          settKoller: data.SettKyrUtenKalvEllerKolle || 0,
          settUkjente: data.SettUkjente || 0,
        };
  return {
    ...sett,
    sumSett:
      sett.settBukker + sett.settSpissbukker + sett.settKalver + sett.settKoller + sett.settUkjente,
  };
}

function sumSkutt(data: JaktdagRegistrationDataHjort, terreng: Terreng): SumSkuttHjort {
  const skutt =
    terreng == Terreng.Innmark
      ? {
          skuttBukker: data.SkuttBukkToOgHalvtÅrPlussInnmark || 0,
          skuttSpissbukker: data.SkuttBukkEttOgHalvtÅrInnmark || 0,
          skuttKoller:
            (data.SkuttKolleToOgHalvtÅrPlussInnmark || 0) +
            (data.SkuttKolleEttOgHalvtÅrInnmark || 0),
          skuttKalver: (data.SkuttHannKalvInnmark || 0) + (data.SkuttHunnKalvInnmark || 0),
        }
      : {
          skuttBukker: data.SkuttBukkToOgHalvtÅrPluss || 0,
          skuttSpissbukker: data.SkuttBukkEttOgHalvtÅr || 0,
          skuttKoller: (data.SkuttKolleToOgHalvtÅrPluss || 0) + (data.SkuttKolleEttOgHalvtÅr || 0),
          skuttKalver: (data.SkuttHannKalv || 0) + (data.SkuttHunnKalv || 0),
        };
  return {
    ...skutt,
    sumSkutt: skutt.skuttBukker + skutt.skuttSpissbukker + skutt.skuttKoller + skutt.skuttKalver,
  };
}

export function sumSettOgSkuttHjort(
  data: JaktdagRegistrationDataHjort,
): SumSettOgSkuttHjortTerreng {
  return {
    utmark: {
      sett: sumSett(data, Terreng.Utmark),
      skutt: sumSkutt(data, Terreng.Utmark),
    },
    innmark: {
      sett: sumSett(data, Terreng.Innmark),
      skutt: sumSkutt(data, Terreng.Innmark),
    },
  };
}

const findSkutt = (
  skutteDyr: IndividResponse[] | undefined,
  alder: AlderType,
  kjonn: KjønnType,
  terreng: Terreng,
) => {
  if (!skutteDyr) return [];
  return skutteDyr.filter(
    (individ) => individ.Alder === alder && individ.Kjønn === kjonn && individ.Terreng === terreng,
  );
};

export const toJaktdagRequestHjort = (
  data: JaktdagRegistrationDataHjort,
  date: Date,
  jegernummer?: number,
  skutteDyr?: IndividResponse[],
) => {
  const jaktdagId = data.Id || uuid();
  const _data = { ...data };

  _data.Innmark = Boolean(_data.Innmark);
  _data.Utmark = Boolean(_data.Utmark);

  if (!_data.Innmark) {
    _data.AntallJegereInnmark = 0;
    _data.AntallTimerJaktetInnmark = 0;
    _data.SettSpissbukkInnmark = 0;
    _data.SettBukkInnmark = 0;
    _data.SettKolleInnmark = 0;
    _data.SettKalvInnmark = 0;
    _data.SettUkjenteInnmark = 0;
    _data.SkuttBukkToOgHalvtÅrPlussInnmark = 0;
    _data.SkuttBukkEttOgHalvtÅrInnmark = 0;
    _data.SkuttKolleToOgHalvtÅrPlussInnmark = 0;
    _data.SkuttKolleEttOgHalvtÅrInnmark = 0;
    _data.SkuttHannKalvInnmark = 0;
    _data.SkuttHunnKalvInnmark = 0;
  }
  if (!_data.Utmark) {
    _data.AntallJegere = 0;
    _data.AntallTimerJaktet = 0;
    _data.SettSpissbukk = 0;
    _data.SettOkseEllerBukk = 0;
    _data.SettKyrUtenKalvEllerKolle = 0;
    _data.SettKalverAlene = 0;
    _data.SettUkjente = 0;
    _data.SkuttBukkEttOgHalvtÅr = 0;
    _data.SkuttKolleToOgHalvtÅrPluss = 0;
    _data.SkuttKolleEttOgHalvtÅr = 0;
    _data.SkuttHannKalv = 0;
    _data.SkuttHunnKalv = 0;
  }

  let individer = new Array<CreateIndividRequest>();

  const oppdaterAntallSkutt = (alder: AlderType, kjønn: KjønnType, terreng: Terreng) => {
    const key = findKey(alder, kjønn, terreng) as keyof SkuttDataHjort;
    let skutteAvDenneTypen = findSkutt(skutteDyr, alder, kjønn, terreng);
    const antallFraSkjema = _data[key];
    if (!antallFraSkjema && !skutteAvDenneTypen.length) return;

    const antallSomSkalLeggesTil = antallFraSkjema - skutteAvDenneTypen.length;
    if (antallSomSkalLeggesTil > 0) {
      for (let i = 0; i < antallSomSkalLeggesTil; i++) {
        individer.push({
          Id: uuid(),
          Dato: formatISO(date),
          JaktdagId: jaktdagId,
          Jaktfelt: _data.Jaktfelt,
          Alder: alder,
          Terreng: terreng,
          Art: ArtType.Hjort,
          Kjønn: kjønn,
          Jegernummer: jegernummer,
        });
      }
    } else {
      const antallSomSkalSlettes = skutteAvDenneTypen.length - antallFraSkjema;
      if (antallSomSkalSlettes > 0) {
        const kanSlettes = (dyr: IndividResponse) =>
          !(dyr.SlaktevektKg || dyr.Merkelapp || dyr.Godkjent);

        const dyrSomKanSlettes = skutteAvDenneTypen.filter(kanSlettes);
        const dyrSomIkkeKanSlettes = skutteAvDenneTypen.filter((dyr) => !kanSlettes(dyr));
        if (dyrSomKanSlettes.length < antallSomSkalSlettes) {
          throw new Error("CanNotDelete");
        }

        skutteAvDenneTypen = [
          ...dyrSomIkkeKanSlettes,
          ...dyrSomKanSlettes.slice(0, dyrSomKanSlettes.length - antallSomSkalSlettes),
        ];
      }
    }
    // transformer skutteAvdennetypen from IndividResponse to CreateIndividRequest, med de nye verdiene
    const addableIndivider: CreateIndividRequest[] = skutteAvDenneTypen.map(
      (individ) => ({ ...individ }) as CreateIndividRequest,
    );
    individer = [...individer, ...addableIndivider];
  };

  for (const type of skuttHjortTypes) {
    oppdaterAntallSkutt(type.alder, type.kjønn, type.terreng);
  }

  const request: CreateJaktdagRequest = {
    ..._data,
    Id: jaktdagId,
    Jaktfelt: _data.Jaktfelt || "",
    Dato: formatISO(date),
    Art: ArtType.Hjort,
    Individer: individer,
  };
  return request;
};
