import Info from "@mui/icons-material/Info";
import { PropsWithChildren } from "react";

export default function WarningAlert({ children }: PropsWithChildren): JSX.Element {
  return (
    <div className="bg-md-warning-light border rounded border-md-warning-dark py-2 px-3 flex items-center">
      <Info className="text-md-warning-dark mr-3" />
      {children}
    </div>
  );
}
