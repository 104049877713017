import { TFunction } from "i18next";
import { z } from "zod";
import { Stateful } from "../../../../hooks/useStateful";
import { sumSettOgSkuttElg } from "./ElgHelpers";
import { JaktdagRegistrationDataElg } from "./ElgTypes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const elgFormValidation = (
  t: TFunction<"translation", undefined>,
  fellingsRapportLevert: Stateful<boolean>,
) =>
  z
    .object({
      Jaktfelt: z.string().min(1, { message: t("pages.registrering.errors.jaktfelt") }),
      AntallTimerJaktet: z
        .number()
        .min(1, { message: t("form.fields.timerJaktet.validationError") })
        .max(24, { message: t("form.fields.timerJaktet.validationError") }),
      AntallJegere: z
        .number()
        .min(1, { message: t("form.fields.jegereMedVåpen.validationError") })
        .max(100, { message: t("form.fields.jegereMedVåpen.validationError") }),
      SettOkseEllerBukk: z.number(),
      SettKyrUtenKalvEllerKolle: z.number(),
      SettKyrMed1Kalv: z.number(),
      SettKyrMed2EllerFlereKalver: z.number(),
      SettKalverAlene: z.number(),
      SettUkjente: z.number(),
      SkuttOkseToOgHalvtÅrPluss: z.number(),
      SkuttOkseEttOgHalvtÅr: z.number(),
      SkuttKuToOgHalvtÅrPluss: z.number(),
      SkuttKuEttOgHalvtÅr: z.number(),
      SkuttOkseKalv: z.number(),
      SkuttKuKalv: z.number(),
    })
    .partial()
    .superRefine((data, ctx) => {
      const sum = sumSettOgSkuttElg(data as JaktdagRegistrationDataElg);
      if (data.Jaktfelt === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["Jaktfelt"],
          fatal: true,
          message: t("form.fields.jaktfelt.validationError"),
        });
      }
      if (fellingsRapportLevert.value === true) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["Jaktfelt"],
          fatal: true,
          message: t("form.fields.jaktfelt.fellingsrapportLevertError"),
        });
      }
      if (sum.settKyr < sum.skuttKyr) {
        ["SettKyrUtenKalvEllerKolle", "SettKyrMed1Kalv", "SettKyrMed2EllerFlereKalver"].forEach(
          (path) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [path],
              fatal: true,
              message: t("form.fields.settAntall.validationError"),
            });
          },
        );
        ["SkuttKuToOgHalvtÅrPluss", "SkuttKuEttOgHalvtÅr"].forEach((path) => {
          data[path as keyof typeof data] &&
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [path],
              fatal: true,
              message: t("form.fields.skuttAntall.validationError"),
            });
        });
      }
      if (sum.settKalver < sum.skuttKalver) {
        ["SettKyrMed1Kalv", "SettKyrMed2EllerFlereKalver", "SettKalverAlene"].forEach((path) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [path],
            fatal: true,
            message: t("form.fields.settAntall.validationError"),
          });
        });
        ["SkuttOkseKalv", "SkuttKuKalv"].forEach((path) => {
          data[path as keyof typeof data] &&
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [path],
              fatal: true,
              message: t("form.fields.skuttAntall.validationError"),
            });
        });
      }
      if (sum.settOkser < sum.skuttOkser) {
        ["SettOkseEllerBukk"].forEach((path) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [path],
            fatal: true,
            message: t("form.fields.settAntall.validationError"),
          });
        });
        ["SkuttOkseToOgHalvtÅrPluss", "SkuttOkseEttOgHalvtÅr"].forEach((path) => {
          data[path as keyof typeof data] &&
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [path],
              fatal: true,
              message: t("form.fields.skuttAntall.validationError"),
            });
        });
      }
    });
