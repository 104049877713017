import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const supportedLanguages = ["nb-NO", "nn-NO", "nb", "nn"];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: supportedLanguages,
    detection: {
      caches: ["localStorage", "cookie"],
      cookieMinutes: 10,
      lookupQuerystring: "lng",
      lookupLocalStorage: "i18nextLng",
    },
    fallbackLng: "nb",
    returnObjects: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on("missingKey", (lng, namespace, key, fallbackValue) => {
  console.warn(lng, namespace, key, fallbackValue);
});
export default i18n;
