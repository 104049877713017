import { useEffect } from "react";

export type ErrorListProps<T> = {
  errors: Partial<Record<keyof T, string>>;
};

export default function ErrorList<T>(props: ErrorListProps<T>): JSX.Element {
  const { errors } = props;
  const errorListItems: JSX.Element[] = [];

  useEffect(() => {
    for (const error in errors) {
      errorListItems.push(<li key={errors[error]}>{error}</li>);
    }
  }, []);
  return (
    <ul className="list-disc pl-5">
      {Object.keys(errors).map((error) => {
        return <li key={error}>{errors[error as keyof T]}</li>;
      })}
    </ul>
  );
}
