import { BarcodeFormat, DetectedBarcode } from "barcode-detector";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import BarcodeReader, { getBarcodeReader } from "~/src/helpers/BarcodeReader";
import { Stateful, useStateful } from "../../../hooks/useStateful";

type BarcodeScannerProps = {
  onResult?: (result: DetectedBarcode) => void;
  enabled: Stateful<boolean>;
  scanIntervalMilliseconds?: number;
  barcodeFormats?: BarcodeFormat[];
  requestedCameraCapabilities?: MediaTrackConstraints;
};

export const BarcodeScanner: React.FC<BarcodeScannerProps> = ({
  onResult,
  enabled,
  scanIntervalMilliseconds = 250,
  barcodeFormats = undefined,
  requestedCameraCapabilities = { advanced: [{ facingMode: "environment" }] },
}: BarcodeScannerProps) => {
  const torchOn = useStateful<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoStream = useStateful<MediaStream | undefined>(undefined);
  const barcodeReader = useStateful<BarcodeReader | undefined>(undefined);
  const ui = useContext(UIContext);
  const { t } = useTranslation();

  const getMediaInput = async (): Promise<MediaStream> => {
    try {
      // Hent kamera som best passer requestedCapabilities
      return navigator.mediaDevices.getUserMedia({ video: requestedCameraCapabilities });
    } catch (_error) {
      // Hent annet kamera
      return navigator.mediaDevices.getUserMedia({ video: true });
    }
  };

  const resetAll = () => {
    torchOn.set(false);
    enabled.set(false);
    if (videoStream.value !== undefined) {
      videoStream.value?.getTracks().forEach((track) => {
        // @ts-ignore
        track.applyConstraints({ advanced: [{ torch: false }] }).catch(() => {
          // do nothing
        });
        track.stop();
      });
    }
    videoRef.current = null;
    barcodeReader.set(undefined);
  };

  useEffect(() => {
    if (enabled.value && videoRef.current !== null) {
      getMediaInput()
        .then((stream) => {
          videoStream.set(stream);
          stream.getTracks().forEach((track) => {
            // @ts-ignore
            track.applyConstraints({ advanced: [{ torch: true }] }).catch(() => {
              // do nothing
            });
          });
        })
        .catch((error) => {
          console.log(error);
          ui.showSnackbar({ message: t("barcodeScanner.canNotActivate") });
          resetAll();
        });
    } else {
      resetAll();
    }
  }, [enabled.value]);

  useEffect(() => {
    if (!!videoRef && !!videoRef.current && !!videoStream.value) {
      videoRef.current.srcObject = videoStream.value;
      getBarcodeReader(barcodeFormats)
        .then((reader) => {
          barcodeReader.set(reader);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }, [videoStream.value]);

  useEffect(() => {
    const scan = async () => {
      if (barcodeReader.value !== undefined && videoRef.current) {
        barcodeReader.value
          ?.detect(videoRef.current)
          .then((result) => {
            onResult && onResult(result);
            resetAll();
          })
          .catch(() => {
            // do nothing - scan again
          });
      }
    };
    const scanInterval = setInterval(async () => {
      await scan();
    }, scanIntervalMilliseconds);

    return () => {
      clearInterval(scanInterval);
    };
  }, [barcodeReader.value, videoRef.current, onResult]);

  return enabled.value ? (
    <>
      <video
        autoPlay={true}
        ref={videoRef}
        playsInline={true}
        className=" w-full border-md-primary border-2 bg-black "
      />
    </>
  ) : (
    <div />
  );
};

export default BarcodeScanner;
