export const Counter = ({
  count = 0,
  hasError = false,
}: { count?: number | null; hasError?: boolean }): JSX.Element => {
  return (
    <div
      className={
        "bg-md-greengray px-4 py-1.5 rounded ml-3 " + (hasError ? " border-red-400 border-2" : "")
      }
    >
      {count}
    </div>
  );
};

export const CounterWithLabel = ({
  label,
  count,
  hasError = false,
}: { label: string; count?: number; hasError?: boolean }): JSX.Element => {
  return (
    <>
      {!!count && (
        <div className="flex text-base justify-between mr-5">
          <div>{label}</div>
          <Counter count={count} hasError={hasError} />
        </div>
      )}
    </>
  );
};
