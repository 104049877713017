import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Jaktdag, JaktdagResponse } from "~/src/api/types";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import { StorageContext, StorageItem } from "~/src/contexts/StorageContext/StorageProvider";
import { ArtType } from "../../../helpers/types";
import { getJaktdagQuery } from "../../../react-query/queries";
import RegistrerElg from "./RegistrerElg/RegistrerElg";
import RegistrerHjort from "./RegistrerHjort/RegistrerHjort";

const RedigerJaktdag = () => {
  const { jaktdagId } = useParams();
  const storage = useContext(StorageContext);
  const appData = useContext(AppDataContext);
  const [jaktdag, setJaktdag] = useState<JaktdagResponse | null | undefined>(null);
  const queryClient = useQueryClient();
  const queryKey = ["jaktdag", { jaktdagId: jaktdagId }];
  const jaktdagQuery = useQuery({
    queryKey: queryKey,
    queryFn: getJaktdagQuery,
    enabled: jaktdagId !== undefined,
    staleTime: 0,
    retry: false,
  });

  useEffect(() => {
    if (appData.didTrySyncWithItems.value) {
      queryClient.invalidateQueries({ queryKey: queryKey }).then(() => {
        queryClient.refetchQueries({ queryKey: queryKey });
      });
    }
  }, [appData.didTrySyncWithItems.value]);

  useEffect(() => {
    const storedJaktdag = storage.get<Jaktdag>({ id: jaktdagId }, "Jaktdag");
    let _jaktdag: Jaktdag | undefined = undefined;
    let _storedJaktdag: StorageItem<Jaktdag> | undefined = undefined;

    if (
      storedJaktdag !== undefined &&
      ["update", "create"].includes(storedJaktdag.operation || "other")
    ) {
      _storedJaktdag = storedJaktdag;
    } else if (
      appData.isOnline &&
      jaktdagQuery.data !== undefined &&
      typeof jaktdagQuery.data !== "string" &&
      "Jaktfelt" in jaktdagQuery.data
    ) {
      _jaktdag = jaktdagQuery.data as Jaktdag;
    }

    const jaktdagToSet = _storedJaktdag?.data || _jaktdag;
    if (_storedJaktdag && _jaktdag) {
      _storedJaktdag.data.Individer = _jaktdag.Individer;
    }
    if (jaktdagToSet) {
      storage.setIndividerForJaktdag(jaktdagToSet);

      setJaktdag(jaktdagToSet);
    }
  }, [appData.isOnline, jaktdagQuery.data]);

  if (jaktdagQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (!jaktdag) {
    return <div className="m-4">{t("errors.notFound", { type: "Jaktdag" })}</div>;
  }

  return (
    <>
      {jaktdag?.Art === ArtType.Elg && <RegistrerElg initialData={jaktdag} />}
      {jaktdag?.Art === ArtType.Hjort && <RegistrerHjort initialData={jaktdag} />}
    </>
  );
};

export default RedigerJaktdag;
