import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "~/src/components/Text/Text";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { OmradeType } from "~/src/helpers/types";
import { useConfirmationDialog } from "~/src/hooks/useConfirmationDialog";
import { ApiError, Favorittområde } from "../../api/types";
import { jaktdataApi } from "../../appGlobals";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";
import { ArtNames } from "../../components/Jaktomrade/ArtNames";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import UIContext from "../../contexts/UIContext/UIProvider";

const MittJaktomrade = (): JSX.Element => {
  const appData = useContext(AppDataContext);
  const ui = useContext(UIContext);
  const storage = useContext(StorageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const jaktomrade: Favorittområde = location.state.jaktomrade;
  const jaktomradeAnsvarligType =
    jaktomrade.Områdetype === OmradeType.Jaktfelt
      ? t("pages.mittJaktomrade.jaktleder")
      : t("pages.mittJaktomrade.valdansvarlig");
  const omradekode = {
    Områdekode: jaktomrade.Områdekode || "",
    Områdetype: jaktomrade.Områdetype || "",
  };

  const handleUndo = async () => {
    if (!!jaktomrade.Områdekode && !!jaktomrade.Områdetype) {
      await jaktdataApi
        .postJaktområde({ Områdekode: jaktomrade.Områdekode, Områdetype: jaktomrade.Områdetype })
        .catch((e: ApiError) => {
          if (e.axiosError?.code === "ERR_NETWORK") {
            storage.add({
              objectType: "PendingJaktomrade",
              objectKey: omradekode,
              data: { ...jaktomrade, ...omradekode },
              isPending: true,
              operation: "create",
              lastUpdated: new Date(),
            });
          } else {
            throw e;
          }
        })
        .then(() => {
          appData.jaktområder.refetch();
        });
    }
  };

  const handleDelete = async () => {
    if (jaktomrade && jaktomrade.Områdekode && jaktomrade.Områdetype) {
      // slett lokal kopi (PendingJaktomrade) hvis den eksisterer
      storage.delete(
        { Områdekode: jaktomrade.Områdekode, Områdetype: jaktomrade.Områdetype },
        "PendingJaktomrade",
      );

      await jaktdataApi
        .deleteJaktområde(omradekode)
        .catch((e: ApiError) => {
          if (e.axiosError?.code === "ERR_NETWORK") {
            storage.add({
              objectType: "PendingJaktomrade",
              objectKey: omradekode,
              data: { ...jaktomrade, ...omradekode },
              isPending: true,
              operation: "delete",
              lastUpdated: new Date(),
            });

            ui.showSnackbar({
              message: t("sync.noNetworkWillSyncLater"),
            });
            navigate("/minside");
          } else {
            navigate("/minside");
            throw e;
          }
        })
        .then(() => {
          ui.showSnackbar({ message: t("jaktomrade.slettet"), undoFn: handleUndo });
          navigate("/minside");
        })
        .finally(() => appData.jaktområder.refetch());
    }
  };

  const confirmationDialog = useConfirmationDialog({
    title: t("actions.deleteFavorite"),
    onConfirm: async () => {
      await handleDelete();
    },
  });

  if (!jaktomrade) {
    return <Text>{t("actions.notSelected")}</Text>;
  }

  return (
    <>
      <div className="flex flex-col justify-between h-[80vh] p-5">
        <div className="flex flex-col items-start gap-2 p-2 font-normal">
          <Text variant="h5" className="mb-3">
            {jaktomrade.Områdenavn}
          </Text>
          <Text variant="small" className="font-normal text-md-gray mb-3.5">
            {jaktomrade.Områdekode}
          </Text>
          <Text className="font-normal mb-2">Jakt på: {ArtNames({ ...jaktomrade })}</Text>
          <Text variant="paragraph" className="font-normal">
            {jaktomradeAnsvarligType}: {jaktomrade.AnsvarligPersonaliaNavn || "-"}
          </Text>
          <Text variant="paragraph" className="font-normal">
            {t("form.fields.epost.label")}: {jaktomrade.AnsvarligPersonaliaEpost || "-"}
          </Text>
          <Text variant="paragraph" className="font-normal mb-5">
            {t("form.fields.telefon.label")}: {jaktomrade.AnsvarligPersonaliaTlf || "-"}
          </Text>
          {jaktomrade.Områdetype === OmradeType.Jaktfelt && (
            <>
              <Text variant="paragraph" className="font-normal">
                {t("pages.mittJaktomrade.tilhørendeVald")}:{" "}
                {`${jaktomrade.TilhørerendeValdnavn} (${jaktomrade.TilhørendeValdkode})` || "-"}
              </Text>
              <Text variant="paragraph" className="font-normal">
                {t("pages.mittJaktomrade.tilhørendeKommune")}:{" "}
                {`${jaktomrade.TilhørerendeKommunenavn} (${jaktomrade.TilhørendeKommunekode})` ||
                  "-"}
              </Text>
            </>
          )}
          {jaktomrade.Områdetype === OmradeType.Vald && (
            <Text variant="paragraph" className="font-normal">
              {t("pages.mittJaktomrade.tilhørendeKommune")}:{" "}
              {`${jaktomrade.TilhørerendeKommunenavn} (${jaktomrade.TilhørendeKommunekode})` || "-"}
            </Text>
          )}
          {jaktomrade.Områdetype === OmradeType.VillreinVald && (
            <Text variant="paragraph" className="font-normal">
              {t("pages.mittJaktomrade.tilhørendeVillreinomrade")}:{" "}
              {`${jaktomrade.TilhørendeVillreinområdenavn} (${jaktomrade.TilhørendeVillreinområdekode})` ||
                "-"}
            </Text>
          )}
          {confirmationDialog.element}
        </div>
        <div className="flex flex-row items-center justify-between">
          <ButtonDefault
            id="slettFavorittButton"
            variant={ButtonVariant.Secondary}
            label={t("actions.deleteFavorite")}
            onClick={() => {
              confirmationDialog.show({ message: t("pages.mittJaktomrade.confirmDelete") });
            }}
          />
          <ButtonDefault
            id="lukkButton"
            variant={ButtonVariant.Primary}
            label={t("actions.close")}
            onClick={() => {
              navigate("/minside");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MittJaktomrade;
