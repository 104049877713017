import JaktdataApi from "../../api/jaktdataApi";
import { ApiError } from "../../api/types";
import { ticksToDate } from "../../helpers/time";
import { store } from "../StorageContext/helpers";
import { AuthData, AuthError, AuthProvider, AuthStorageKeys, Credentials } from "./AuthProvider";

export class ApiAuthProvider extends AuthProvider {
  api: JaktdataApi;
  isRefreshing: boolean;
  constructor(api: JaktdataApi) {
    super();
    this.api = api;
    this.isRefreshing = false;
  }

  async handleLogin(credentials: Credentials): Promise<AuthData> {
    await this.handleLogout();
    return this.api.login(credentials).then((tokenResponse) => {
      return new Promise<AuthData>((resolve, reject) => {
        const auth = {
          name: credentials.Jegernummer,
          token: tokenResponse.AccessToken as string,
          refreshToken: tokenResponse.RefreshToken as string,
          tokenExpiresAt: ticksToDate(tokenResponse.AccessTokenExpiration!),
          refreshTokenExpiresAt: ticksToDate(tokenResponse.RefreshTokenExpiration!),
        };
        try {
          store(AuthStorageKeys.authData, auth);
          resolve(auth);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  async handleRefresh(): Promise<AuthData> {
    const authData = this.authData();
    if (!authData) {
      throw new AuthError("MissingAuthData");
    }
    if (this.isRefreshing) {
      return authData;
    }
    this.isRefreshing = true;
    return this.api
      .refreshToken()
      .then((tokenResponse) => {
        this.isRefreshing = false;
        return new Promise<AuthData>((resolve, reject) => {
          const auth = {
            name: authData.name,
            token: tokenResponse.AccessToken as string,
            refreshToken: tokenResponse.RefreshToken as string,
            tokenExpiresAt: ticksToDate(tokenResponse.AccessTokenExpiration!),
            refreshTokenExpiresAt: ticksToDate(tokenResponse.RefreshTokenExpiration!),
          };
          try {
            store(AuthStorageKeys.authData, auth);
            resolve(auth);
          } catch (e) {
            reject(e);
          }
        });
      })
      .catch((e: ApiError) => {
        this.isRefreshing = false;
        throw new AuthError(e.statusCode === 400 ? "TokenExpired" : "Unknown");
      });
  }
}
