import { NetworkMode } from "@tanstack/react-query";
import { Duration } from "date-fns";
// import * as env from "env-var";
/**
 * Global and environment-specific config (dev, prod, feature, etc.)
 */
export type AppConfig = {
  jaktdataApiBaseUrl: string;
  networkTimeoutLimit: Duration;
  reactQuery: {
    defaultStaleTime: Duration; // hvor lenge query resultater er gyldig før de invalideres
    networkMode: NetworkMode;
    maxRetries: number;
  };
  urls: {
    personvernerklaering: string;
  };
};

const defaultConfig: AppConfig = {
  jaktdataApiBaseUrl: import.meta.env.VITE_API_URL,
  networkTimeoutLimit: { seconds: 5 },
  reactQuery: {
    defaultStaleTime: { seconds: 60 },
    networkMode: "offlineFirst",
    maxRetries: 3,
  },
  urls: {
    personvernerklaering: "https://www.hjorteviltregisteret.no/personvern",
  },
};

export const appConfig = defaultConfig;
