export default function CounterChip({
  label,
  count,
  fill = true,
}: {
  label: string;
  count: number;
  fill?: boolean;
}): JSX.Element {
  let styles = "";
  if (fill) styles += " bg-md-lysegronn";
  else styles += " bg-md-creme-medium";

  return (
    <div
      className={
        "px-2 py-1 rounded-lg text-xs flex items-center gap-2 justify-center uppercase flex-grow" +
        styles
      }
    >
      {label} <span className="text-lg font-semibold">{count}</span>
    </div>
  );
}
