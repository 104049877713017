import { AppStorage } from "~/src/contexts/StorageContext/StorageProvider";
import { IndividData } from "../schemas/schema";

type HandleOfflineIndividProps = {
  mode: "create" | "update";
  formData: IndividData;
  storage: AppStorage;
};

export const handleOfflineIndividCreateOrUpdate = ({
  mode,
  storage,
  formData,
}: HandleOfflineIndividProps) => {
  // if create individ is in queue, update existing entry and keep operation as create
  const storedIndivid = storage.get<IndividData>({ id: formData.Id }, "Individ");

  storage.add({
    objectType: "Individ",
    objectKey: { id: formData.Id },
    data: formData,
    isPending: true,
    operation: storedIndivid ? storedIndivid.operation : mode,
    lastUpdated: new Date(),
  });
};
