import { format } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import { AccordionDefault } from "../../../components/Accordion/AccordionDefault";
import CalendarPicker from "../../../components/CalendarPicker/CalendarPicker";
import { ComboboxOption } from "../../../components/Form/Combobox/Combobox";
import JaktomradePicker from "../../../components/JaktomradePicker/JaktomradePicker";
import { useFormDataContext } from "../../../contexts/FormDataContext/FormDataContext";
import { ArtType } from "../../../helpers/types";
import { Stateful, useStateful } from "../../../hooks/useStateful";
import { IndividFormDataContext } from "../components/IndividForm";

type Props = {
  selectedState: Stateful<ComboboxOption> | undefined;
};

export default function DatoOgJaktomrade({ selectedState }: Props): JSX.Element {
  const { t } = useTranslation();
  const { formData, setFormData, formErrors } = useFormDataContext(IndividFormDataContext);
  const storedDate = new Date(formData?.Dato || new Date());
  const dato = useStateful<Date>(storedDate);

  useEffect(() => {
    setFormData({
      ...formData,
      Dato: format(dato.value, dateFormatISO),
    });
  }, [dato.value]);

  useEffect(() => {
    selectedState?.value && setJaktomrade(selectedState.value.id, selectedState.value.label);
  }, [selectedState?.value]);

  const setJaktomrade = (id: string, _label: string) => {
    switch (formData.Art) {
      case ArtType.Rådyr:
        setFormData({ ...formData, Vald: id });
        break;
      case ArtType.Villrein:
        setFormData({ ...formData, Villreinvald: id });
        break;
      case ArtType.Hjort:
      case ArtType.Elg:
        setFormData({ ...formData, Jaktfelt: id });
        break;
    }
  };
  return (
    <AccordionDefault headerContent={t("pages.registrerArt.datoOgJaktomrade")}>
      <div className="pt-2 pb-6">
        <CalendarPicker
          id="registrerArtDatePicker"
          label={t("form.fields.date.label")}
          date={dato}
        />
      </div>
      <JaktomradePicker
        selectedState={selectedState}
        onChange={(selected) => setJaktomrade(selected?.id || "", selected?.label || "")}
        valgtArt={formData.Art}
        defaultOmradekode={formData.Vald || formData.Villreinvald || formData.Jaktfelt || ""}
        errorText={formErrors?.Vald || formErrors?.Villreinvald || formErrors?.Jaktfelt || ""}
        helperText={t("form.fields.vald.helperText")}
      />
    </AccordionDefault>
  );
}
