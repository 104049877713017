import ElgIcon from "../assets/img/elg.svg?react";
import HjortIcon from "../assets/img/hjort.svg?react";
import Horn from "../assets/img/horn.svg?react";
import RadyrIcon from "../assets/img/radyr.svg?react";
import ReinsdyrIcon from "../assets/img/reinsdyr.svg?react";
import VillsvinIcon from "../assets/img/villsvin.svg?react";

import { ArtType } from "./types";

export const artIcon = (art: ArtType): JSX.Element => {
  switch (art) {
    case ArtType.Elg:
      return <ElgIcon />;
    case ArtType.Hjort:
      return <HjortIcon />;
    case ArtType.Rådyr:
      return <RadyrIcon />;
    case ArtType.Villrein:
      return <ReinsdyrIcon />;
    case ArtType.Villsvin:
      return <VillsvinIcon />;
  }
};

export const ArtIcons = ({
  JaktPåHjort,
  JaktPåElg,
  JaktPåRådyr,
  JaktPåVillrein,
  JaktPåVillsvin,
}: {
  JaktPåHjort?: boolean;
  JaktPåElg?: boolean;
  JaktPåRådyr?: boolean;
  JaktPåVillrein?: boolean;
  JaktPåVillsvin?: boolean;
}): JSX.Element => {
  return (
    <div className="flex flex-row items-center">
      {JaktPåHjort && artIcon(ArtType.Hjort)}
      {JaktPåElg && artIcon(ArtType.Elg)}
      {JaktPåRådyr && artIcon(ArtType.Rådyr)}
      {JaktPåVillrein && artIcon(ArtType.Villrein)}
      {JaktPåVillsvin && artIcon(ArtType.Villsvin)}
    </div>
  );
};

export const HornIcon = ({ size, className }: { size: string; className: string }): JSX.Element => {
  return <Horn className={className} style={{ width: `${size}`, height: `${size}` }} />;
};
