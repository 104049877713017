import CircularProgress from "@mui/material/CircularProgress";

import { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ErrorAlert from "../Alerts/ErrorAlert";
import ButtonDefault from "../Button/ButtonDefault";

type RenderQueryResultProps = {
  query: UseQueryResult;
  children: React.ReactNode;
} & React.ComponentProps<"div">;

export default function RenderQueryResult({
  query,
  children,
  ...restProps
}: RenderQueryResultProps): JSX.Element {
  const { t } = useTranslation();
  const id = "RenderQuery";
  const renderError = (error: Error) => {
    return (
      <div id="QueryErrorAlert" {...restProps}>
        <ErrorAlert text={error.message} />
        <ButtonDefault
          id={`${id}RetryButton`}
          label={t("actions.retry")}
          onClick={async () => query.refetch()}
        />
      </div>
    );
  };

  const renderSpinner = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-center">
          <CircularProgress color="success" />
        </div>
      </div>
    );
  };

  return (
    <div id={id} {...restProps}>
      {query.isSuccess
        ? children
        : query.isError && !query.isRefetching
          ? renderError(query.error)
          : renderSpinner()}
    </div>
  );
}
