import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { AccordionDefault } from "../../../components/Accordion/AccordionDefault";
import Combobox, { ComboboxOption } from "../../../components/Form/Combobox/Combobox";
import Input from "../../../components/Form/Input/Input";
import { RadioButton, RadioGroup } from "../../../components/Form/Radio";
import Text from "../../../components/Text/Text";
import { AlderType, ArtType, KjønnType } from "../../../helpers/types";
import { useDialog } from "../../../hooks/useDialog";
import HelpButton from "../components/HelpButton";
import { IndividFormDataContext } from "../components/IndividForm";
import { KjonnAlderType, MalemetodeSlaktevekt, Terreng, kjonnAlderToAlderAndKjonn } from "../types";

const getKjonnAlderOptions = (art: ArtType) => {
  const { t } = useTranslation();
  const artLowerCase = art.toLowerCase();

  const kjonnAlderTypes = Object.values(KjonnAlderType).filter(
    (value) => ArtType.Rådyr !== art || value.split("-")[1] !== AlderType.Ungdyr,
  );

  const options = kjonnAlderTypes.map((value) => ({
    value,
    text: t(`pages.registrerArt.${artLowerCase}.kjonnAlderOptions.${value}`),
  }));
  return options;
};

type OmSkuttDyrProps = {
  jaktdagGodkjent?: boolean;
};

export default function OmSkuttDyr({ jaktdagGodkjent }: OmSkuttDyrProps): JSX.Element {
  const { t } = useTranslation();
  const { formData, formErrors, setFormData } = useFormDataContext(IndividFormDataContext);
  const navigate = useNavigate();
  const location = useLocation();

  const helpDialog = useDialog({
    title: t("pages.registrerArt.omSkuttDyr.slaktevekt"),
    message: t("pages.registrerArt.omSkuttDyr.slaktevektHelpMessage"),
  });
  const handleSelectKjonnAlder = (selected: ComboboxOption) => {
    if (!selected) return;

    const [kjonn, alder] = kjonnAlderToAlderAndKjonn(selected.id as KjonnAlderType);

    const _formData = { ...formData, Kjønn: kjonn, Alder: alder };

    if (!showMelkIJuret(kjonn, alder)) {
      _formData.MelkIJuret = undefined;
      _formData.AntallKalverIfølgeMedHunndyret = undefined;
    }
    if (!showGevirtakker(formData.Art, kjonn, alder)) {
      _formData.GevirtakkerVenstre = undefined;
      _formData.GevirtakkerHøyre = undefined;
    }
    if (alder !== AlderType.Kalv) {
      _formData.FeltKalvIkkeIfølgeMedMorDyr = undefined;
    }

    setFormData(_formData);
  };
  const showGevirtakker = (art: ArtType, kjønn?: KjønnType, alder?: AlderType): boolean => {
    if (!kjønn || !alder) return false;
    if (alder === AlderType.Kalv) return false;
    if (kjønn === KjønnType.Hann) return true;
    if (art === ArtType.Villrein) return true;
    return false;
  };

  const showMelkIJuret = (kjonn?: KjønnType, alder?: AlderType): boolean => {
    if (!kjonn || !alder) return false;
    if (alder === AlderType.Kalv) return false;
    return kjonn === KjønnType.Hunn;
  };

  const gevirTakkerRange = Array.from(Array(31).keys());

  const setTerreng = (terreng: Terreng) => setFormData({ ...formData, Terreng: terreng });
  const setMelkIJuret = (melk?: boolean) => setFormData({ ...formData, MelkIJuret: melk });
  const setMalemetodeSlaktevektKg = (metode: MalemetodeSlaktevekt) =>
    setFormData({ ...formData, MålemetodeSlaktevekt: metode });
  const setMalemetodeIkkeVeid = () =>
    setFormData({
      ...formData,
      MålemetodeSlaktevekt: MalemetodeSlaktevekt.IkkeVeid,
      SlaktevektKg: undefined,
    });
  const setSlaktevektKg = (vekt: number | undefined) =>
    setFormData({ ...formData, SlaktevektKg: vekt });
  const setGevirtakkerVenstre = (gevirtakker: string) =>
    setFormData({
      ...formData,
      GevirtakkerVenstre: gevirtakker ? Number.parseInt(gevirtakker) : null,
    });
  const setGevirtakkerHøyre = (gevirtakker: string) =>
    setFormData({
      ...formData,
      GevirtakkerHøyre: gevirtakker ? Number.parseInt(gevirtakker) : null,
    });
  const setKontrollKort = (kontrollkort: string) =>
    setFormData({ ...formData, Kontrollkort: kontrollkort });

  const hvorSkutt =
    formData.Terreng === Terreng.Innmark
      ? t("pages.registrerArt.omSkuttDyr.hvorInnmark")
      : formData.Terreng === Terreng.Utmark
        ? t("pages.registrerArt.omSkuttDyr.hvorUtmark")
        : undefined;

  const isSvalbard = formData.Villreinvald?.startsWith("2300");

  const kjonnAlderOptions = getKjonnAlderOptions(formData.Art).map((opt) => ({
    id: opt.value,
    label: opt.text,
  }));

  const kjonnAlder = formData.Kjønn && formData.Alder ? formData.Kjønn + "-" + formData.Alder : "";
  const defaultKjonnAlder = kjonnAlderOptions.find(
    (option: ComboboxOption) => option.id === kjonnAlder,
  );

  const gevirtakkerOptions = gevirTakkerRange.map((antall) => ({
    id: antall.toString(),
    label: antall.toString(),
  }));
  const defaultGevirtakkerVenstre = gevirtakkerOptions.find(
    (option) => option.id === formData.GevirtakkerVenstre?.toString(),
  );
  const defaultGevirtakkerHoyre = gevirtakkerOptions.find(
    (option) => option.id === formData.GevirtakkerHøyre?.toString(),
  );

  return (
    <AccordionDefault headerContent={t("pages.registrerArt.omSkuttDyr.title")} defaultOpen>
      {formData.Art === ArtType.Hjort &&
        (((formData.Jaktdag?.Utmark || false) && (formData.Jaktdag?.Innmark || false) && (
          <div>
            <Text variant="h6">{t("pages.registrerArt.hjort.velgTerreng")}</Text>
            <div className="flex flex-row gap-x-10">
              <RadioButton
                label={t("pages.fellingsinfo.innmark")}
                onChange={() => setTerreng(Terreng.Innmark)}
                checked={formData.Terreng === Terreng.Innmark}
                disabled={jaktdagGodkjent}
              />
              <RadioButton
                label={t("pages.fellingsinfo.utmark")}
                onChange={() => setTerreng(Terreng.Utmark)}
                checked={formData.Terreng === Terreng.Utmark}
                disabled={jaktdagGodkjent}
              />
            </div>
          </div>
        )) || (
          <div className="flex flex-row items-center p-2">
            <Text>{t("pages.registrerArt.omSkuttDyr.skuttHvor", { hvor: hvorSkutt })}</Text>
            <HelpButton
              title={""}
              onClick={() => {
                helpDialog.show({
                  title: formData.Jaktdag?.Utmark
                    ? t("pages.fellingsinfo.utmark")
                    : t("pages.fellingsinfo.innmark"),
                  message: t("pages.registrerArt.omSkuttDyr.hvorSkuttHelpMessage", {
                    hvor: hvorSkutt,
                  }),
                });
              }}
            />
          </div>
        ))}
      <div className="flex space-x-2 items-center pb-4">
        <Combobox
          defaultValue={defaultKjonnAlder}
          label={t("pages.registrerArt.omSkuttDyr.kjonnOgAlderLabel")}
          onChange={(option) => handleSelectKjonnAlder(option as ComboboxOption)}
          options={kjonnAlderOptions}
          disabled={jaktdagGodkjent}
          errorHelpText={formErrors?.Kjønn || formErrors?.Alder}
          readOnlyInput={true}
        />

        {[ArtType.Elg, ArtType.Hjort].includes(formData.Art) && (
          <HelpButton
            title={t("pages.registrerArt.omSkuttDyr.kjonnAlderHelpButtonText")}
            onClick={() =>
              navigate("/minside/aldersbestemmelse", {
                state: { referrer: location.pathname, art: formData.Art },
              })
            }
          />
        )}
      </div>
      <Text variant="h6">{t("pages.registrerArt.omSkuttDyr.malemetodeSlaktevekt")}</Text>
      <RadioGroup>
        <div>
          <div className="flex gap-x-10">
            <RadioButton
              label={t("pages.registrerArt.omSkuttDyr.veid")}
              onChange={() => setMalemetodeSlaktevektKg(MalemetodeSlaktevekt.Veid)}
              checked={formData.MålemetodeSlaktevekt === "Veid"}
            />
            <RadioButton
              label={t("pages.registrerArt.omSkuttDyr.anslatt")}
              onChange={() => setMalemetodeSlaktevektKg(MalemetodeSlaktevekt.Anslatt)}
              checked={formData.MålemetodeSlaktevekt === "Anslått"}
            />
          </div>
          <div className="mt-5">
            <RadioButton
              label={t("pages.registrerArt.omSkuttDyr.ikkeVeid")}
              onChange={() => {
                setMalemetodeIkkeVeid();
              }}
              checked={formData.MålemetodeSlaktevekt === "IkkeVeid"}
            />
          </div>
        </div>
      </RadioGroup>
      {formData.MålemetodeSlaktevekt &&
        formData.MålemetodeSlaktevekt !== MalemetodeSlaktevekt.IkkeVeid && (
          <div className="flex space-x-2 justify-between items-center pt-4">
            <div className="w-full">
              <Input
                id="input-slaktevekt"
                label={t("pages.registrerArt.omSkuttDyr.slakteVektLabel")}
                inputMode="numeric"
                maxLength={3}
                defaultValue={
                  Number.isNaN(formData.SlaktevektKg) ? "" : formData.SlaktevektKg ?? ""
                }
                step={1}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value);
                  const safe = Number.isNaN(parsed) ? undefined : parsed;
                  setSlaktevektKg(safe);
                }}
                errorMessage={formErrors?.SlaktevektKg}
              ></Input>
            </div>
            <HelpButton
              title={t("pages.registrerArt.omSkuttDyr.slaktevekt")}
              onClick={() => helpDialog.show()}
            />
          </div>
        )}
      {showGevirtakker(formData.Art, formData.Kjønn, formData.Alder) && (
        <>
          <Text variant="h6">{t("pages.registrerArt.omSkuttDyr.antallGevirtakker")}</Text>
          <div className="flex space-x-2 pb-4">
            <Combobox
              defaultValue={defaultGevirtakkerVenstre}
              label={t("pages.registrerArt.omSkuttDyr.gevirtakkerVenstreLabel")}
              onChange={(e) => setGevirtakkerVenstre(e?.label as string)}
              options={gevirtakkerOptions}
              inputMode="number"
            />

            <Combobox
              defaultValue={defaultGevirtakkerHoyre}
              label={t("pages.registrerArt.omSkuttDyr.gevirtakkerHoyreLabel")}
              onChange={(e) => setGevirtakkerHøyre(e?.label as string)}
              options={gevirtakkerOptions}
              inputMode="number"
            />
          </div>
        </>
      )}
      {showMelkIJuret(formData.Kjønn, formData.Alder) && (
        <div className="pb-4">
          <Text variant="h6">{t("pages.registrerArt.omSkuttDyr.melkIJuret.title")}</Text>
          <RadioGroup>
            <RadioButton
              label={t("pages.registrerArt.omSkuttDyr.melkIJuret.ja")}
              onChange={() => setMelkIJuret(true)}
              checked={formData.MelkIJuret === true}
            />
            <RadioButton
              label={t("pages.registrerArt.omSkuttDyr.melkIJuret.nei")}
              onChange={() => setMelkIJuret(false)}
              checked={formData.MelkIJuret === false}
            />
          </RadioGroup>
        </div>
      )}
      {formData.Art === ArtType.Villrein && (
        <>
          <Input
            id="input-kontrollkort"
            label={t("pages.registrerArt.omSkuttDyr.kontrollKortNummerLabel")}
            maxLength={isSvalbard ? 7 : 12}
            defaultValue={formData.Kontrollkort ?? ""}
            onChange={(e) => setKontrollKort(e.target.value)}
            errorMessage={formErrors?.Kontrollkort}
          />
        </>
      )}
      {helpDialog.element}
    </AccordionDefault>
  );
}
