import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { removeAll, retrieve, store } from "~/src/contexts/StorageContext/helpers";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { ConfirmationButtonsProps } from "~/src/hooks/useConfirmationDialog";
import { DialogHook, DialogShowProps, UseDialogProps, useDialog } from "~/src/hooks/useDialog";
import { FormDataWrapper } from "~/src/hooks/useFormData";
import { getFormDataStorageKey } from "~/src/pages/Registrering/Jaktdag/common/helpers";
import { IndividData } from "~/src/pages/Registrering/schemas/schema";
import ButtonDefault, { ButtonLayout, ButtonVariant } from "../components/Button/ButtonDefault";
import { DialogAlignment } from "../components/Dialog/DialogDefault";

type DraftConfirmationButtonsProps = {
  onSave?: () => void;
  onCancel?: () => void;
} & ConfirmationButtonsProps;

type UseDraftConfirmationDialogProps = DraftConfirmationButtonsProps & UseDialogProps;

export type DraftConfirmationDialogHook = Omit<DialogHook, "show"> & {
  show: (props?: DialogShowProps & DraftConfirmationButtonsProps) => void;
};

export function useDraftConfirmationDialog({
  title = undefined,
  message = undefined,
  onConfirm = undefined,
  onCancel = undefined,
  onSave = undefined,
  confirmButtonText = undefined,
  cancelButtonText = "Avbryt",
  buttonLayout = ButtonLayout.Horizontal,
  alignment = DialogAlignment.Start,
}: UseDraftConfirmationDialogProps): DraftConfirmationDialogHook {
  const { showSnackbar } = useContext(UIContext);
  const storageKey = getFormDataStorageKey();
  const { t } = useTranslation();
  const dialog = useDialog({ title, message, buttonLayout, alignment });
  const confirmationButtons = (props?: DraftConfirmationButtonsProps) => {
    return (
      <>
        <ButtonDefault
          id="CancelButton"
          variant={ButtonVariant.Secondary}
          onClick={() => {
            dialog.close();
            (props?.onCancel && props.onCancel()) || (onCancel && onCancel());
          }}
          label={props?.cancelButtonText || cancelButtonText || t("actions.cancel")}
          className="ml-2 mr-2"
        />
        <ButtonDefault
          id="SaveDraftButton"
          variant={ButtonVariant.SecondaryWhite}
          onClick={() => {
            dialog.close();
            const draft = retrieve<FormDataWrapper<unknown>>(storageKey);
            if (draft !== undefined) {
              draft.isDraft = true;
              store(storageKey, draft);
            }
            (props?.onSave && props.onSave()) || (onSave && onSave());
          }}
          label={t("pages.registrering.dialogs.avbryt.saveAsDraftButtonText")}
          className="mr-2"
        />
        <ButtonDefault
          id="ConfirmButton"
          variant={ButtonVariant.Primary}
          onClick={() => {
            if (retrieve<IndividData>(storageKey)) {
              showSnackbar({
                title: t("pages.registrering.dialogs.draftRemoved.title"),
                message: t("pages.registrering.dialogs.draftRemoved.message"),
              });
            }
            removeAll([storageKey]);
            dialog.close();
            (props?.onConfirm && props.onConfirm()) || (onConfirm && onConfirm());
          }}
          label={props?.confirmButtonText || confirmButtonText || t("actions.confirm")}
          className="mr-2"
        />
      </>
    );
  };

  return {
    ...dialog,
    show: (props?: DialogShowProps & DraftConfirmationButtonsProps) => {
      dialog.show({ ...props, ...{ buttons: props?.buttons || confirmationButtons(props) } });
    },
  };
}
