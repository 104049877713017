import { useQueryClient } from "@tanstack/react-query";
import * as fuzzysort from "fuzzysort";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "~/src/components/Text/Text";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { JaktomradeResponse, Område, OmrådeData, Områdekode } from "../../api/types";
import { jaktdataApi } from "../../appGlobals";
import { ArtButtons } from "../../components/Button/ArtButtons";
import ButtonDefault, { ButtonVariant } from "../../components/Button/ButtonDefault";
import InputFilterDefault from "../../components/Form/Input/InputFilterDefault";
import JaktområdeDefault from "../../components/Jaktomrade/Jaktomrade";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import { harJaktPaArt } from "../../helpers/filters";
import { ArtType } from "../../helpers/types";
import { useStateful } from "../../hooks/useStateful";

export default function (): JSX.Element {
  const { t, i18n } = useTranslation();
  const art = useStateful<ArtType | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const appData = React.useContext(AppDataContext);
  const navigate = useNavigate();
  const områdeData = useStateful<OmrådeData | undefined>(undefined);
  const filteredData = useStateful<Område[]>([]);
  const filterValue = useStateful<string>("");
  const valgteOmråder = useStateful<Områdekode[]>([]);
  const isSending = useStateful<boolean>(false);
  const ui = useContext(UIContext);
  const storage = useContext(StorageContext);
  const queryClient = useQueryClient();

  // TODO: maxItems kan settes dynamisk med ref for div::
  //   https://stackoverflow.com/questions/60881446/receive-dimensions-of-element-via-getboundingclientrect-in-react
  const maxItems = 25;

  const hasMatchingFilters = (entry: Område) => {
    const navnOrIdMatch =
      entry.Navn?.toLowerCase().includes(filterValue.value.toLowerCase()) ||
      entry.Id?.toLowerCase().includes(filterValue.value.toLowerCase());
    let typeSpecificMatch = false;
    if ("ValdId" in entry) {
      typeSpecificMatch = entry.ValdId!.includes(filterValue.value.toLowerCase());
    }
    return navnOrIdMatch || typeSpecificMatch;
  };

  useEffect(() => {
    switch (art.value) {
      case ArtType.Elg:
      case ArtType.Hjort:
        områdeData.set(appData.jaktfelt.data);
        break;
      case ArtType.Rådyr:
        områdeData.set(appData.vald.data);
        break;
      case ArtType.Villrein:
        områdeData.set(appData.villreinvald.data);
        break;
      default:
        områdeData.set(undefined);
    }
  }, [
    art.value,
    områdeData.set,
    appData.jaktfelt.data,
    appData.vald.data,
    appData.villreinvald.data,
  ]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }, [art.value]);

  useEffect(() => {
    if (områdeData.value !== undefined && områdeData.value.data !== undefined) {
      const områdeDataFiltered = områdeData.value.data.filter((entry: Område) => {
        return harJaktPaArt(art.value, entry) && hasMatchingFilters(entry);
      });
      filteredData.set(
        fuzzysort
          .go(filterValue.value, områdeDataFiltered, { keys: ["Id", "Navn", "ValdId"] })
          .map((result) => {
            return result.obj;
          })
          .slice(0, maxItems),
      );
    }
  }, [områdeData.value, filterValue.value, art.value, filteredData.set]);

  const områdeEntry = (områdeEntry: Område) => {
    return (
      <JaktområdeDefault
        key={`omrade-${områdeEntry.Id}`}
        områdeEntry={områdeEntry}
        omradeType={områdeData.value?.type}
        onClick={async () => {
          if (områdeEntry.Id && områdeData.value?.type) {
            const alreadyAdded = valgteOmråder.value.some((v) => v.Områdekode === områdeEntry.Id);
            if (alreadyAdded) {
              valgteOmråder.set(valgteOmråder.value.filter((v) => v.Områdekode !== områdeEntry.Id));
            } else {
              valgteOmråder.set([
                ...valgteOmråder.value,
                {
                  Områdekode: områdeEntry.Id,
                  Områdetype: områdeData.value?.type,
                },
              ]);
            }
          }
        }}
      />
    );
  };

  const createPendingJaktomradeRequest = (områdekode: Områdekode) => {
    const omrade = områdeData.value?.data.find((omrade) => omrade.Id === områdekode.Områdekode);
    if (omrade) {
      storage.add({
        objectType: "PendingJaktomrade",
        objectKey: områdekode,
        data: { ...omrade, ...områdekode },
        isPending: true,
        operation: "create",
        lastUpdated: new Date(),
      });
    } else {
      ui.showSnackbar({
        message: t("errors.unexpectedError", { details: t("errors.missingData") }),
      });
    }
  };

  const sendJaktområder = async () => {
    if (valgteOmråder.value.length > 0) {
      isSending.set(true);
      const responses = new Array<JaktomradeResponse>();
      valgteOmråder.value.forEach((områdekode) => {
        // TODO: Endre til react-query mutation
        jaktdataApi
          .postJaktområde(områdekode)
          .then((response) => {
            responses.push(response);
          })
          .catch(() => {
            ui.showSnackbar({ message: t("errors.notSynchronizedYet") });
            createPendingJaktomradeRequest(områdekode);
          });
      });
      isSending.set(false);
    }
  };

  return (
    <div data-testid="jaktomrader-testid">
      <div>
        {art.value == undefined && (
          <Text variant="h5" className="ml-4 mt-4 p-2">
            {t("pages.jaktområder.velgArt")}
          </Text>
        )}
        <ArtButtons selected={art} />
      </div>
      {art.value !== undefined && (
        <div className="flex flex-col justify-start m-2 p-2 gap-2 items-start">
          <InputFilterDefault
            id="jaktomraderSearchFilterInput"
            ref={inputRef}
            label={`${t("pages.jaktområder.search")}${ArtType[art.value].toLowerCase()}`}
            onChange={(e) => filterValue.set(e.target.value)}
          />
          <Text variant="small">{t("pages.jaktområder.searchResult")}</Text>
          <div className="w-full overflow-y-scroll">
            {filteredData.value &&
              filteredData.value.length > 0 &&
              filteredData.value
                .sort((a, b) => {
                  if (a.Navn && b.Navn) {
                    return a.Navn.localeCompare(b.Navn, i18n.language);
                  } else {
                    return 0;
                  }
                })
                .map((o) => {
                  return områdeEntry(o);
                })}
          </div>
          <div className="w-full flex flex-row justify-evenly">
            <ButtonDefault
              id="jaktområderAvbrytButton"
              label={t("actions.cancel")}
              variant={ButtonVariant.Secondary}
              onClick={async () => {
                valgteOmråder.set([]);
                art.set(undefined);
              }}
            ></ButtonDefault>
            <ButtonDefault
              id="jaktområderLeggTilButton"
              label={`${t("actions.add")} ${
                valgteOmråder.value.length > 0 ? `(${valgteOmråder.value.length})` : ""
              }`}
              disabled={valgteOmråder.value.length <= 0}
              onClick={async () => {
                await sendJaktområder().then(() => {
                  queryClient.invalidateQueries({ queryKey: ["jaktomrader"] }).then(() => {
                    appData.jaktområder.refetch().then(() => {
                      navigate("/minside");
                    });
                  });
                });
              }}
              loading={isSending.value}
            ></ButtonDefault>
          </div>
        </div>
      )}
    </div>
  );
}
