import React from "react";

export class Stateful<T> {
  public set(value: T): void {
    this._setValue(value);
  }

  public get value(): T {
    return this._value;
  }

  public constructor(
    protected _value: T,
    protected _setValue: React.Dispatch<React.SetStateAction<T>>,
  ) {}
}

/** Wrapper for React.useState which returns a Stateful object instead of an array */
export function useStateful<T>(initialValue: T): Stateful<T> {
  const [value, setValue] = React.useState<T>(initialValue);
  return new Stateful<T>(value, setValue);
}
