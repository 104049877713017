import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { removeAll, retrieve, store } from "~/src/contexts/StorageContext/helpers";
import { Stateful, useStateful } from "~/src/hooks/useStateful";
import { getFormDataStorageKey } from "~/src/pages/Registrering/Jaktdag/common/helpers";

export type FormDataWrapper<T> = {
  data: T;
  isDraft: boolean;
};

/**
 * A custom React hook for persisting state in localStorage.
 *
 * This hook abstracts the process of saving, retrieving, and resetting state
 * that is stored in the browser's localStorage. It is useful for persisting
 * user preferences or other data across browser sessions.
 */

export default function useFormData<T>(
  initialFormData?: T,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void, Stateful<boolean>] {
  const storageKey = getFormDataStorageKey();
  const isDraft = useStateful(false);
  const [formData, setFormData] = useState<T>(() => {
    const savedData = retrieve<FormDataWrapper<T>>(storageKey);
    savedData && isDraft.set(savedData.isDraft);
    return savedData?.data ?? (initialFormData as T);
  });

  const isEmpty = (state: unknown) => {
    return !state || JSON.stringify(state) === JSON.stringify({});
  };

  useEffect(() => {
    if (isEmpty(initialFormData) || isEmpty(formData) || isEqual(initialFormData, formData)) {
      removeFormData();
    } else if (!isEqual(initialFormData, formData)) {
      store(storageKey, { data: formData, isDraft: isDraft.value });
    }
  }, [storageKey, formData, isDraft.value]);
  const removeFormData = () => {
    removeAll([storageKey]);
  };
  return [formData, setFormData, removeFormData, isDraft];
}
