import { UTCDate } from "@date-fns/utc";
import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { JaktdagResponse } from "~/src/api/types";
import { AppData } from "~/src/contexts/AppDataContext/DataProvider";
import { AppStorage } from "~/src/contexts/StorageContext/StorageProvider";
import { ShowSnackbarProps } from "~/src/contexts/UIContext/UIProvider";

export const getFormDataStorageKey = (formStateId?: string) => {
  return "formState-" + location.pathname + (formStateId || "");
};

export const removeJaktdagDraft = (
  storageKey: string,
  navigate: NavigateFunction,
  showSnackbar: (props: ShowSnackbarProps) => void,
) => {
  try {
    localStorage.removeItem(storageKey);
  } catch (e) {
    console.error("Error removing draft", e);
  }
  navigate("/registrering");
  showSnackbar({
    title: t("pages.registrering.dialogs.draftRemoved.title"),
    message: t("pages.registrering.dialogs.draftRemoved.message"),
  });
};

type HandleOfflineProps = {
  mode: "create" | "update";
  jaktdagToStore: JaktdagResponse;
  appData: AppData;
  storage: AppStorage;
};
export const handleOfflineJaktdagCreateOrUpdate = ({
  mode,
  jaktdagToStore,
  appData,
  storage,
}: HandleOfflineProps) => {
  if (mode === "create") {
    jaktdagToStore.RegistrertAv = appData.jegeropplysninger.data?.Navn;

    jaktdagToStore.Individer?.forEach((individ) => {
      individ.Jegernummer = appData.jegeropplysninger.data?.Jegernummer;
      individ.RegistrertAv = appData.jegeropplysninger.data?.Navn;
      individ.RegistrertDato = new Date().toISOString();

      storage.add({
        objectType: "Individ",
        objectKey: { id: individ.Id },
        data: individ,
        isPending: true,
        operation: "create",
        lastUpdated: new Date(),
      });
    });

    jaktdagToStore.Individer = [];

    storage.add({
      objectType: "Jaktdag",
      objectKey: { id: jaktdagToStore.Id },
      data: jaktdagToStore,
      isPending: true,
      operation: "create",
      lastUpdated: new Date(),
    });
  } else if (mode === "update") {
    // if create jaktdag is in queue, update existing entry and keep operation as create
    const storedJaktdag = storage.get<JaktdagResponse>({ id: jaktdagToStore.Id }, "Jaktdag");

    jaktdagToStore.Individer?.forEach((individ) => {
      // if individ is already created, skip (those without Uri are not created yet)
      if (individ.Uri) return;

      individ.Jegernummer = appData.jegeropplysninger.data?.Jegernummer;
      individ.RegistrertAv = appData.jegeropplysninger.data?.Navn;
      individ.RegistrertDato = new Date().toISOString();

      storage.add({
        objectType: "Individ",
        objectKey: { id: individ.Id },
        data: individ,
        isPending: true,
        operation: "create",
        lastUpdated: new UTCDate(),
      });
    });

    jaktdagToStore.Individer = [];

    storage.add({
      objectType: "Jaktdag",
      objectKey: { id: jaktdagToStore.Id },
      data: jaktdagToStore,
      isPending: true,
      operation: storedJaktdag?.operation || "update",
      lastUpdated: new Date(),
    });
  }
};
