import { Radio as MUIRadio, RadioProps as MUIRadioProps } from "@material-tailwind/react";
import { PropsWithChildren } from "react";

export type RadioButtonProps = Omit<MUIRadioProps, "ref">;

export function RadioButton(props: RadioButtonProps): JSX.Element {
  return <MUIRadio {...props} crossOrigin={""} labelProps={{ className: "font-normal" }} />;
}

export function RadioGroup({ children }: PropsWithChildren): JSX.Element {
  return <div className="flex gap-10">{children}</div>;
}
