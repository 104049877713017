import { t } from "i18next";
import { IndividResponse } from "../api/types";

export const getKjonnOgAlderByArt = (individ: IndividResponse): string => {
  return t(
    `pages.registrerArt.${individ.Art?.toLowerCase()}.kjonnAlderOptions.${individ.Kjønn}-${
      individ.Alder
    }`,
  );
};
