import { faCircleUser, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomNavigation, BottomNavigationProps } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import NavigationButtonDefault from "./NavigationButtonDefault";

export type NavigationDefaultProps = Omit<BottomNavigationProps, "ref" | "children"> & {
  id: string | number;
  ref?: React.Ref<HTMLDivElement>;
};

const NavigationDefault: React.ForwardRefRenderFunction<HTMLDivElement, NavigationDefaultProps> = (
  { id, ...rest },
  ref,
) => {
  const defaultClassName = "w-full h-[80px] bg-md-seagray ";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appData = useContext(AppDataContext);
  const hasJaktomrader = appData.jaktområder.data && appData.jaktområder.data.length > 0;

  const otherRoutes = [
    {
      id: "mainPageNavigationButtonOversikt",
      label: t("pages.oversikt.title"),
      icon: <FontAwesomeIcon icon={faList} size={"2x"} />,
      path: "/oversikt",
    },
    {
      id: "mainPageNavigationButtonMinSide",
      label: t("pages.minSide.title"),
      icon: <FontAwesomeIcon icon={faCircleUser} size={"2x"} />,
      path: "/minside",
    },
  ];

  const nyRegistreringRoute = [
    {
      id: "mainPageNavigationButtonNyRegistrering",
      label: t("pages.registrering.title"),
      icon: <FontAwesomeIcon icon={faPlus} size={"2x"} />,
      path: "/registrering",
    },
  ];

  const navbarRoutes = !hasJaktomrader ? otherRoutes : nyRegistreringRoute.concat(otherRoutes);

  return (
    <BottomNavigation
      ref={ref}
      data-testid={id}
      className={defaultClassName}
      showLabels={true}
      onChange={(_event, selectedIndex) => {
        navigate(navbarRoutes[selectedIndex].path);
      }}
      {...rest}
    >
      {navbarRoutes.map((button, _index) => {
        return <NavigationButtonDefault key={_index} {...button} />;
      })}
    </BottomNavigation>
  );
};

export default React.forwardRef(NavigationDefault);
