import { QrCodeScanner } from "@mui/icons-material";
import { BarcodeFormat, DetectedBarcode } from "barcode-detector";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ButtonDefault from "~/src/components/Button/ButtonDefault";
import { useFormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { useStateful } from "~/src/hooks/useStateful";
import BarcodeScanner from "~/src/pages/Registrering/components/BarcodeScanner";
import { IndividFormDataContext } from "~/src/pages/Registrering/components/IndividForm";
import { AccordionDefault } from "../../../components/Accordion/AccordionDefault";
import WarningAlert from "../../../components/Alerts/WarningAlert";
import Input from "../../../components/Form/Input/Input";
import { RadioButton, RadioGroup } from "../../../components/Form/Radio";
import Text from "../../../components/Text/Text";

const MERKELAPP_BARCODE_FORMATS: BarcodeFormat[] = ["code_128"];

export default function MerkelappOgCwd(): JSX.Element {
  const { formData, formErrors, setFormData } = useFormDataContext(IndividFormDataContext);
  const { LevertHjerneprøve } = formData;
  const setMerkelapp = (merkelapp?: string) => setFormData({ ...formData, Merkelapp: merkelapp });
  const setLevertHjerneprøve = (levert: boolean) =>
    setFormData({ ...formData, LevertHjerneprøve: levert });

  const { t } = useTranslation();
  const scannerEnabled = useStateful(false);
  const merkelappInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const val = parseInt(target.value);
    const safe = isNaN(val) ? "" : val;
    setMerkelapp(`${safe}`);
  };

  const onScannerResult = (result: DetectedBarcode) => {
    setMerkelapp(result.rawValue);
    if (!!merkelappInputRef && !!merkelappInputRef.current) {
      merkelappInputRef.current.value = result.rawValue;
    }
  };

  return (
    <AccordionDefault headerContent={t("pages.registrerArt.merkelappOgCwd.title")}>
      <div className="w-full align-middle">
        <BarcodeScanner
          enabled={scannerEnabled}
          onResult={onScannerResult}
          barcodeFormats={MERKELAPP_BARCODE_FORMATS}
        />
      </div>
      <div className="flex gap-2 justify-between items-center align-bottom">
        <ButtonDefault
          className={formErrors?.Merkelapp && "mb-6"}
          id="skann-merkelapp"
          label="skann-merkelapp-button"
          icon={<QrCodeScanner />}
          onClick={() => {
            scannerEnabled.set(!scannerEnabled.value);
            merkelappInputRef.current?.blur();
          }}
        >
          {scannerEnabled.value ? t("actions.close") : t("pages.registrerArt.merkelappOgCwd.skann")}
        </ButtonDefault>

        <div className="w-full align-middle">
          <Input
            ref={merkelappInputRef}
            label={t("pages.registrerArt.merkelappOgCwd.merkelappNummerLabel")}
            id="input-radyr-merkelappNummer"
            type="text"
            inputMode="numeric"
            maxLength={12}
            value={formData.Merkelapp || ""}
            onChange={handleInputChange}
            errorMessage={formErrors?.Merkelapp}
          ></Input>
        </div>
      </div>
      <Text variant="h6">{t("pages.registrerArt.merkelappOgCwd.hjerneprove")}</Text>
      <div className="flex">
        <RadioGroup>
          <RadioButton
            name="group-radyr-levert-hjerneprove"
            label={t("actions.yes")}
            onChange={() => setLevertHjerneprøve(true)}
            checked={LevertHjerneprøve === true}
          />
          <RadioButton
            name="group-radyr-levert-hjerneprove"
            label={t("actions.no")}
            onChange={() => setLevertHjerneprøve(false)}
            checked={LevertHjerneprøve === false}
          />
        </RadioGroup>
      </div>
      {formData.LevertHjerneprøve && !formData.Merkelapp && (
        <WarningAlert>Merkelapp er påkrevd dersom hjerneprøve er levert</WarningAlert>
      )}
    </AccordionDefault>
  );
}
