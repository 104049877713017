import { format } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import { ArtType } from "../../../helpers/types";
import IndividForm from "../components/IndividForm";
import { IndividData } from "../schemas/schema";

const DEFAULT_VILLREIN: IndividData = {
  Id: "",
  Dato: format(new Date(), dateFormatISO),
  Art: ArtType.Villrein,
  Villreinvald: "",
};

export default function RegistrerVillrein(): JSX.Element {
  return <IndividForm initialInfo={DEFAULT_VILLREIN} mode="create" />;
}
