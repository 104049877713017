import { useTranslation } from "react-i18next";
import { z } from "zod";
import { CreateIndividRequest, IndividMedJaktdagResponse } from "../../../api/types";
import { AlderType, ArtType, KjønnType } from "../../../helpers/types";
import { MalemetodeSlaktevekt, Terreng } from "../types";

const gyldigMerkelapp = (merkelapp: string) => {
  const parsedMerkelapp = parseInt(merkelapp);
  if (isNaN(parsedMerkelapp) || parseFloat(merkelapp) !== parsedMerkelapp) {
    return false;
  }
  return merkelapp.length === 12 && parsedMerkelapp.toString().length === 12;
};

export type IndividData = {
  Alder?: AlderType;
  Art: ArtType;
  Kjønn?: KjønnType;
  MålemetodeSlaktevekt?: MalemetodeSlaktevekt | null;
  Terreng?: Terreng | null;
  Dato: string;
} & Omit<CreateIndividRequest, "Alder" | "Kjønn"> &
  IndividMedJaktdagResponse;

export const useIndividSchema = () => {
  const { t } = useTranslation();

  const commonSchema: z.ZodSchema<IndividData> = z
    .object({
      Id: z.string().uuid(),
      JaktdagId: z.string().optional().nullable(),
      Jegernummer: z.number().optional().nullable(),
      AntallKalverIfølgeMedHunndyret: z.number().optional().nullable(),
      FeltKalvIkkeIfølgeMedMorDyr: z.boolean().optional().nullable(),
      KoordinatFesting: z.string().optional().nullable(),
      KoordinatØst: z.string().optional().nullable(),
      KoordinatNord: z.string().optional().nullable(),
      Alder: z.nativeEnum(AlderType, { message: t("pages.registrering.errors.kjonnOgAlder") }),
      Art: z.nativeEnum(ArtType),
      Fellingssted: z
        .string()
        .max(200, { message: t("pages.registrering.errors.fellingssted") })
        .optional()
        .nullable(),
      GevirtakkerHøyre: z.number().min(0).int().max(100).optional().nullable(),
      GevirtakkerVenstre: z.number().min(0).int().max(100).optional().nullable(),
      Kjønn: z.nativeEnum(KjønnType, { message: t("pages.registrering.errors.kjonnOgAlder") }),
      KvitteringAnnen: z
        .string()
        .email({ message: t("pages.registrering.errors.epostBekreftelseAnnen") })
        .optional()
        .nullable(),
      KvitteringEgen: z.boolean().optional().nullable(),
      KvitteringJaktleder: z.boolean().optional().nullable(),
      KvitteringValdansvarlig: z.boolean().optional().nullable(),
      LevertHjerneprøve: z.boolean().optional().nullable(),
      MålemetodeSlaktevekt: z.nativeEnum(MalemetodeSlaktevekt).optional().nullable(),
      MelkIJuret: z.boolean().optional().nullable(),
      Kontrollkort: z.string().optional().nullable(),
      Merkelapp: z.string().optional().nullable(),
      Merknad: z
        .string()
        .max(2000, { message: t("pages.registrering.errors.merknad") })
        .optional()
        .nullable(),
      SlaktevektKg: z
        .number({ message: t("pages.registrering.errors.slaktevekt") })
        .min(0, { message: t("pages.registrering.errors.slaktevekt") })
        .int({ message: t("pages.registrering.errors.slaktevektHeltall") })
        .max(1000, { message: t("pages.registrering.errors.slaktevekt") })
        .optional()
        .nullable(),
      Terreng: z.nativeEnum(Terreng).optional().nullable(),
      Dato: z.string(),
      Villreinvald: z.string().min(2).optional().nullable(),
      Jaktfelt: z.string().min(2).optional().nullable(),
      Vald: z.string().min(2).optional().nullable(),
    })
    .refine(
      (data) => {
        const kreverSlaktevekt =
          data.MålemetodeSlaktevekt === MalemetodeSlaktevekt.Veid ||
          data.MålemetodeSlaktevekt === MalemetodeSlaktevekt.Anslatt;
        const harSlaktevekt = !(data.SlaktevektKg === undefined || data.SlaktevektKg === null);

        return (kreverSlaktevekt && harSlaktevekt) || (!kreverSlaktevekt && !harSlaktevekt);
      },
      {
        message: t("pages.registrering.errors.slaktevektPakrevd"),
        path: ["SlaktevektKg"],
      },
    )
    .refine(
      (data) => {
        if (!data.LevertHjerneprøve) return true;
        return data.Merkelapp;
      },
      {
        message: t("pages.registrering.errors.merkelappPåkrevd"),
        path: ["Merkelapp"],
      },
    )
    .refine(
      (data) => {
        if (data.Art !== ArtType.Hjort) return true;
        return data.Terreng;
      },
      {
        message: t("pages.registrering.errors.terreng"),
        path: ["Terreng"],
      },
    )
    .refine(
      (data) => {
        if (data.Art !== ArtType.Villrein) return true;
        return data.Villreinvald;
      },
      {
        message: t("pages.registrering.errors.villreinvald"),
        path: ["Villreinvald"],
      },
    )
    .refine(
      (data) => {
        if (data.Art !== ArtType.Rådyr) return true;
        return data.Vald;
      },
      {
        message: t("pages.registrering.errors.vald"),
        path: ["Vald"],
      },
    )
    .refine(
      (data) => {
        if (data.Art !== ArtType.Elg && data.Art !== ArtType.Hjort) return true;
        return data.Jaktfelt;
      },
      {
        message: t("pages.registrering.errors.jaktfelt"),
        path: ["Jaktfelt"],
      },
    )
    .refine(
      (data) => {
        if (data.Art !== ArtType.Villrein) return true;
        if (!data.Kontrollkort) return true; // Kontrollkort er valgfritt for villrein

        const isSvalbard = data.Villreinvald?.startsWith("2300");
        if (isSvalbard) {
          if (data.Kontrollkort[0].toLowerCase() !== "s") return false;
          if (!/^\d{6}$/.test(data.Kontrollkort.substring(1))) return false;
          return true;
        }
        if (gyldigMerkelapp(data.Kontrollkort)) return true;
        if (!/^\d{6}$/.test(data.Kontrollkort)) return false;
        if (parseInt(data.Kontrollkort) < 405756) return false; // 2023 top kontrollkort serie.
        return true;
      },
      {
        message: t("pages.registrering.errors.kontrollkort"),
        path: ["Kontrollkort"],
      },
    )

    .refine(
      (data) => {
        if (!data.Merkelapp) return true;
        return gyldigMerkelapp(data.Merkelapp);
      },
      {
        message: t("pages.registrering.errors.merkelapp"),
        path: ["Merkelapp"],
      },
    )
    .refine(
      (data) => {
        // Use a regular expression to validate ISO 8601 date strings
        const isoRegex = /^\d{4}-\d{2}-\d{2}/;
        return isoRegex.test(data.Dato);
      },
      {
        message: t("pages.registrering.errors.dato"),
        path: ["Dato"],
      },
    );

  const IndividSchema = commonSchema;

  return { schema: IndividSchema };
};
