import { ThemeProvider } from "@material-tailwind/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as env from "env-var";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  ClientState,
  NOT_AUTHENTICATED_STATUS_CODES,
  NOT_CACHED_STATUS_CODE,
} from "~/src/api/jaktdataApi";
import StorageProvider from "~/src/contexts/StorageContext/StorageProvider";
import "./App.css";
import { ApiError } from "./api/types";
import { appConfig } from "./appConfig";
import { authProvider, jaktdataApi } from "./appGlobals";
import ButtonDefault from "./components/Button/ButtonDefault";
import InstallApp from "./components/InstallApp/InstallApp";
import ManagedRoute from "./components/ManagedRoute";
import AuthContext, { AuthError } from "./contexts/AuthContext/AuthProvider";
import { UIProvider } from "./contexts/UIContext/UIProvider";
import { useDialog } from "./hooks/useDialog";
import { useStateful } from "./hooks/useStateful";
import "./i18n";
import Debug from "./pages/Debug/Debug";
import Login from "./pages/Login/Login";
import RequireDataLayout from "./pages/MainLayout/RequireDataLayout";
import { registerServiceWorker } from "./service-worker/init";
import { materialTailwindThemeOverrides, muiOverrides } from "./theme";

registerServiceWorker(appConfig);

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serverfeilDialog = useDialog({ title: t("actions.error") });
  const shouldPerformLateTokenCheck = useStateful<boolean>(false);
  const LATE_TOKEN_CHECK_DURATION = 2000; // 2 seconds
  const expiredSessionDialog = useDialog({
    title: t("dialogs.sessionExpired.title"),
  });

  const displayErrorDialog = useCallback((apiError: ApiError) => {
    let dialogContent =
      apiError.statusCode === NOT_CACHED_STATUS_CODE
        ? { message: t("errors.connectionError") }
        : {
            title: t("dialogs.serverfeil.title"),
            message: t("dialogs.serverfeil.message"),
          };
    serverfeilDialog.show({
      ...dialogContent,
      buttons: (
        <ButtonDefault
          id="serverfeilDialogLukkButton"
          label={t("actions.close")}
          onClick={() => {
            serverfeilDialog.close();
          }}
        />
      ),
    });
  }, []);

  useEffect(() => {
    if (shouldPerformLateTokenCheck.value) {
      performLateTokenCheck();
    }
  }, [shouldPerformLateTokenCheck.value]);

  const performLateTokenCheck = () => {
    setTimeout(() => {
      shouldPerformLateTokenCheck.set(false);
      jaktdataApi.checkClientState().then((state: ClientState) => {
        if (state !== "Online") {
          expiredSessionDialog.show({
            message: t("pages.login.validation.tokenExpired.message"),
            title: t("pages.login.validation.tokenExpired.title"),
            buttons: (
              <ButtonDefault
                id="sessionExpiredDialogOkButton"
                label={t("actions.ok")}
                onClick={() => {
                  expiredSessionDialog.close();
                  navigate("/login");
                }}
              />
            ),
          });
        }
      });
    }, LATE_TOKEN_CHECK_DURATION);
  };

  const [queryCache] = useState(
    () =>
      new QueryCache({
        onError: (error: Error) => {
          if ("statusCode" in error) {
            const apiError = error as ApiError;
            if (
              NOT_AUTHENTICATED_STATUS_CODES.includes(apiError.statusCode) &&
              !authProvider.isRefreshing
            ) {
              // verify and refresh
              authProvider.verify().catch((error: AuthError) => {
                // TODO: Refresh token slettes på serversiden når det er brukt, så det kan oppstå feil her hvis
                //       flere requester kjører samtidig.
                if (["TokenExpired", "Unknown"].includes(error.message)) {
                  // HTTP 400 Bad Request eller 500 Internal Server Error
                  // Hvis vi får 400 eller 500 fra /renew må vi sjekke om appen har fått et nytt token
                  // som resultat av en annen request i mellomtiden. Denne sjekken gjøres etter LATE_TOKEN_CHECK_DURATION
                  // for å unngå å kaste brukeren ut med en gang en renew forsøkes med et token som er "oppbrukt" på serveren.
                  shouldPerformLateTokenCheck.set(true);
                }
              });
            } else if (
              apiError.statusCode >= 500 ||
              apiError.statusCode === NOT_CACHED_STATUS_CODE
            ) {
              displayErrorDialog(apiError);
            }
          }
        },
      }),
  );

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: queryCache,
        defaultOptions: {
          queries: {
            staleTime: appConfig.reactQuery.defaultStaleTime.seconds || 5 * 1000,
            networkMode: appConfig.reactQuery.networkMode,
            retry: (failureCount, error) => {
              if (window.location.pathname === "/login" || authProvider.isRefreshing) {
                return false;
              }
              if ("statusCode" in error) {
                const apiError = error as ApiError;
                if (
                  NOT_AUTHENTICATED_STATUS_CODES.includes(apiError.statusCode) ||
                  apiError.statusCode === NOT_CACHED_STATUS_CODE
                ) {
                  return false;
                }
              }
              return failureCount <= appConfig.reactQuery.maxRetries;
            },
          },
        },
      }),
  );

  useEffect(() => {
    if (authProvider.isRefreshing) {
      queryClient.cancelQueries();
    } else {
      queryClient.invalidateQueries().then(() => {
        queryClient.refetchQueries();
      });
    }
  }, [authProvider.isRefreshing]);

  return (
    <div className="App mx-auto max-w-screen-max min-w-86">
      {expiredSessionDialog.element}
      {serverfeilDialog.element}
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={authProvider}>
          <StorageProvider>
            <ThemeProvider value={materialTailwindThemeOverrides}>
              <MuiThemeProvider theme={muiOverrides}>
                <UIProvider>
                  <>
                    <InstallApp />

                    <Routes>
                      <Route path="/login" element={<Login />} />
                      {env.get("NODE_ENV").asString() === "development" && (
                        <Route path="/debug" element={<Debug />} />
                      )}
                      <Route path="/*" element={<ManagedRoute element={<RequireDataLayout />} />} />
                    </Routes>
                  </>
                </UIProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </StorageProvider>
        </AuthContext.Provider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
