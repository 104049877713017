import { Check, PinDrop } from "@mui/icons-material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonDefault from "~/src/components/Button/ButtonDefault";
import { useFormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { IndividFormDataContext } from "~/src/pages/Registrering/components/IndividForm";
import { AccordionDefault } from "../../../components/Accordion/AccordionDefault";
import Input from "../../../components/Form/Input/Input";
import Text from "../../../components/Text/Text";

export default function StedfestFelling(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, formErrors, setFormData } = useFormDataContext(IndividFormDataContext);
  const { Merknad, Fellingssted } = formData;

  useEffect(() => {
    if (location.state?.nord && location.state?.ost) {
      setFormData({
        ...formData,
        KoordinatØst: location.state?.ost,
        KoordinatNord: location.state?.nord,
        Koordinatfesting: location.state?.koordinatfesting,
      });
    }
  }, [location.state]);

  const setMerknad = (merknad: string) => setFormData({ ...formData, Merknad: merknad });
  const setFellingssted = (fellingsted: string) =>
    setFormData({ ...formData, Fellingssted: fellingsted });
  return (
    <div>
      <AccordionDefault headerContent={t("pages.registrerArt.stedfestFelling.title")} defaultOpen>
        <Input
          id="input-fellingssted"
          label={t("pages.registrerArt.stedfestFelling.fellingssted")}
          defaultValue={Fellingssted ?? ""}
          onChange={(e) => setFellingssted(e.target.value)}
          errorMessage={formErrors?.Fellingssted}
          maxLength={200}
        ></Input>
        <ButtonDefault
          id="stedfest"
          label="stedfest-button"
          onClick={() =>
            navigate("../kart", {
              state: {
                referrer: location.pathname,
                nord: formData.KoordinatNord,
                ost: formData.KoordinatØst,
                koordinatfesting: formData.Koordinatfesting,
              },
            })
          }
          icon={formData.KoordinatNord && formData.KoordinatØst ? <Check /> : <PinDrop />}
        >
          {formData.KoordinatNord && formData.KoordinatØst
            ? t("pages.registrerArt.stedfestFelling.stedfestetIKart")
            : t("pages.registrerArt.stedfestFelling.stedfestIKart")}
        </ButtonDefault>
        {formData.KoordinatNord && formData.KoordinatØst && (
          <>
            <ButtonDefault
              id="remove-coordinates"
              label="remove-coordinates"
              onClick={() =>
                setFormData({
                  ...formData,
                  KoordinatNord: undefined,
                  KoordinatØst: undefined,
                  Koordinatfesting: undefined,
                })
              }
            >
              {t("pages.registrerArt.stedfestFelling.fjernStedfestingskoordinater")}
            </ButtonDefault>
            <Input
              id="input-koordinatfesting"
              type={"hidden"}
              hidden={true}
              errorMessage={formErrors?.Koordinatfesting}
            ></Input>
            <Input
              id="input-koordinatOst"
              type={"hidden"}
              hidden={true}
              errorMessage={formErrors?.KoordinatØst}
            ></Input>
            <Input
              id="input-koordinatNord"
              type={"hidden"}
              hidden={true}
              errorMessage={formErrors?.KoordinatNord}
            ></Input>
          </>
        )}
      </AccordionDefault>
      <AccordionDefault headerContent={t("pages.registrerArt.merknad.title")} defaultOpen>
        <Input
          id="input-fellingssted-merknad"
          label={t("pages.registrerArt.merknad.merknad")}
          defaultValue={Merknad ?? ""}
          onChange={(e) => setMerknad(e.target.value)}
          errorMessage={formErrors?.Merknad}
          maxLength={2000}
          textArea={true}
        ></Input>
        <Text variant="small">{t("pages.registrerArt.merknad.merknadHelperText")}</Text>
      </AccordionDefault>
    </div>
  );
}
