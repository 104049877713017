import { Input, Typography } from "@material-tailwind/react";
import { InputProps } from "@material-tailwind/react/components/Input";
import { ErrorOutlined } from "@mui/icons-material";
import React, { ReactElement, useEffect, useImperativeHandle, useRef } from "react";
import { FieldError } from "react-hook-form";
import { onEnterPressed } from "../../../helpers/accessibilityHelpers";
import { useStateful } from "../../../hooks/useStateful";

export type InputDefaultProps = Omit<
  InputProps,
  "ref" | "crossOrigin" | "error" | "icon" | "defaultValue"
> & {
  id: string;
  label: string;
  defaultValue?: string | number;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  rightIconOnClick?: () => void;
  helpText?: string;
  crossOrigin?: "anonymous" | "use-credentials" | "";
  fieldError?: FieldError;
  validationErrorMessage?: string;
  onValueChanged?: (value: string) => void;
  inputRegexPattern?: string;
  inputClasses?: string;
};

const InputDefault: React.ForwardRefRenderFunction<HTMLInputElement, InputDefaultProps> = (
  {
    id,
    label,
    defaultValue,
    rightIcon,
    rightIconOnClick,
    leftIcon,
    helpText,
    crossOrigin,
    fieldError,
    onValueChanged,
    validationErrorMessage,
    placeholder,
    onBlur,
    onFocus,
    onChange,
    onInput,
    pattern,
    inputRegexPattern,
    inputClasses,
    ...rest
  },
  ref,
) => {
  const _defaultValue = defaultValue !== undefined ? `${defaultValue}` : "";
  const value = useStateful<string>(_defaultValue);
  const focused = useStateful(false);

  const internalRef = useRef<HTMLInputElement>(null);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => internalRef.current,
  );

  useEffect(() => {
    if (value.value !== _defaultValue) {
      onValueChanged && onValueChanged(value.value);
    }
  }, [value.value]);

  useEffect(() => {
    if (defaultValue) {
      value.set(_defaultValue);
    }
  }, [defaultValue]);

  const borderColor = focused.value
    ? "border-md-primary"
    : fieldError != null || validationErrorMessage
      ? "border-md-error-red"
      : "border-md-secondary";

  return (
    <>
      <div
        className={`flex flex-row items-center h-14 bg-md-greengray border-b-2 rounded-t-md ${borderColor} ${inputClasses}`}
      >
        <div className="pl-2 pr-2 text-md-gray">{leftIcon && leftIcon}</div>
        <Input
          data-testid={id}
          inputRef={internalRef}
          label={label}
          aria-labelledby={label}
          crossOrigin={crossOrigin || "anonymous"}
          variant="standard"
          containerProps={{ className: "bg-md-greengray" }}
          error={fieldError != null}
          labelProps={{ className: "flex flex-row items-center text-md-seagray pb-4 text-sm" }}
          {...rest}
          value={value.value}
          onBlur={(e) => {
            focused.set(false);
            onBlur && onBlur(e);
          }}
          onFocus={(e) => {
            focused.set(true);
            onFocus && onFocus(e);
          }}
          onChange={(e) => {
            value.set(e.target.value);
            onChange && onChange(e);
          }}
          onInput={(e) => {
            onInput && onInput(e);
          }}
        />
        <div
          className="text-md-gray"
          onClick={rightIconOnClick}
          onKeyDown={(e) => onEnterPressed(e, rightIconOnClick)}
          data-testid="inputRightIconClick"
        >
          {rightIcon}
        </div>
        <div className="pl-2 pr-2 border-2 text-md-gray">
          {fieldError && !validationErrorMessage && <ErrorOutlined className="text-md-error-red" />}
        </div>
      </div>
      {fieldError || validationErrorMessage ? (
        <div>
          <Typography variant="small" className={"text-md-error-red"} placeholder={placeholder}>
            {fieldError?.message || validationErrorMessage}
          </Typography>
        </div>
      ) : (
        helpText && (
          <Typography variant="small" placeholder={placeholder}>
            {helpText}
          </Typography>
        )
      )}
    </>
  );
};

export default React.forwardRef(InputDefault);
