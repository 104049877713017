import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppDataContext from "../../contexts/AppDataContext/DataProvider";
import useRouteProps from "../../hooks/useRouteProps";
import ErrorPage from "../Error/ErrorPage";
import MinSide from "../MinSide/MinSideLayout";
import OversiktLayout from "../Oversikt/OversiktLayout";
import Registrering from "../Registrering/Registrering";
import NavigationDefault from "./components/BottomNavigation/NavigationDefault";
import CardHeaderDefault from "./components/CardHeader/CardHeaderDefault";

const MAX_WAIT_FOR_DATA_DURATION_MS = 5000; // 5 seconds

export default function MainLayout(): JSX.Element {
  const appData = useContext(AppDataContext);
  const pathProps = useRouteProps();

  useEffect(() => {
    // for å unngå evig spinner så reloades siden etter timeout
    if (!appData.allLoaded) {
      const waitIntervalId = setInterval(() => {
        window.location.reload();
      }, MAX_WAIT_FOR_DATA_DURATION_MS);
      return () => clearInterval(waitIntervalId);
    }
  }, [appData.allLoaded]);

  if (!appData.allLoaded) {
    return (
      <div className="h-dvh flex flex-col items-center justify-center">
        <CircularProgress color="success" />
      </div>
    );
  }

  const maxH = pathProps.hideNavbar ? "max-h-[100vh]" : "max-h-[93vh]";
  return (
    <div className="h-dvh flex flex-col items-center">
      <CardHeaderDefault pathProps={pathProps} />
      <div className={`${maxH} overflow-y-scroll w-full h-full`}>
        <Routes>
          <Route path="registrering/*" element={<Registrering />} />
          <Route path="oversikt/*" element={<OversiktLayout />} />
          <Route path="minside/*" element={<MinSide />} />
          <Route path="/" element={<Navigate to="/minside" replace />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </div>
      {!pathProps.hideNavbar && (
        <div className="sticky top-[100vh] w-dvw">
          <NavigationDefault id="mainPageNavigation" />
        </div>
      )}
    </div>
  );
}
