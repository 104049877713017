import { zodResolver } from "@hookform/resolvers/zod";
import { AccountCircleOutlined, Email, LocalPhone } from "@mui/icons-material";
import { useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Text from "~/src/components/Text/Text";
import { setApiErrorsUseForm } from "~/src/helpers/formErrors";
import { UpdateJegerOpplysningerRequest } from "../../../api/types";
import { appConfig } from "../../../appConfig";
import { jaktdataApi } from "../../../appGlobals";
import ButtonDefault, {
  ButtonLayout,
  ButtonVariant,
} from "../../../components/Button/ButtonDefault";
import InputDefault from "../../../components/Form/Input/InputDefault";
import ExternalLink from "../../../components/Links/ExternalLink";
import {
  default as AppDataContext,
  default as UserContext,
} from "../../../contexts/AppDataContext/DataProvider";
import { useDialog } from "../../../hooks/useDialog";
import { useStateful } from "../../../hooks/useStateful";

export type JegeropplysningerFormProps = {
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  mode: "velkommen" | "rediger";
};

const phoneNrRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export default function ({
  className = "",
  onConfirm = undefined,
  onCancel = undefined,
  mode,
}: JegeropplysningerFormProps): JSX.Element {
  const { t } = useTranslation();
  const errorDialog = useDialog({ title: t("pages.minSide.jegerOpplysninger.errorDialog.title") });

  const jegerOpplysningerFormValidation = z.object({
    Navn: z.string().min(3, { message: t("form.fields.navn.validationError") }),
    Telefonnummer: z
      .string()
      .regex(phoneNrRegex, { message: t("form.fields.telefon.validationError") })
      .min(7, { message: t("form.fields.telefon.validationError") })
      .max(20, { message: t("form.fields.telefon.validationError") }),
    Epost: z
      .string()
      .email({ message: t("form.fields.epost.validationError") })
      .min(5)
      .or(z.literal("")),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<UpdateJegerOpplysningerRequest>({
    resolver: zodResolver(jegerOpplysningerFormValidation),
    mode: "onBlur",
  });

  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const isSubmitting = useStateful<boolean>(false);
  const user = useContext(UserContext);
  const onSubmit: SubmitHandler<UpdateJegerOpplysningerRequest> = async (request) => {
    isSubmitting.set(true);
    const sanitizedRequest = {
      ...request,
      Telefonnummer: request.Telefonnummer.replace(/\s+/g, ""),
    };
    jaktdataApi
      .putJegeropplysninger(sanitizedRequest)
      .then(async () => {
        await appData.jegeropplysninger.refetch();
        navigate("/minside");
      })
      .catch((error) => {
        setApiErrorsUseForm(error, setError);

        errorDialog.show({ message: error.message });
      })
      .finally(() => {
        isSubmitting.set(false);
        onConfirm && onConfirm();
      });
  };

  useEffect(() => {
    reset();
  }, []);

  const showPersonvernerklaeringOppfording = mode === "velkommen";
  const buttonAlignment = mode === "velkommen" ? ButtonLayout.Vertical : ButtonLayout.Horizontal;
  // const showLagresIhjorteviltHelpertext = mode === "rediger"; TODO: Implement

  return (
    <div
      className={`flex flex-col ${className} ${
        buttonAlignment === ButtonLayout.Horizontal ? "justify-between" : "justify-start"
      }`}
    >
      <div>
        <div className="flex flex-row justify-evenly mx-8 mt-4">
          <form id="endreJegeropplysningerForm" className="w-full">
            <div className="flex flex-col gap-2 p-2 w-full">
              <InputDefault
                id="jegeropplysningerNavnInput"
                label={t("form.fields.navn.label")}
                leftIcon={<AccountCircleOutlined />}
                fieldError={errors.Navn}
                {...register("Navn", { required: t("form.fields.navn.validationError") })}
                defaultValue={user.jegeropplysninger.data?.Navn ?? ""}
                onValueChanged={(value: string) =>
                  setValue("Navn", value, { shouldValidate: true })
                }
              />
              <InputDefault
                id="jegeropplysningerTelefonnummerInput"
                label={t("form.fields.telefon.label")}
                leftIcon={<LocalPhone />}
                type="string"
                inputMode="tel"
                fieldError={errors.Telefonnummer}
                {...register("Telefonnummer", {
                  required: t("form.fields.telefon.validationError"),
                })}
                defaultValue={user.jegeropplysninger.data?.Telefonnummer ?? ""}
                onValueChanged={(value: string) => {
                  setValue("Telefonnummer", value, { shouldValidate: true });
                }}
              />
              <InputDefault
                id="jegeropplysningerEpostInput"
                label={`${t("form.fields.epost.label")} (${t("actions.optional")})`}
                leftIcon={<Email />}
                fieldError={errors.Epost}
                {...register("Epost")}
                defaultValue={user.jegeropplysninger.data?.Epost ?? ""}
                onValueChanged={(value: string) =>
                  setValue("Epost", value, { shouldValidate: true })
                }
              />
              <div>
                <div className="flex flex-col gap-6 mt-4">
                  <Text variant="small">
                    {t("pages.minSide.jegerOpplysninger.epostOppfordring")}
                  </Text>
                </div>
                {showPersonvernerklaeringOppfording && (
                  <div className="flex flex-col items-center justify-center text-center mt-2">
                    <ExternalLink
                      url={appConfig.urls.personvernerklaering}
                      text={t("pages.minSide.jegerOpplysninger.oppfordring", {
                        personvernerklaering: t(
                          "pages.minSide.jegerOpplysninger.personvernerklaering",
                        ),
                      })}
                    ></ExternalLink>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`flex ${
          buttonAlignment === ButtonLayout.Horizontal ? "flex-row" : "flex-col-reverse"
        } justify-evenly items-center`}
      >
        <div className="w-full max-w-96 m-4 px-8">
          <ButtonDefault
            id="jegeropplysningerAvbrytButton"
            variant={ButtonVariant.SecondaryWhite}
            type="reset"
            fullWidth={true}
            onClick={() => {
              reset();
              (onCancel && onCancel()) || navigate("/minside");
            }}
            label={t("actions.cancel")}
          />
        </div>
        <div className="w-full max-w-96 m-4 px-8">
          <ButtonDefault
            id="jegeropplysningerSubmitButton"
            type="submit"
            fullWidth={true}
            label={
              buttonAlignment === ButtonLayout.Vertical
                ? t("actions.saveInformation")
                : t("actions.save")
            }
            loading={isSubmitting.value}
            onClick={handleSubmit(onSubmit)}
            form="endreJegeropplysningerForm"
          />
        </div>
      </div>
      {errorDialog.element}
    </div>
  );
}
