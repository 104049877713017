import { Info, Sync, SyncProblem, Warning } from "@mui/icons-material";
import { Alert, Collapse } from "@mui/material";
import { PropsWithChildren, useContext } from "react";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { useStateful } from "~/src/hooks/useStateful";

export enum AlertVariant {
  Info,
  Error,
  Warning,
  Sync,
  SyncError,
}

type AlertDefaultProps = PropsWithChildren & {
  variant?: AlertVariant;
  canClose?: boolean;
  id?: string;
  showIcon?: boolean;
  className?: string;
};

export type AlertState = {
  show: true;
};

export default function AlertDefault({
  children,
  canClose,
  id,
  variant = AlertVariant.Info,
  showIcon = false,
  className = "",
}: AlertDefaultProps): JSX.Element {
  const storageKey = { name: "AlertDefault", id: id };
  const storage = useContext(StorageContext);
  let state: AlertState = { show: true };
  if (id) {
    state = storage?.get<AlertState>(storageKey)?.data || state;
  }
  const isOpen = useStateful<boolean>(state.show);
  const handleClose = () => {
    isOpen.set(false);
    if (id) {
      storage?.add({ objectKey: storageKey, data: { show: false } });
    }
  };

  const severity =
    variant === AlertVariant.Error
      ? "error"
      : variant === AlertVariant.Warning
        ? "warning"
        : "info";

  let color;
  let icon = null;
  switch (variant) {
    case AlertVariant.Info:
      color = "bg-md-info";
      break;

    case AlertVariant.Warning:
      color = "bg-md-warning-light";
      icon = (
        <div className="text-md-warning-dark">
          <Info />
        </div>
      );
      break;

    case AlertVariant.Error:
      color = "bg-md-error-red-light";
      icon = (
        <div className="text-md-error-red">
          <Warning />
        </div>
      );
      break;

    case AlertVariant.Sync:
      color = "bg-md-warning-light";
      icon = (
        <div className="text-md-warning-dark">
          <Sync />
        </div>
      );
      break;

    case AlertVariant.SyncError:
      color = "bg-md-error-red-light";
      icon = (
        <div className="text-md-error-red">
          <SyncProblem />
        </div>
      );
      break;
  }

  return (
    <Collapse in={isOpen.value}>
      <Alert
        severity={severity}
        icon={false}
        onClose={canClose ? handleClose : undefined}
        className={`${color} text-black rounded py-1 px-3 ${className}`}
      >
        <div className="flex items-center gap-2">
          {showIcon && icon} <div>{children}</div>
        </div>
      </Alert>
    </Collapse>
  );
}
