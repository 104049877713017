import { isEqual } from "lodash";
import superjson from "superjson";

export function canBeSerialized(payload: object): boolean {
  const stringified = superjson.stringify(payload);
  return isEqual(superjson.parse(stringified), payload);
}

export function store(key: string, value: object): void {
  if (!value) {
    throw new Error("value must be defined and can not be empty");
  }
  if (!canBeSerialized(value)) {
    throw new Error(
      "Can not serialize value to JSON: Serialized value did not match original value",
    );
  }

  const objectString = superjson.stringify(value);
  localStorage.setItem(key, objectString);
}

export function retrieve<T>(key: string) {
  const objectString = localStorage.getItem(key);
  if (!objectString) {
    return undefined;
  }
  try {
    return (superjson.parse(objectString) as T) ?? undefined;
  } catch (e) {
    console.error(e);
    return (superjson.parse(objectString) as T) ?? undefined;
  }
}

export function removeAll(keys: string[]): void {
  keys.forEach((key: string) => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  });
}
