import { NavigateNext } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function InternalLink({ path, text, icon }: InternalLinkProps): JSX.Element {
  let Icon = null;
  if (icon) Icon = React.cloneElement(icon, { htmlColor: "gray", style: { marginRight: 10 } });

  return (
    <div className="flex">
      {Icon}
      <NavLink to={path} className="text-md-gray flex flex-grow justify-between">
        {text}
        <NavigateNext />
      </NavLink>
    </div>
  );
}

type InternalLinkProps = {
  path: string;
  text: string;
  icon?: JSX.Element;
};
