import { t } from "i18next";

export const ArtNames = ({
  JaktPåHjort,
  JaktPåElg,
  JaktPåRådyr,
  JaktPåVillrein,
  JaktPåVillsvin,
}: {
  JaktPåHjort?: boolean;
  JaktPåElg?: boolean;
  JaktPåRådyr?: boolean;
  JaktPåVillrein?: boolean;
  JaktPåVillsvin?: boolean;
}): string => {
  const artStrings = [
    JaktPåHjort && t("fauna.art.hjort.artsnavn"),
    JaktPåElg && t("fauna.art.elg.artsnavn"),
    JaktPåRådyr && t("fauna.art.rådyr.artsnavn"),
    JaktPåVillrein && t("fauna.art.villrein.artsnavn"),
    JaktPåVillsvin && t("fauna.art.villsvin.artsnavn"),
  ].filter((artString) => !!artString);

  return artStrings.join(", ");
};
