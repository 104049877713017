import { PropsWithChildren, ReactNode } from "react";

type PageTitleProps = PropsWithChildren & {
  children?: string | ReactNode | null;
  width: number;
};

export default function PageTitle({ width, children }: PageTitleProps) {
  return (
    <div
      className={
        "absolute text-white -mt-10 left-1/2 text-center text-lg font-bold antialiased font-sans"
      }
      style={{ width: width, marginLeft: (width / 2) * -1 }}
    >
      {children}
    </div>
  );
}
