import { Link } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format, formatISO, parseISO } from "date-fns";
import {
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormatISO, dateFormatNorsk } from "~/src/components/CalendarPicker/CalendarConstants";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import { FormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { firstNonEmpty } from "~/src/helpers/filters";
import { toErrorRecords } from "~/src/helpers/formErrors";
import { formatISOWithoutTimestamp } from "~/src/helpers/time";
import { useConfirmationDialog } from "~/src/hooks/useConfirmationDialog";
import {
  getFormDataStorageKey,
  handleOfflineJaktdagCreateOrUpdate,
  removeJaktdagDraft,
} from "~/src/pages/Registrering/Jaktdag/common/helpers";
import { RegistrerJaktdagProps } from "~/src/pages/Registrering/Jaktdag/common/types";
import { IndividResponse, Jaktdag, JaktdagResponse } from "../../../../api/types";
import { jaktdataApi } from "../../../../appGlobals";
import { AccordionDefault } from "../../../../components/Accordion/AccordionDefault";
import AlertDefault, { AlertVariant } from "../../../../components/Alerts/AlertDefault";
import ButtonDefault, { ButtonLayout } from "../../../../components/Button/ButtonDefault";
import CalendarPicker from "../../../../components/CalendarPicker/CalendarPicker";
import Card from "../../../../components/Card/Card";
import Counter from "../../../../components/Counter/Counter";
import { DialogAlignment } from "../../../../components/Dialog/DialogDefault";
import { ComboboxOption } from "../../../../components/Form/Combobox/Combobox";
import JaktomradePicker from "../../../../components/JaktomradePicker/JaktomradePicker";
import Text from "../../../../components/Text/Text";
import UIContext from "../../../../contexts/UIContext/UIProvider";
import { ArtType } from "../../../../helpers/types";
import { useDialog } from "../../../../hooks/useDialog";
import useFormData from "../../../../hooks/useFormData";
import { useStateful } from "../../../../hooks/useStateful";
import { getJaktdagerQuery } from "../../../../react-query/queries";
import { parseErrors } from "../../helpers";
import AntallJegereOgTimerJaktet from "../common/AntallJegereOgTimerJaktet";
import HeaderRight from "../common/HeaderRight";
import SkuttHelpText from "../common/SkuttHelpText";
import SuccessDialogButtons from "../common/SuccessDialogButtons";
import { elgFormValidation } from "./ElgFormValidation";
import { parseInitialData, sumSettOgSkuttElg, toJaktdagRequestElg } from "./ElgHelpers";
import { JaktdagRegistrationDataElg, settElgFields, skuttElgFields } from "./ElgTypes";
import SettCountersElg from "./SettCounters";
import SkuttCountersElg from "./SkuttCounters";

export const JaktdagElgFormDataContext = createContext<
  FormDataContext<JaktdagRegistrationDataElg> | undefined
>(undefined);

export default function RegistrerElg({ initialData }: RegistrerJaktdagProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useContext(UIContext);
  const appData = useContext(AppDataContext);
  const scrollTopRef = useRef<HTMLDivElement>(null);

  const isSubmitting = useStateful(false);
  const { t } = useTranslation();
  const fellingsRapportLevert = useStateful(false);
  const storage = useContext(StorageContext);
  const eksisterendeJaktdag = useStateful<JaktdagResponse | undefined>(undefined);
  const initialFormData = parseInitialData(initialData, appData);

  const [formData, setFormData, removeFormData, isDraft] =
    useFormData<JaktdagRegistrationDataElg>(initialFormData);

  const resetForm = () => {
    initialFormData && setFormData(initialFormData);
  };

  const date = useStateful<Date>(parseISO(formData.Dato) || new Date());

  const initialSelected = (formData && {
    label: formData?.jaktomradeNavn || "",
    id: formData?.Jaktfelt || "",
    data: {},
  }) || { label: "", id: "", data: {} };

  const selectedJaktomrade = useStateful<ComboboxOption>(initialSelected);

  const [formErrors, setFormErrors] = useState<
    Partial<Record<keyof JaktdagRegistrationDataElg, string>>
  >({});
  const schema = elgFormValidation(t, fellingsRapportLevert);
  const queryClient = useQueryClient();

  const jaktdagEksistererDialog = useConfirmationDialog({
    title: t("pages.registrering.dialogs.jaktdagEksisterer.title"),
    confirmButtonText: t("pages.registrering.dialogs.jaktdagEksisterer.confirmButtonText"),
    cancelButtonText: t("pages.registrering.dialogs.jaktdagEksisterer.cancelButtonText"),
  });

  const eksisterendeJaktdager = useQuery({
    queryKey: [
      "jaktdager",
      {
        jaktomradeId: selectedJaktomrade.value?.id,
        sesong: date.value.getFullYear(),
        artFilter: ["Elg"],
        dateFilter: date.value,
        jegernummerFilter: appData.jegeropplysninger.data?.Jegernummer,
      },
    ],
    queryFn: getJaktdagerQuery,
    enabled: !!formData.Jaktfelt && !!date.value && !!appData.jegeropplysninger.data,
    staleTime: 0,
  });

  const successDialog = useDialog({
    title: t("pages.registrering.dialogs.overforingSuccess.title"),
    message: t("pages.registrering.dialogs.overforingSuccess.message"),
    alignment: DialogAlignment.Center,
    buttonLayout: ButtonLayout.Vertical,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      Dato: format(date.value, dateFormatISO),
      Jaktfelt: selectedJaktomrade.value?.id || formData?.Jaktfelt || "",
      jaktomradeNavn: selectedJaktomrade.value?.label || formData?.jaktomradeNavn || "",
    });
    eksisterendeJaktdager.refetch();
    location.state = { routeProps: { valgtOmradekode: selectedJaktomrade.value.id } };
  }, [selectedJaktomrade.value, date.value]);

  useEffect(() => {
    if (eksisterendeJaktdager.isSuccess && eksisterendeJaktdager.data !== undefined) {
      const matchingJaktdag =
        eksisterendeJaktdager.data.length > 0 && eksisterendeJaktdager.data[0];
      const deletedStoredJaktdager = storage
        .getAll<Jaktdag>("Jaktdag")
        .filter((jaktdag) => jaktdag.operation === "delete" && !jaktdag.errors);
      const jaktdagEksisterer =
        matchingJaktdag &&
        initialData?.Id != matchingJaktdag.Id &&
        appData.jegeropplysninger.data?.Jegernummer == matchingJaktdag.Jegernummer &&
        deletedStoredJaktdager.find((deleted) => deleted.data?.Id == matchingJaktdag.Id) ===
          undefined;
      if (jaktdagEksisterer) {
        eksisterendeJaktdag.set(matchingJaktdag);
      } else {
        eksisterendeJaktdag.set(undefined);
      }
    }
  }, [eksisterendeJaktdager.data, eksisterendeJaktdager.status]);

  useEffect(() => {
    eksisterendeJaktdag.value &&
      jaktdagEksistererDialog.show({
        message: t("pages.registrering.dialogs.jaktdagEksisterer.message", {
          dato: format(date.value, "d. MMM"),
          art: t("fauna.art.elg.artsnavn").toLowerCase(),
          jaktomrade: formData.jaktomradeNavn,
        }),
        onCancel: () => {
          jaktdagEksistererDialog.close();
        },
        onConfirm: () => {
          navigate(`/registrering/jaktdag/${eksisterendeJaktdag.value?.Id}`);
          removeFormData();
        },
      });
  }, [eksisterendeJaktdag.value]);

  // Do counter validation on counter change
  useEffect(() => {
    const handler = setTimeout(() => {
      // Cleanup function to clear the timeout if the effect is called again before the delay
      const result = schema.safeParse(formData);
      if (result.success) {
        setFormErrors({});
        return;
      }
      const fieldErrors: Partial<Record<keyof JaktdagRegistrationDataElg, string>> = {};
      [...settElgFields, ...skuttElgFields].forEach((field) => {
        fieldErrors[field] = result.error.errors.find((error) => error.path[0] === field)?.message;
      });
      setFormErrors({ ...formErrors, ...fieldErrors });
    }, 50); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [formData]);

  const settOgSkuttData = useMemo(() => formData && sumSettOgSkuttElg(formData), [formData]);
  const settErrorMessage = useMemo(
    () => firstNonEmpty(settElgFields.map((field) => formErrors[field])),
    [formErrors],
  );
  const skuttErrorMessage = useMemo(
    () => firstNonEmpty(skuttElgFields.map((field) => formErrors[field])),
    [formErrors],
  );

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const _formData = {
      ...formData,
      RegistrertAv: initialData?.RegistrertAv,
    };
    const result = schema.safeParse(_formData);

    if (!result.success) {
      const fieldErrors: Partial<Record<keyof JaktdagRegistrationDataElg, string>> = {};
      result.error.errors.forEach((error) => {
        fieldErrors[error.path[0] as keyof JaktdagRegistrationDataElg] = error.message;
      });
      setFormErrors(fieldErrors);
      scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
      if (!result.success) return;
    }

    setFormErrors({});
    const mode = initialData ? "update" : "create";
    const requestMethodFor =
      mode === "update"
        ? jaktdataApi.putJaktdag.bind(jaktdataApi)
        : jaktdataApi.postJaktdag.bind(jaktdataApi);

    isSubmitting.set(true);

    const skotneDyr = initialData?.Individer?.map((individ) => {
      return {
        ...individ,
        Dato: formatISOWithoutTimestamp(date.value),
        RegistrertDato: formatISO(date.value, { representation: "date" }),
        Jegernummer: formData.Jegernummer,
      };
    });

    try {
      const request = toJaktdagRequestElg(
        formData,
        date.value,
        appData.jegeropplysninger.data?.Jegernummer,
        skotneDyr ?? undefined,
      );

      const storedJaktdag = storage.get<JaktdagResponse>({ id: request.Id }, "Jaktdag");
      if (!appData.isOnline.value || storedJaktdag) {
        const jaktdagToStore = request as Jaktdag;
        jaktdagToStore.RegistrertAv = _formData.RegistrertAv;
        handleOfflineJaktdagCreateOrUpdate({
          mode,
          jaktdagToStore,
          appData,
          storage,
        });

        if (!appData.isOnline.value) {
          showSnackbar({
            message: t("sync.noNetworkWillSyncLater"),
          });
        }

        successDialog.show({
          buttons: <SuccessDialogButtons valgtOmradekode={selectedJaktomrade.value.id} />,
        });

        removeFormData();

        return;
      }

      requestMethodFor(request)
        .then(() => {
          const individQueries: Promise<IndividResponse>[] = [];
          Promise.all([
            jaktdataApi.getJaktdag(request.Id),
            jaktdataApi
              .getSkutteDyr(date.value.getFullYear(), selectedJaktomrade.value.id)
              .then((individer) => {
                individer.forEach((individ) => {
                  individQueries.push(jaktdataApi.getIndivid(individ.Id || ""));
                });
              }),
            queryClient.invalidateQueries({
              queryKey: ["jaktdag", { jaktdagId: initialData?.Id }],
            }),
          ]).then(async () => {
            await Promise.all(individQueries);
            removeFormData();
            successDialog.show({
              buttons: <SuccessDialogButtons valgtOmradekode={selectedJaktomrade.value.id} />,
            });
          });
        })
        .catch((error) => {
          scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });

          setFormErrors(toErrorRecords<JaktdagRegistrationDataElg>(error));
          const formattedErrors = parseErrors(error.message);
          showSnackbar({
            message: formattedErrors.length
              ? formattedErrors
              : t("pages.registrering.errors.noeGikkGalt"),
          });
        })
        .finally(() => isSubmitting.set(false));
    } catch (e) {
      isSubmitting.set(false);
      resetForm();
      if (e instanceof Error) {
        if (e.message === "CanNotDelete") {
          showSnackbar({ message: t("pages.registrering.errors.canNotDelete") });
        } else {
          showSnackbar({ message: e.message });
        }
      }
    }
  };

  return (
    <JaktdagElgFormDataContext.Provider
      value={{ formData, formErrors, setFormData, setFormErrors: setFormErrors }}
    >
      <div className="h-full pb-7">
        <div ref={scrollTopRef} />

        {fellingsRapportLevert.value && (
          <AlertDefault
            id="registreringFellingsrapportLevertAlert"
            variant={AlertVariant.Info}
            className="mx-4 mt-4"
          >
            {t("pages.registrering.alerts.fellingsrapportLevert")}
          </AlertDefault>
        )}
        {isDraft.value && !fellingsRapportLevert.value && (
          <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4 flex-wrap">
            {t("pages.oversikt.hasJaktdagDraftDescription") + " "}
            <Link
              onClick={() => removeJaktdagDraft(getFormDataStorageKey(), navigate, showSnackbar)}
            >
              {t("draft.deleteDraft")}
            </Link>
          </AlertDefault>
        )}

        {formErrors.Id && (
          <AlertDefault variant={AlertVariant.Error} showIcon className="mx-4 mt-4">
            {formErrors.Id}
          </AlertDefault>
        )}

        <Card color="transparent" shadow={false} className="flex flex-col h-full p-4">
          <form>
            <AccordionDefault
              headerContent={
                <div className="flex justify-between w-full">
                  <div>{t("pages.registrerArt.datoOgJaktfelt")}</div>
                  <div className="text-sm w-32 text-md-gray font-light text-opacity-70 text-ellipsis overflow-hidden text-nowrap whitespace-nowrap">
                    <div className=""></div>
                    {formData.jaktomradeNavn &&
                      date.value &&
                      format(date.value, dateFormatNorsk) + " " + formData.jaktomradeNavn}
                  </div>
                </div>
              }
              error={Boolean(
                formErrors.Jaktfelt || formErrors.AntallJegere || formErrors.AntallTimerJaktet,
              )}
            >
              <div className="flex flex-col justify-evenly p-2 space-y-4 pb-6">
                <CalendarPicker
                  id="registrerArtDatePicker"
                  label={t("form.fields.date.label")}
                  date={date}
                  disabled={fellingsRapportLevert.value}
                />
                <JaktomradePicker
                  selectedState={selectedJaktomrade}
                  valgtArt={ArtType.Elg}
                  onChange={async (selected) => {
                    selectedJaktomrade.set(selected || selectedJaktomrade.value);
                    setFormData({
                      ...formData,
                      Jaktfelt: selected?.id || "",
                      jaktomradeNavn: selected?.label || "",
                    });
                    setFormErrors({ ...formErrors, Jaktfelt: "" });
                    fellingsRapportLevert.set(selected?.data?.ÅpenForRegistreringElg === false);
                    await queryClient.invalidateQueries({ queryKey: ["jaktdager"] });
                    await eksisterendeJaktdager.refetch();
                  }}
                  disabled={fellingsRapportLevert?.value}
                  defaultOmradekode={formData.Jaktfelt}
                  helperText={
                    initialData === undefined
                      ? t("form.fields.jaktfelt.helperText")
                      : t("form.fields.jaktfelt.helperTextRediger")
                  }
                  errorText={formErrors.Jaktfelt}
                />

                <AntallJegereOgTimerJaktet
                  formData={formData}
                  setFormData={setFormData as (data: JaktdagRegistrationDataElg) => void}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  disabled={fellingsRapportLevert.value}
                />
              </div>
            </AccordionDefault>
            <AccordionDefault
              headerContent={
                <div className="flex justify-between w-full">
                  {t("pages.registrerArt.sett")}
                  <HeaderRight label="Sum">{settOgSkuttData.sumSett || "-"}</HeaderRight>
                </div>
              }
              disabled={fellingsRapportLevert.value}
              errorMessage={settErrorMessage}
            >
              <div className="space-y-2 pb-6">
                {!settErrorMessage && (
                  <Text variant="small">
                    {t("pages.registrerArt.settHelpText", {
                      art: t("fauna.art.elg.artsnavn").toLowerCase(),
                    })}
                  </Text>
                )}
                <SettCountersElg />
                <Counter
                  showErrorHelperText={false}
                  id="settSumKalver"
                  label="Sum kalver"
                  showButtons={false}
                  value={settOgSkuttData.settKalver}
                  readOnly={true}
                />
              </div>
            </AccordionDefault>
            <AccordionDefault
              headerContent={
                <div className="flex justify-between w-full">
                  {t("pages.registrerArt.skutt")}
                  <HeaderRight label="Sum">{settOgSkuttData.sumSkutt || "-"}</HeaderRight>
                </div>
              }
              disabled={fellingsRapportLevert.value}
              errorMessage={skuttErrorMessage}
            >
              {!skuttErrorMessage && <SkuttHelpText />}
              <SkuttCountersElg />
            </AccordionDefault>
            <ButtonDefault
              className="w-full mt-5 mb-5"
              containerClassName="flex justify-center"
              id="registrerElgLagreButton"
              label={t("actions.save")}
              type="button"
              loading={isSubmitting.value}
              onClick={(e) => handleSubmit(e)}
              disabled={fellingsRapportLevert.value || !!eksisterendeJaktdag.value}
            />
          </form>
          {successDialog.element}
          {jaktdagEksistererDialog.element}
        </Card>
      </div>
    </JaktdagElgFormDataContext.Provider>
  );
}
